import { useEffect, memo } from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import L, { MarkerCluster } from 'leaflet';
import { TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';

import { BlockArrayProps, ChartDataProps } from '../interfaces';

// custom pins for desktop resolution
import outOfOrderPin from '../../../assets/images/pins/1.5x/pin_red.png';
import inUsePin from '../../../assets/images/pins/1.5x/pin_yellow.png';
import occupiedPin from '../../../assets/images/pins/1.5x/pin_blue.png';
import availablePin from '../../../assets/images/pins/1.5x/pin_green.png';

import { useAppSelector, useWindowSize } from '../../hooks';
import { BREAKPOINTS, PointStatuses, POINT_ICON_STATUSES, ChartColors } from '../../../constants';
import { convertNumberToString } from '../../../helpers';
import EllipsisText from '../EllipsisText';

const desktopIconOptions: L.BaseIconOptions = {
    iconSize: [32, 43],
    iconAnchor: [13, 38],
    popupAnchor: [1.8, -35],
};
const mobileIconOptions: L.BaseIconOptions = {
    iconSize: [27, 34],
    iconAnchor: [12, 28],
    popupAnchor: [1, -25],
};

const createClusterIcon = (cluster: MarkerCluster) => {
    return L.divIcon({
        html: `<span>${cluster.getChildCount()}</span>`,
        className: 'custom-marker-cluster',
        iconSize: L.point(30, 30, true),
    });
};
/**
 * A custom map component that renders the map tiles and its markers with custom popups.
 * @prop {ChartDataProps[]} props.data - The data to render.
 * @prop {number} props.width - The width of the component.
 * @return {JSX.Element} The rendered map component.
 */
const DashboardMapTiles = ({ data, width }: BlockArrayProps): JSX.Element => {
    const map = useMap();
    const windowSizes = useWindowSize();
    const { t } = useTranslation();
    const { ISOLanguageCode } = useAppSelector((state) => state.commonData);
    const isMobileSize = windowSizes.width <= BREAKPOINTS.sm;

    useEffect(() => {
        if (width) map.invalidateSize();
    }, [width, map]);

    const generateMarkers = (markersData: ChartDataProps[]) => {
        return markersData.map((marker, index) => {
            let pin: L.Icon<L.IconOptions>;
            const iconOptions = isMobileSize ? mobileIconOptions : desktopIconOptions;

            switch (marker.status) {
                case 'out_of_order':
                    pin = new L.Icon({ ...iconOptions, iconUrl: outOfOrderPin });
                    break;
                case 'in_use':
                    pin = new L.Icon({ ...iconOptions, iconUrl: inUsePin });
                    break;
                case 'occupied':
                    pin = new L.Icon({ ...iconOptions, iconUrl: occupiedPin });
                    break;

                default:
                    pin = new L.Icon({ ...iconOptions, iconUrl: availablePin });
            }

            return (
                <Marker
                    position={[marker.gps?.latitude ?? 0, marker.gps?.longitude ?? 0]}
                    key={`marker-${index}`}
                    icon={pin}>
                    {generatePopup(marker, index)}
                </Marker>
            );
        });
    };

    const generatePopup = (markerData: ChartDataProps, index: number) => {
        const { devices, points } = markerData;
        const availablePoints =
            points?.filter((point) => point.status === PointStatuses.Available) ?? [];

        return (
            <Popup
                key={`popup-${index}`}
                maxWidth={isMobileSize ? Number(width) - 50 : Number(width) / 1.7}>
                <Typography variant="subtitle2">
                    <span style={{ color: ChartColors.Green }}>
                        {convertNumberToString(availablePoints.length, ISOLanguageCode)}
                    </span>
                    /{convertNumberToString(points?.length ?? 0, ISOLanguageCode)}
                    <Typography variant="body2" sx={{ display: 'inline' }}>
                        {t('charts.pointsAvailable')}
                    </Typography>
                </Typography>
                <Typography variant="subtitle2">{markerData.name}</Typography>

                <Box className="popupBody">
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                        }}>
                        {devices?.map((device, deviceIndex) => {
                            const deviceClassName = device.device_state
                                ? 'available'
                                : 'out_of_order';
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                    }}
                                    key={`device-${deviceIndex}`}>
                                    <EllipsisText variant="caption" maxWidth={100}>
                                        <>{device.name}</>
                                    </EllipsisText>

                                    <img
                                        src={POINT_ICON_STATUSES.get(deviceClassName)}
                                        className="deviceStatusImage"
                                        alt="device-status"
                                    />
                                </div>
                            );
                        })}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}>
                        {points?.map((point, pointIndex) => {
                            const image = POINT_ICON_STATUSES.get(point.status);
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                    }}
                                    key={`point-${pointIndex}`}>
                                    <EllipsisText variant="caption" maxWidth={100}>
                                        <> {point.name}</>
                                    </EllipsisText>

                                    <img
                                        src={image}
                                        alt="point-status"
                                        className="pointStatusImage"
                                    />
                                </div>
                            );
                        })}
                    </Box>
                </Box>
            </Popup>
        );
    };
    return (
        <>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {data?.length > 0 && (
                <MarkerClusterGroup
                    chunkedLoading
                    iconCreateFunction={createClusterIcon}
                    showCoverageOnHover={false}>
                    {generateMarkers(data)}
                </MarkerClusterGroup>
            )}
        </>
    );
};

export default memo(DashboardMapTiles);
