import { store } from '../app/store';
import { MODAL_TYPES } from '../constants/general';
import { displayModal } from '../app/common/modal/modalDuck';
import { clearLSOnComponentLeave } from './clearStorage';
import i18n from '../i18n';

/**
 * Opens a modal to confirm if the user wants to leave the current page.
 *
 * @param {() => void} navigateCallBack - A callback function to be called when the user confirms leaving the page.
 * @return {void}
 */
export const openLeavePageModal = (navigateCallBack: () => void): void => {
    store.dispatch(
        displayModal({
            showModal: true,
            title: i18n.t('general.messages.notSaved'),
            type: MODAL_TYPES.ConfirmationMessage,
            message: 'general.messages.leavePage',
            onLeave: () => handleLeavePage(navigateCallBack),
        })
    );
};

/**
 * Handles the logic when the user wants to leave the current page.
 *
 * @param {() => void} navigateCallBack - A callback function to be called when the user confirms leaving the page.
 * @return {void} This function does not return anything.
 */
const handleLeavePage = (navigateCallBack: () => void): void => {
    closeModal();
    clearLSOnComponentLeave();
    navigateCallBack();
};

/**
 * Closes the modal by dispatching a Redux action to update the state.
 *
 * @return {void}
 */
export const closeModal = (): void => {
    store.dispatch(displayModal({ showModal: false }));
};
