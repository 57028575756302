import { useCallback, useEffect, useState } from 'react';

import { Typography, Box } from '@mui/material';
import { ColorPicker, ColorPickerChangeEvent } from '@progress/kendo-react-inputs';

interface CustomColorPickerProps {
    defaultValue: string;
    onChangeColor: (event: ColorPickerChangeEvent) => void;

    pickerLabel?: string;
}
/**
 * CustomColorPicker is a React component that renders a Kendo color picker with a label.
 *
 * @param {CustomColorPickerProps} props - The properties for the component:
 * @param {string} props.pickerLabel - The label for the color picker.
 * @param {string} props.defaultValue - The default value for the color picker.
 * @param {(event: ColorPickerChangeEvent) => void} props.onChangeColor - The callback function triggered when the color is changed.
 * @return {JSX.Element} The rendered component.
 */
const CustomColorPicker = ({
    defaultValue,
    pickerLabel,
    onChangeColor,
}: CustomColorPickerProps): JSX.Element => {
    const [value, setValue] = useState('');

    const handleOnChange = useCallback(
        (event: ColorPickerChangeEvent) => {
            onChangeColor(event);
            setValue(event.value);
        },
        [onChangeColor]
    );

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    return (
        <Box className="custom-color" role="custom-color">
            <Typography variant="body1">{pickerLabel}</Typography>
            <ColorPicker value={value} onChange={handleOnChange} view="gradient" />
        </Box>
    );
};
export default CustomColorPicker;
