import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { SelectionRange } from '@progress/kendo-react-dateinputs';
import moment, { unitOfTime } from 'moment';
import { TODAY_DATE } from '../constants';

/**
 * Returns a string representation of the given Date object in UTC format,
 * with the 'Z' and 'T' characters removed.
 *
 * @param {Date} date - The Date object to convert to UTC format.
 * @return {string} A string representation of the given Date object in UTC format.
 */
const getUTCDate = (date: Date): string => {
    return new Date(
        Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds()
        )
    )
        .toISOString()
        .replace('Z', '')
        .replace('T', ' ');
};

/**
 * Creates date filters based on the given date filters and adds them to the filters array.
 *
 * @param {SelectionRange} dateFilters - The range of dates to filter by.
 * @param {(CompositeFilterDescriptor | FilterDescriptor)[]} filters - The array of filters to add the date filters to.
 * @param {string} [fieldName='created_at'] - The name of the field to filter by. Defaults to 'created_at'.
 * @return {(CompositeFilterDescriptor | FilterDescriptor)[]} The updated filters array with the added date filters.
 */
export const createDateFilters = (
    dateFilters: SelectionRange,
    filters: (CompositeFilterDescriptor | FilterDescriptor)[],
    fieldName?: string
): (CompositeFilterDescriptor | FilterDescriptor)[] => {
    const selectedFieldName = fieldName ?? 'created_at';
    if (dateFilters.start) {
        filters.push({
            field: selectedFieldName,
            operator: 'gt',
            value: getUTCDate(moment(dateFilters.start?.setHours(0, 0, 0)).toDate()),
        });
        if (!dateFilters.end || dateFilters.end.getTime() === dateFilters.start.getTime()) {
            filters.push({
                field: selectedFieldName,
                operator: 'lt',
                value: getUTCDate(moment(dateFilters.start).add(1, 'day').toDate()),
            });
        } else {
            filters.push({
                field: selectedFieldName,
                operator: 'lt',
                value: getUTCDate(
                    moment(dateFilters.end.setHours(0, 0, 0))
                        .add(1, 'day')
                        .toDate()
                ),
            });
        }
    }
    return filters;
};

/**
 * Creates date filters parameters based on the given selection range.
 *
 * @param {SelectionRange} dateFilters - The selection range of dates.
 * @return {{start_date: string, end_date: string}} The date filters parameters.
 */
export const createDateFiltersParams = (
    dateFilters: SelectionRange
): { start_date: string; end_date: string } => {
    let start_date = '';
    let end_date = '';
    if (dateFilters.start) {
        start_date = getUTCDate(moment(dateFilters.start?.setHours(0, 0, 0)).toDate());
        if (!dateFilters.end || dateFilters.end.getTime() === dateFilters.start.getTime()) {
            end_date = getUTCDate(moment(dateFilters.start).add(1, 'day').toDate());
        } else {
            end_date = getUTCDate(
                moment(dateFilters.end.setHours(0, 0, 0))
                    .add(1, 'day')
                    .toDate()
            );
        }
    }
    return {
        start_date,
        end_date,
    };
};

/**
 * Generates a new Date object that is one minute after the given startTime.
 *
 * @param {Date} startTime - The starting time.
 * @return {Date} The new Date object that is one minute after the startTime.
 */
export const generateMinEndTime = (startTime: Date): Date => {
    return new Date(startTime.setMinutes(startTime.getMinutes() + 1));
};

/**
 * Returns the translated short form of a day, based on the given date and language.
 *
 * @param {Date} date - The date for which the translated short form is desired.
 * @param {string} language - The language code for which the translation is desired.
 * @return {string} The translated short form of the day.
 */
export const getTranslatedShortDay = (date: Date, language: string): string => {
    return new Intl.DateTimeFormat(language, { weekday: 'short' }).format(date);
};

/**
 * Converts the given number of minutes into a string representation of the time in the format HH:MM.
 *
 * @param {number} nrOfMinutes - The number of minutes to convert.
 * @return {string} The time string in the format HH:MM.
 */
export const convertMinutesToTimeString = (nrOfMinutes: number): string => {
    const hours = Math.floor(nrOfMinutes / 60);
    const minutes = nrOfMinutes % 60;
    const hoursString = hours < 10 ? `0${hours}` : hours.toString();
    const minutesString = minutes < 10 ? `0${minutes}` : minutes.toString();
    return `${hoursString}:${minutesString}`;
};

/**
 * Converts a given date or string representation of a date into a formatted string representation.
 *
 * @param {string | Date} data - The date to be converted, either as a string or a Date object.
 * @param {string} format - The desired format of the output string.
 * @return {string} The formatted string representation of the input date.
 */
export const convertDateToFormat = (data: string | Date, format: string): string => {
    return moment(data).format(format);
};

/**
 * Converts a string representation of a date into a JavaScript Date object.
 *
 * @param {string} data - The string representation of the date.
 * @param {string} format - The format of the input date string.
 * @return {Date} The JavaScript Date object representing the input date.
 */
export const convertToDateObject = (data: string, format: string): Date => {
    return moment(new Date(data), format).toDate();
};

/**
 * Formats the current date according to the specified format.
 *
 * @param {string} format - The format to use for formatting the date.
 * @return {Date} The formatted date as a JavaScript Date object.
 */
export const formatTodayDate = (format: string): Date => {
    return moment(new Date(), format).toDate();
};

/**
 * Adds a specified amount of time to the current date according to the given format.
 *
 * @param {string} format - The format used to represent the date.
 * @param {number} amount - The amount of time to add.
 * @param {unitOfTime.Base} unit - The unit of time to add (e.g. 'days', 'hours', 'minutes', etc.).
 * @return {Date} The resulting date after adding the specified amount of time.
 */
export const addAmountToTodayDate = (
    format: string,
    amount: number,
    unit: unitOfTime.Base
): Date => {
    return moment(new Date(), format).add(amount, unit).toDate();
};

/**
 * Formats a given Date object or null into an ISO string representation with milliseconds set to 0.
 *
 * @param {Date | null} data - The Date object or null to be formatted.
 * @return {string} The formatted ISO string representation of the input Date object or null.
 */
export const formatDateToISOString = (data: Date | null): string => {
    return moment(data).milliseconds(0).toISOString();
};

/**
 * Adds the current time to the given date. If the date is null, it returns the same null value.
 *
 * @param {Date | null} date - The date to add the current time to.
 * @return {Date | null} The date with the current time added, or the original null value if the input was null.
 */
export const addCurrentTimeToDate = (date: Date | null): Date | null => {
    if (date) {
        const currentDate = date.setHours(
            TODAY_DATE.getHours(),
            TODAY_DATE.getMinutes(),
            TODAY_DATE.getSeconds()
        );
        return new Date(currentDate);
    }
    return date;
};

/**
 * Formats a time string into a localized time string representation.
 *
 * @param {string} time - The time string to format, in the format 'hh:mm:ss'.
 * @param {string} language - The language code for the desired localized representation.
 * @return {string} The formatted time string in the specified language.
 */
export const formatTimeString = (time: string, language: string): string => {
    return moment(time, 'hh:mm:ss').toDate().toLocaleTimeString(language);
};

/**
 * Compares two dates and returns a negative number if the first date is earlier than the second date,
 * a positive number if the first date is later than the second date, or 0 if the dates are equal.
 *
 * @param {string | Date} first - The first date to compare. Can be a string representing a date in ISO format or a Date object.
 * @param {string | Date} second - The second date to compare. Can be a string representing a date in ISO format or a Date object.
 * @return {number} A negative number if the first date is earlier than the second date, a positive number if the first date is later than the second date, or 0 if the dates are equal.
 */
export const compareDates = (first: string | Date, second: string | Date): number => {
    return new Date(first).getTime() - new Date(second).getTime();
};

/**
 * Compares two dates and returns a negative number if the first date is earlier than the second date,
 * a positive number if the first date is later than the second date, or 0 if the dates are equal.
 *
 * @param {Date} a - The first date to compare.
 * @param {Date} b - The second date to compare.
 * @return {number} A negative number if the first date is earlier than the second date, a positive number if the first date is later than the second date, or 0 if the dates are equal.
 */
export const compareTimes = (a: Date, b: Date): number => {
    if (a.getHours() < b.getHours()) return -1;
    if (a.getHours() > b.getHours()) return 1;
    if (a.getHours() === b.getHours()) {
        if (a.getMinutes() < b.getMinutes()) return -1;
        if (a.getMinutes() > b.getMinutes()) return 1;
    }
    return 0;
};

/**
 * Processes the incoming digit and returns a string representation based on its value.
 * it is used for numerical time input
 *
 * @param {number} incomingDigit - The digit to be processed.
 * @return {string} The string representation of the processed digit. If the incoming digit is less than or equal to 4, it is prepended with '2'. Otherwise, it is prepended with '0'.
 */
const processTwoUpdate = (incomingDigit: number): string => {
    if (incomingDigit <= 4) {
        return `2${incomingDigit}`;
    }
    return `0${incomingDigit}`;
};

/**
 * Processes the hours value and returns a string representation based on its value.
 *
 * @param {string} hours - The hours value to be processed.
 * @return {string} The string representation of the processed hours value. If the length of the hours value is 1, it is prepended with '0'.
 *                  If the first digit of the hours value is not 0, the incoming digit is prepended with '0'.
 *                  If the second digit of the hours value is 1, the incoming digit is prepended with '1'.
 *                  If the second digit of the hours value is 2, the incoming digit is processed using the processTwoUpdate function.
 *                  Otherwise, the incoming digit is prepended with '0'.
 */
export const processHoursValue = (hours: string): string => {
    if (hours.length === 1) return `0${hours}`;

    const firstDigit = parseInt(hours.slice(0, 1));
    const secondDigit = parseInt(hours.slice(1, 2));
    const incomingDigit = parseInt(hours.slice(2, 3));

    if (isNaN(incomingDigit)) {
        return '00';
    }

    if (firstDigit !== 0) {
        return `0${incomingDigit}`;
    } else {
        switch (secondDigit) {
            case 1:
                return `1${incomingDigit}`;
            case 2:
                return processTwoUpdate(incomingDigit);
            default:
                return `0${incomingDigit}`;
        }
    }
};

/**
 * Processes the minutes value and returns a string representation based on its value.
 *
 * @param {string} minutes - The minutes value to be processed.
 * @return {string} The string representation of the processed minutes value. If the length of the minutes value is 1, it is prepended with '0'.
 *                  If the first digit of the minutes value is not 0 or the second digit is greater than 5, the incoming digit is prepended with '0'.
 *                  Otherwise, the second digit and the incoming digit are concatenated.
 */
export const processMinutesValue = (minutes: string): string => {
    if (minutes.length === 1) return `0${minutes}`;

    const firstDigit = parseInt(minutes.slice(0, 1));
    const secondDigit = parseInt(minutes.slice(1, 2));
    const incomingDigit = parseInt(minutes.slice(2, 3));

    if (isNaN(incomingDigit)) {
        return '00';
    }

    if (firstDigit !== 0 || secondDigit > 5) {
        return `0${incomingDigit}`;
    } else {
        return `${secondDigit}${incomingDigit}`;
    }
};
