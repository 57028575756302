import { useTranslation } from 'react-i18next';
import { TreeView, processTreeViewItems, ItemRenderProps } from '@progress/kendo-react-treeview';
import { TreeViewComponentInterface } from './interfaces';

/**
 * Renders a TreeView component with the provided props.
 *
 * @param {TreeViewComponentInterface} props - The props for the TreeViewComponent.
 * @param {TreeViewDataItem[]} props.treeData - The data for the TreeView.
 * @param {RefObject<any>} props.treeRef - The reference to the TreeView component.
 * @param {(data: TreeViewExpandChangeEvent) => void} props.onExpandChange - The callback function for expand change event.
 * @param {(data: TreeViewCheckChangeEvent) => void} props.onCheckChange - The callback function for check change event.
 * @param {Record<never, never>} [props.check] - The check configuration for the TreeView.
 * @param {Record<never, never>} [props.expand] - The expand configuration for the TreeView.
 * @param {boolean} [props.disabled] - Whether the TreeView is disabled.
 * @return {JSX.Element} The rendered TreeView component.
 */
const TreeViewComponent = ({
    check,
    expand,
    treeData,
    treeRef,
    onCheckChange,
    onExpandChange,
    disabled,
}: TreeViewComponentInterface): JSX.Element => {
    const { t } = useTranslation();
    const itemWithTranslation = (props: ItemRenderProps) => {
        return (
            <>
                <span> {t(props.item.text)}</span>
            </>
        );
    };

    return (
        <TreeView
            data={processTreeViewItems(treeData, {
                check,
                expand,
            })}
            ref={treeRef}
            checkboxes={true}
            expandIcons={true}
            item={itemWithTranslation}
            onExpandChange={onExpandChange}
            onCheckChange={onCheckChange}
        />
    );
};
export default TreeViewComponent;
