import { MandatorProduct, ProductPrice, ProductResponseItem } from '../app/pages/interfaces';

/**
 * Returns a formatted string containing the code and name of a product.
 *
 * @param {ProductResponseItem} pointProduct - The product to get data from.
 * @param {MandatorProduct[]} tenantProducts - The list of tenant products.
 * @return {string} The formatted string containing the product code and name.
 */
export const getProductData = (
    pointProduct: ProductResponseItem,
    tenantProducts: MandatorProduct[]
): string => {
    const changedProduct: MandatorProduct = tenantProducts.filter(
        (mandatorProduct) => pointProduct.id === mandatorProduct.product_id
    )[0];

    let code = pointProduct.code,
        name = pointProduct.name;

    if (changedProduct) {
        code = changedProduct.mandator_product_code || changedProduct.product_code;
        name = changedProduct.mandator_product_name || changedProduct.product_name;
    }

    return `${code} - ${name}`;
};

/**
 * Retrieves the product name based on the given product and mandator products.
 *
 * @param {ProductPrice} product - The product for which to retrieve the name.
 * @param {MandatorProduct[]} mandatorProducts - The list of mandator products.
 * @return {string} The product name. If no matching mandator product is found, the default product name is returned.
 */
export const getProductName = (
    product: ProductPrice,
    mandatorProducts: MandatorProduct[]
): string => {
    let productName = product.product_name;
    const newProduct: MandatorProduct = mandatorProducts.filter(
        (mandatorProduct) => product.product_id === mandatorProduct.product_id
    )[0];
    if (newProduct) productName = newProduct.mandator_product_name || newProduct.product_name;
    return productName;
};
