import { ComponentType } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { IGridColumn } from '../../common/grid/interfaces';
import { COLUMN_VISIBLE, MIN_COLUMN_WIDTH } from '../../../constants';

/**
 * Returns columns for the Twint grid.
 * @param customCells A map of custom cell components.
 * @param isSitesGrid Whether the columns are for the sites grid.
 * @returns An array of IGridColumn objects.
 */
export const getTwintColumns = (
    customCells: Map<string, ComponentType<GridCellProps>>,
    isSitesGrid: boolean
): IGridColumn[] => [
    {
        field: 'name',
        title: 'general.labels.name',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: isSitesGrid ? 'code' : 'point_number',
        title: isSitesGrid ? 'general.labels.code' : 'points.number',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'QR_code',
        title: 'twint.qrCode',
        sortable: false,
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
        cell: customCells.get('QR_code'),
    },
];
