import moment from 'moment';

export enum Resources {
    Transactions = 'transactions',
}

export const EXPORT_TYPES = [
    { name: 'xlsx', value: 'xlsx' },
    { name: 'csv', value: 'csv' },
];

export const DAY_NUMBER_VALUES = Array.from({ length: 5 }, (_, i) => ({
    number: i + 1,
    value: i + 1,
}));

export const MONTHLY_DAYS_NUMBER_VALUES = Array.from({ length: 31 }, (_, i) => ({
    number: i + 1,
    value: i + 1,
}));

export const MONTH_NAMES = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
];

export enum DailyOptions {
    NumberOption = 'number_option',
    DayNameOption = 'day_name_option',
}

export const DEFAULT_TIME = moment({ hour: 0, minute: 0 }).toDate();
export const DEFAULT_WORKING_DAY = ['monday'];
export const DEFAULT_MONTH = MONTH_NAMES[0];
export const DEFAULT_DAY_NUMBER = 1;
export const DEFAULT_EXPORT_TYPE = 'xlsx';
export const DEFAULT_REPEAT_VALUE = 'weekly';
