import { GridCellProps } from '@progress/kendo-react-grid';
import { ComponentType } from 'react';
import { COLUMN_VISIBLE, MIN_COLUMN_WIDTH } from '../../../constants';
import { IGridColumn } from '../../common/interfaces';

export const getVehicleTagsColumns = (): IGridColumn[] => {
    return [
        {
            field: 'tag_id',
            title: 'edentify.vehicleTags.title',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'scheme',
            title: 'edentify.vehicleTags.scheme',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'creation_date',
            title: 'general.labels.createdAt',
            filter: 'date',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'plate_number',
            title: 'edentify.vehicleTags.plateNumber',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
    ];
};

export const getEdentifyUnitsColumns = (): IGridColumn[] => {
    return [
        {
            field: 'name',
            title: 'sites.entityName',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
    ];
};

export const getEdentifySiteUnitsColumns = (
    customCells?: Map<string, ComponentType<GridCellProps>>
): IGridColumn[] => {
    return [
        {
            field: 'name',
            title: 'edentify.pointName',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
            editable: false,
        },
        {
            field: 'unit_tag',
            title: 'edentify.units.singular',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            cell: customCells?.get('unit_tag'),
            sortable: false,
        },
    ];
};
