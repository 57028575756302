import { useEffect } from 'react';

// redux
import { useAppDispatch, useAppSelector } from '.';
import { updateActiveModules } from '../pages/marketplace/marketplaceDuck';

import { getActiveModulesForMandator } from '../../helpers';

// determine if mandator has access to component or not
export const useActiveModules = () => {
    const { selectedMandator } = useAppSelector((state) => state.mandators);
    const { modules } = useAppSelector((state) => state.marketplace);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const activeModulesForMandator = getActiveModulesForMandator(modules);
        dispatch(updateActiveModules(activeModulesForMandator));
    }, [dispatch, modules, selectedMandator]);
};
