import { createRef, useEffect } from 'react';
import { DateTimePicker, DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { GridCellProps } from '@progress/kendo-react-grid';

import { TIME_STEPS } from '../../../../constants';
import { convertDateToFormat } from '../../../../helpers';

interface DateTimeCellProps extends GridCellProps {
    dateTimeFormat?: string;
    disabled?: boolean;
    minDate?: Date;
    momentFormat?: string;
}

/**
 * Renders a custom cell for a date and time field in a grid.
 *
 * @param {DateTimeCellProps} props - The props for the DateTimeCell component.
 * @param {string} props.field - The field name.
 * @param {object} props.dataItem - The data item.
 * @param {function} props.onChange - The change event handler.
 * @param {number} props.dataIndex - The index of the element.
 * @param {Date} props.minDate - The minimum available date.
 * @param {boolean} props.disabled - Whether the cell is disabled.
 * @param {string} props.dateTimeFormat - The date and time format.
 * @param {string} props.momentFormat - The moment format.
 * @param {object} props.style - The style object.
 * @param {string} props.className - The class name.
 * @return {JSX.Element} The custom cell for the date and time field.
 */
const DateTimeCell = ({
    className,
    dataIndex,
    dataItem,
    dateTimeFormat,
    disabled,
    field,
    minDate,
    momentFormat,
    style,
    onChange,
}: DateTimeCellProps): JSX.Element => {
    const ref = createRef<DateTimePicker>();
    const fieldName = field || '';
    const dataValue = dataItem[fieldName];

    useEffect(() => {
        ref.current?.dateInput?.element?.setAttribute('readonly', 'true');
    }, [ref]);

    const handleChange = (e: DateTimePickerChangeEvent) => {
        if (onChange)
            onChange({
                dataIndex,
                dataItem,
                field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            });
    };
    const selectedDate = dataValue
        ? convertDateToFormat(dataValue, momentFormat as string)
        : dataValue;

    return (
        <td className={className} style={style}>
            {dataItem.inEdit ? (
                <DateTimePicker
                    ref={ref}
                    steps={TIME_STEPS}
                    format={dateTimeFormat}
                    value={dataValue}
                    name={fieldName}
                    onChange={handleChange}
                    valid={true}
                    min={minDate}
                    disabled={disabled}
                />
            ) : (
                <span>{selectedDate}</span>
            )}
        </td>
    );
};
export default DateTimeCell;
