import { Checkbox } from '@progress/kendo-react-inputs';
import { TreeListCellProps } from '@progress/kendo-react-treelist';

type TreeListCheckboxProps = TreeListCellProps & { disabled: boolean };

/**
 * Renders a custom checkbox in a TreeList cell.
 *
 * @param {TreeListCheckboxProps} props - The props for the TreeListCheckbox component.
 * @param {string} props.field - The field name for the checkbox.
 * @param {function} props.onChange - The function to call when the checkbox value changes.
 * @param {object} props.dataItem - The data item associated with the checkbox.
 * @param {number} props.level - The level of the TreeList.
 * @param {boolean} props.disabled - Whether the checkbox is disabled.
 * @return {JSX.Element} The rendered checkbox within a table cell.
 */
const TreeListCheckbox = ({
    dataItem,
    disabled,
    field,
    level,
    onChange,
}: TreeListCheckboxProps): JSX.Element => {
    const fieldName = field || '';
    const dataValue = dataItem[fieldName];
    const handleChange = (e: any) => {
        if (onChange) {
            onChange({
                level: level,
                dataItem: dataItem,
                field: field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            });
        }
    };

    return (
        <td>
            <Checkbox
                value={dataValue}
                onChange={handleChange}
                disabled={disabled}
                name={fieldName}
            />
        </td>
    );
};
export default TreeListCheckbox;
