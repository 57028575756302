import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { ReportTemplatesGetReqProps, ReportTemplatesStateTypes } from '../interfaces';
import { Interceptor } from '../../common';
import { buildQueryParams } from '../../../helpers';
import { INITIAL_DATA_STATE, URLS } from '../../../constants';

const initialState: ReportTemplatesStateTypes = {
    reportTemplates: INITIAL_DATA_STATE,
    reportFields: [],
    reportTemplate: null,
};

// create a thunk for getting the report templates
export const getReportTemplates = createAsyncThunk(
    'reports/templates/list',
    async ({ resource_name, data_state }: ReportTemplatesGetReqProps, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(URLS.ReportsTemplates, {
                params: { resource_name, ...queryParams },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for getting the report fields
export const getReportTemplatesFields = createAsyncThunk(
    'reports/templates/fields',
    async (resource_name: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(URLS.ReportsFields, {
                params: { resource_name },
            });

            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for getting the report template by id
export const getReportTemplateById = createAsyncThunk(
    'reports/template/get',
    async (template_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.ReportsTemplates}/${template_id}`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for add report template  ----to be checked
export const addReportTemplate = createAsyncThunk(
    'reports/template/add',
    async (reportTemplate: any, { rejectWithValue }) => {
        try {
            return await Interceptor().post(`${URLS.ReportsTemplates}`, { ...reportTemplate });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for update report template  ----to be checked
export const updateReportTemplate = createAsyncThunk(
    'reports/template/update',
    async ({ reportTemplate, templateId }: any, { rejectWithValue }) => {
        try {
            return await Interceptor().put(`${URLS.ReportsTemplates}/${templateId}`, {
                ...reportTemplate,
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for deleting a report template
export const deleteReportTemplate = createAsyncThunk(
    'reports/template/delete',
    async (reportTemplateId: string, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.ReportsTemplates}/${reportTemplateId}`);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for deleting multiple report templates
export const deleteReportTemplates = createAsyncThunk(
    'reports/templates/delete',
    async (ids: string[], { rejectWithValue }) => {
        try {
            return await Interceptor().delete(URLS.ReportsTemplates, {
                data: [...ids],
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        resetReportTemplates: (state) => {
            state.reportTemplates = initialState.reportTemplates;
        },
        resetReportTemplate: (state) => {
            state.reportTemplate = initialState.reportTemplate;
        },
        resetReportFields: (state) => {
            state.reportFields = initialState.reportFields;
        },
        updateItems: (state, action) => {
            state.reportTemplates.data = [...action.payload];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getReportTemplates.fulfilled, (state, { payload }) => {
            state.reportTemplates.data = [...payload.data];
            state.reportTemplates.total = payload.meta.total;
        });
        builder.addCase(getReportTemplateById.fulfilled, (state, { payload }) => {
            state.reportTemplate = payload.data[0];
        });
        builder.addCase(getReportTemplatesFields.fulfilled, (state, { payload }) => {
            state.reportFields = [...payload.data];
        });
    },
});

export const { resetReportTemplates, resetReportFields, resetReportTemplate, updateItems } =
    reportsSlice.actions;
export default reportsSlice.reducer;
