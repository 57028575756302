import { Dispatch, KeyboardEvent, SetStateAction } from 'react';
import { t } from 'i18next';
import { FormikErrors } from 'formik';
import { toast } from 'react-toastify';
import { NavigateFunction, Params } from 'react-router-dom';

import { Actions, SUCCESSFUL_SELECTED_ACTION, ToasterType } from '../constants';
import { openLeavePageModal } from './handleModalVisibility';
import { clearLSOnComponentLeave, navigateToParent } from '.';

/**
 * Executes the back and cancel action based on the value of `localStorage.addOrEdit`.
 *
 * @param {NavigateFunction} navigate - The function to navigate to the parent.
 * @return {void} This function does not return a value.
 */
export const backAndCancelAction = (navigate: NavigateFunction): void => {
    if (localStorage.addOrEdit === 'true') {
        openLeavePageModal(() => navigateToParent(navigate));
    } else {
        navigateToParent(navigate);
        clearLSOnComponentLeave();
    }
};

/**
 * Validates the form values and sets a flag in localStorage if values are present.
 *
 * @param {T} values - The values to validate.
 * @return {void} This function does not return a value.
 */
export const validateForm = <T>(values: T): void => {
    if (values) {
        localStorage.setItem('addOrEdit', 'true');
    }
};

/**
 * Prevents form submission when the Enter key is pressed outside of a textarea element.
 *
 * @param {KeyboardEvent<HTMLElement>} keyEvent - The keyboard event triggered by the Enter key press.
 * @return {void} This function does not return a value.
 */
export const preventSubmit = (keyEvent: KeyboardEvent<HTMLElement>): void => {
    const textareas = document.getElementsByClassName('textarea');
    const isTextArea = testTextarea(textareas, keyEvent.target);

    if (keyEvent.key === 'Enter' && !isTextArea) {
        keyEvent.preventDefault();
    }
};

/**
 * Checks if the given item is a textarea element within the provided HTMLCollection.
 *
 * @param {HTMLCollection} textareas - The collection of textarea elements.
 * @param {EventTarget} item - The item to check.
 * @return {boolean} Returns true if the item is a textarea element, false otherwise.
 */
const testTextarea = (textareas: HTMLCollection, item: EventTarget): boolean => {
    const arr = Array.from(textareas);
    let isTextArea = false;
    if (arr.length > 0) {
        arr.forEach(function (element) {
            if (element.ariaHidden !== 'true' && element === item) {
                isTextArea = true;
            }
        });
    }
    return isTextArea;
};

/**
 * Generates a form title based on the provided path parameters, item name, and optional entity name.
 *
 * @param {Readonly<Params<string>> | any} pathParameters - The path parameters containing the id and action.
 * @param {string} itemName - The name of the item.
 * @param {string} [entityName] - The optional name of the entity.
 * @return {string} The generated form title.
 */
export const generateFormTitle = (
    pathParameters: Readonly<Params<string>> | any,
    itemName: string,
    entityName?: string
): string => {
    if (pathParameters.id && pathParameters.action !== Actions.Copy) {
        const translatedAction = t(`general.labels.${pathParameters.action}`);
        return `${translatedAction ?? ''} ${itemName}`;
    } else {
        return t(`general.labels.addForms.${entityName}`);
    }
};

/**
 * Checks the visibility of the edit button based on the path parameters and edit rights.
 *
 * @param {string} pathParamsAction - The action specified in the path parameters.
 * @param {boolean} editRight - Indicates whether the user has edit rights.
 * @return {boolean} Returns true if the edit button should be visible, false otherwise.
 */
export const checkEditButtonVisibility = (
    pathParamsAction: string,
    editRight: boolean
): boolean => {
    return pathParamsAction === Actions.View && editRight;
};

/**
 * Checks the visibility of the delete button based on the path parameters and delete rights.
 *
 * @param {string} pathParamsActions - The action specified in the path parameters.
 * @param {boolean} deleteRight - Indicates whether the user has delete rights.
 * @return {boolean} Returns true if the delete button should be visible, false otherwise.
 */
export const checkDeleteButtonVisibility = (
    pathParamsActions: string,
    deleteRight: boolean
): boolean => {
    return (
        (pathParamsActions === Actions.View || pathParamsActions === Actions.Edit) && deleteRight
    );
};

/**
 * Determines if a tab is disabled based on the provided parameters.
 *
 * @param {boolean} dirty - Indicates if the form is dirty.
 * @param {boolean} isValid - Indicates if the form is valid.
 * @param {number} selectedTab - The currently selected tab.
 * @param {number} previousTab - The previously selected tab.
 * @param {string} [idItem] - Optional ID of the item.
 * @returns {boolean} Returns true if the tab is disabled, false otherwise.
 */
export const isDisabledTab = (
    dirty: boolean,
    isValid: boolean,
    selectedTab: number,
    previousTab: number,
    idItem?: string
): boolean => {
    if (!idItem) {
        return (!isValid || !dirty) && previousTab === selectedTab;
    }
    return !isValid && previousTab === selectedTab;
};

/**
 * Displays a toast notification of the specified type with the given message.
 *
 * @param {string} type - The type of toast notification to display. Can be 'success', 'warning', 'error', or any other value.
 * @param {string} message - The message to display in the toast notification.
 * @param {string} [action] - An optional action to append to the message.
 * @param {boolean} [skipTranslation] - An optional flag indicating whether to skip translation of the message.
 * @return {void} This function does not return anything.
 */
export const showToaster = (
    type: string,
    message: string,
    action?: string,
    skipTranslation?: boolean
): void => {
    let translatedMessage = skipTranslation ? message : t(action ? `${message}${action}` : message);

    if (translatedMessage.includes(SUCCESSFUL_SELECTED_ACTION)) {
        translatedMessage = t(message, { action: t(action?.toLowerCase() ?? '') });
    }
    switch (type) {
        case 'success':
            toast.success(translatedMessage, { theme: 'colored' });
            break;
        case 'warning':
            toast.warn(translatedMessage, { theme: 'colored' });
            break;
        case 'error':
            toast.error(translatedMessage, { theme: 'colored' });
            break;
        default:
            toast.info(translatedMessage, { theme: 'colored' });
    }
};

/**
 * Updates the state with the new value of the selected tab.
 *
 * @param {Dispatch<SetStateAction<number>>} setState - The function to update the state.
 * @param {number} value - The new value of the selected tab.
 * @return {void} This function does not return anything.
 */
export const handleChangeTabs = (
    setState: Dispatch<SetStateAction<number>>,
    value: number
): void => {
    setState(value);
};

/**
 * Shows a validation error on a different tab if there are any formik errors and none of the errors are on the current tab.
 *
 * @param {FormikErrors<T>} formikErrors - The errors returned by formik.
 * @param {Map<number, string[]>} tabsFields - A map of tab numbers to an array of field names on that tab.
 * @param {number} currentTab - The current tab number.
 * @return {void} This function does not return anything.
 */
export const showValidationErrorOnDifferentTab = <T>(
    formikErrors: FormikErrors<T>,
    tabsFields: Map<number, string[]>,
    currentTab: number
): void => {
    const errorKeys = Object.keys(formikErrors);
    if (errorKeys.length > 0) {
        const currentTabFields = tabsFields.get(currentTab);
        const foundError = errorKeys.some((key) => currentTabFields?.includes(key));
        if (!foundError) {
            showToaster(ToasterType.Error, t('general.validations.invalidEntries'));
        }
    }
};

/**
 * Checks if a field is required based on the schema validation.
 *
 * @param {any} schemaValidation - The schema validation object.
 * @param {string} fieldName - The name of the field to check.
 * @return {boolean} Returns true if the field is required, false otherwise.
 */
export const checkRequiredField = (schemaValidation: any, fieldName: string): boolean => {
    let isRequired = false;
    if (fieldName.includes('.')) {
        const splittedName = fieldName.split('.');
        isRequired =
            schemaValidation[splittedName[0]]?.fields[splittedName[1]]?.exclusiveTests?.required;
    } else {
        isRequired = schemaValidation[fieldName]?.exclusiveTests?.required;
    }
    return isRequired;
};
