import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { URLS, INITIAL_DATA_STATE } from '../../../constants';
import { Interceptor } from '../../common';
import {
    ExtendedGetReqProps,
    UpdateSelectedUsersModel,
    UpdateUserModel,
    User,
    UsersStateTypes,
} from '../interfaces';
import { buildQueryParams } from '../../../helpers';

const initialState: UsersStateTypes = {
    user: null,
    users: INITIAL_DATA_STATE,
};

// create a thunk for get users
export const getUsers = createAsyncThunk(
    'users/list',
    async ({ data_state, mandator_id }: ExtendedGetReqProps, { rejectWithValue }) => {
        try {
            // build pagination, sorting and filtering params
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.Users}`, {
                params: { ...queryParams, mandator_id },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for add user
export const addUser = createAsyncThunk(
    'users/user/add',
    async (user: User, { rejectWithValue }) => {
        try {
            return await Interceptor().post(`${URLS.Users}`, { ...user });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// thunk for update multiple users
export const updateUsers = createAsyncThunk(
    'users/update/bulk',
    async (updateSelectedUsersModel: UpdateSelectedUsersModel, { rejectWithValue }) => {
        try {
            // axios request
            return await Interceptor().put(URLS.Users, { ...updateSelectedUsersModel });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk for getting the user
export const getUserById = createAsyncThunk(
    'users/user/get',
    async (userId: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Users}/${userId}`);
            return response.data.data[0];
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk for updating a user
export const updateUser = createAsyncThunk(
    'users/user/update',
    async ({ user, userId }: UpdateUserModel, { rejectWithValue }) => {
        try {
            return await Interceptor().put(`${URLS.Users}/${userId}`, { ...user });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//create thunk for deleting the user
export const deleteUser = createAsyncThunk(
    'users/user/delete',
    async (userId: string, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.Users}/${userId}`);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// reducers
const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        updateItems: (state, action) => {
            state.users.data = [...action.payload];
        },
        resetUser: (state) => {
            state.user = initialState.user;
        },
        resetUsers: (state) => {
            state.users = initialState.users;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUsers.fulfilled, (state, { payload }) => {
            state.users.data = [...payload.data];
            state.users.total = payload.meta.total;
        });
        builder.addCase(getUserById.fulfilled, (state, { payload }) => {
            const roughData = payload;
            state.user = {
                ...roughData,
                mandator_role_link: [],
                mandator_role_link_rough: roughData.mandator_role_link,
            };
        });
    },
});

// Action creators are generated for each case reducer function
export const { updateItems, resetUser, resetUsers } = usersSlice.actions;
export default usersSlice.reducer;
