import { ComponentType } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';

import { IGridColumn } from '../../common/interfaces';
import { COLUMN_VISIBLE, MIN_COLUMN_WIDTH } from '../../../constants';

/**
 * Returns an array of column definitions for the Templates grid.
 *
 * @param {Map<string, ComponentType<GridCellProps>>} customCells - A map of custom cell components.
 *
 * @returns {IGridColumn[]} An array of column definitions.
 */
export const getTemplateColumns = (
    customCells: Map<string, ComponentType<GridCellProps>>
): IGridColumn[] => {
    return [
        {
            field: 'name',
            title: 'digitalReceipts.entityName',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'type',
            title: 'general.labels.type',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'number_of_sites',
            title: 'digitalReceipts.siteAssignmentCount',
            filter: 'numeric',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
        },
        {
            field: 'created_at',
            title: 'general.labels.createdAt',
            filter: 'date',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            cell: customCells.get('createdAt'),
        },
    ];
};
