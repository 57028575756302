import React, { ChangeEvent, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment, OutlinedInput, Tooltip } from '@mui/material';
import { Search, Close } from '@mui/icons-material';
import { CustomDataState, IGridColumn } from './interfaces';
import { getSearchFilters, getSearchableFields } from '../../helpers';

interface SearchInputProps {
    entityName: string;

    columns?: IGridColumn[];
    componentServerDataState?: CustomDataState;
    defaultValue?: string;
    setComponentServerDataState?: (value: SetStateAction<CustomDataState>) => void;
}

/**
 * Renders a search input component with a clear button.
 *
 * @param {SearchInputProps} props - The component props.
 * @param {IGridColumn[] | undefined} props.columns - The columns to search.
 * @param {CustomDataState | undefined} props.componentServerDataState - The component server data state.
 * @param {Function} props.setComponentServerDataState - The function to set the component server data state.
 * @param {string} props.entityName - The name of the entity.
 * @param {string} [props.defaultValue=''] - The default value of the search input.
 * @return {JSX.Element} The search input component.
 */
const SearchInput = ({
    columns,
    componentServerDataState,
    defaultValue,
    entityName,
    setComponentServerDataState,
}: SearchInputProps): JSX.Element => {
    const { t } = useTranslation();
    const [searchInput, setSearchInput] = useState(defaultValue ?? '');

    const searchableFields = useMemo(() => columns && getSearchableFields(columns), [columns]);

    const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    }, []);

    const handleKeyPress = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter' && searchableFields && setComponentServerDataState) {
                const searchFilters = getSearchFilters(searchableFields, searchInput);
                setComponentServerDataState({
                    ...componentServerDataState,
                    skip: 0,
                    filter: searchFilters,
                });
            }
        },
        [searchableFields, searchInput, componentServerDataState]
    );

    const clearInput = (event: React.MouseEvent<HTMLDivElement>) => {
        const inputElement = event.target as HTMLInputElement;
        inputElement.value = '';
        if (componentServerDataState && setComponentServerDataState) {
            const { filter, ...newState } = componentServerDataState;
            setComponentServerDataState({
                ...newState,
                skip: 0,
            });
        }
        handleSearch(event as unknown as ChangeEvent<HTMLInputElement>);
    };

    return (
        <Tooltip title={t(`general.messages.search.${entityName}`)}>
            <OutlinedInput
                placeholder={t('general.labels.search')}
                value={searchInput}
                onChange={handleSearch}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => handleKeyPress(event)}
                sx={{ width: '220px' }}
                startAdornment={
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                }
                endAdornment={
                    searchInput && (
                        <InputAdornment
                            position="end"
                            onClick={(event) => clearInput(event)}
                            style={{ cursor: 'pointer' }}>
                            <Close />
                        </InputAdornment>
                    )
                }
            />
        </Tooltip>
    );
};
export default SearchInput;
