import { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RestartAlt } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';

import { GridNames } from '../../../constants';
import { removeUserPreferencesData } from '../../../helpers';

interface ResetToDefaultButtonProps {
    dataKey: `${GridNames}`;
    resetData?: Dispatch<SetStateAction<any>>;
}

/**
 * Component used to render 'reset to default' button
 *
 * @param {`${GridNames}`} dataKey - The key associated with the user preferences that need to be removed
 * @param {Dispatch<SetStateAction<null>>} props.resetData - A function that is used to reset the state for user preferences
 * @return {JSX.Element} The JSX element representing the reset to default button
 */
const ResetToDefaultButton = ({ dataKey, resetData }: ResetToDefaultButtonProps): JSX.Element => {
    const { t } = useTranslation();

    const onResetToDefaultButtonClick = useCallback(async () => {
        removeUserPreferencesData({
            key: dataKey,
            resetData: resetData as Dispatch<SetStateAction<null>>,
        });
    }, [dataKey, resetData]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                marginRight: '8px',
            }}>
            <Tooltip title={t('general.labels.resetLogoUpload')}>
                <IconButton className="reset-button" onClick={onResetToDefaultButtonClick}>
                    <RestartAlt />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export default ResetToDefaultButton;
