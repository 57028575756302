import { useEffect, useState } from 'react';
import {
    TreeList,
    mapTree,
    extendDataItem,
    TreeListExpandChangeEvent,
    TreeListItemChangeEvent,
} from '@progress/kendo-react-treelist';

import { EDIT_FIELD, EXPANDED_FIELD } from '../../../constants';
import { TreeListComponentProps, TreeListState } from './interfaces';

/**
 * Renders a TreeList component according to the provided props.
 *
 * @template T - The type of the data items in the tree list.
 * @param {TreeListComponentProps<T>} props - The props for the TreeListComponent.
 * @param {TreeListColumnProps[]} props.columns - The columns of the tree list.
 * @param {T[]} props.data - The data for the tree list.
 * @param {string} props.subItemsField - The field name for the sub-items in the tree list.
 * @param {number[]} props.expandedItems - The IDs of the expanded items in the tree list.
 * @param {(field: string, value: T[]) => void} [props.setFieldValue] - The function to set the field value.
 * @param {string} [props.formField] - The form field.
 * @return {JSX.Element} The rendered TreeList component.
 */
const TreeListComponent = <T extends Record<never, never>>({
    columns,
    data,
    expandedItems,
    formField,
    subItemsField,
    setFieldValue,
}: TreeListComponentProps<T>): JSX.Element => {
    const [state, setState] = useState<TreeListState<T>>({
        data: [],
        expanded: [],
    });

    useEffect(() => {
        setState({ data: data, expanded: expandedItems });
    }, [data, expandedItems]);

    const updateFields = (dataArr: T[]) => {
        const { expanded } = state;
        return mapTree(dataArr, subItemsField, (item) =>
            extendDataItem(item, subItemsField, {
                expanded: expanded.includes(item.id),
                inEdit: true,
            })
        );
    };
    const onExpandChange = (e: TreeListExpandChangeEvent) => {
        setState({
            ...state,
            expanded: e.value
                ? state.expanded.filter((id) => id !== e.dataItem.id)
                : [...state.expanded, e.dataItem.id],
        });
    };
    const updateChildren = (array: T[], field: string, value: T): T[] => {
        return array.map((el: any) => ({
            ...el,
            [field]: value,
            [subItemsField]: el[subItemsField] && updateChildren(el[subItemsField], field, value),
        }));
    };
    const getParentFieldValue = (item: any, field: string, value: T) => {
        if (item[subItemsField]?.every((el: any) => el[field] === value)) {
            return value;
        } else if (
            (!item[subItemsField]?.every((el: any) => el[field]) &&
                item[subItemsField]?.some((el: any) => el[field])) ||
            item[subItemsField]?.some((el: any) => el[field] === null)
        ) {
            return null;
        }

        return item[field];
    };
    const onItemChange = (event: TreeListItemChangeEvent) => {
        const field = event.field || '';
        const newState = {
            ...state,
            data: mapTree(state.data, subItemsField, (item) => {
                if (item.id === event.dataItem.id) {
                    return {
                        ...item,
                        [field]: event.value,
                        [subItemsField]:
                            item[subItemsField] &&
                            updateChildren(item[subItemsField], field, event.value),
                    };
                }
                return {
                    ...item,
                    [field]: getParentFieldValue(item, field, event.value),
                };
            }),
        };
        setFieldValue?.(formField || '', newState.data);
        setState(newState);
    };
    return (
        <TreeList
            editField={EDIT_FIELD}
            columns={columns}
            data={updateFields(state.data)}
            expandField={EXPANDED_FIELD}
            subItemsField={subItemsField}
            onExpandChange={onExpandChange}
            onItemChange={onItemChange}
            className="tree-list-component"
        />
    );
};
export default TreeListComponent;
