export enum FileExtension {
    CSV = 'csv',
    PDF = 'pdf',
    XLSX = 'xlsx',
    REPORT = 'report',
}
export enum DownloadButtonState {
    Load = 'Loading',
    Primary = 'Primary',
}
