import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppBar, Toolbar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// components
import ProfileMenu from './ProfileMenu';
import LicenseMenu from './LicenseMenu';
import MandatorMenu from './MandatorMenu';
import { HeaderProps } from '../../interfaces';
import { clearLSOnComponentLeave, closeModal } from '../../../../helpers';
import { BREAKPOINTS, LIVE_DATA_MODULE, MODAL_TYPES } from '../../../../constants';
//redux
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getUserById, resetUser } from '../../../pages/users/usersDuck';
import { updateLoggedUser } from '../../../auth/authDuck';
import { getAllRoles } from '../../../pages/userPermissions/rolesDuck';
import { updateSelectedModule } from '../userRightsDuck';
import { displayModal } from '../../modal/modalDuck';
import { updatePaletteMode } from '../../../../themes/themeDuck';

/**
 * Renders the header component of the application.
 *
 * @param {Object} props - The props object containing the following properties:
 *   - handleDrawerOpen: A function to handle the opening of the drawer.
 *   - width: The width of the component.
 * @return {JSX.Element} The rendered header component.
 */
const Header = ({ handleDrawerOpen, width }: HeaderProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { userMandatorsList, selectedMandator } = useAppSelector((state) => state.mandators);
    const { logo } = useAppSelector((state) => state.theme);
    const { loggedUser } = useAppSelector((state) => state.auth);

    const { accessToken } = useAppSelector((state) => state.auth);

    const getUser = useCallback(async () => {
        const res = await dispatch(getUserById(localStorage.userId));
        const userData = res.payload;

        if (userData) {
            dispatch(updateLoggedUser(userData));
            dispatch(updatePaletteMode(userData.dark_mode));
            dispatch(resetUser()); //reset user after logged user has been updated
        }
    }, [dispatch]);

    useEffect(() => {
        if (accessToken && localStorage.userId && !loggedUser) {
            getUser();
            dispatch(getAllRoles());
        }
    }, [accessToken, dispatch, getUser, loggedUser]);

    // navigate to dashboard in case the logo is clicked
    const navigateToDashboard = () => {
        localStorage.setItem('selectedModule', LIVE_DATA_MODULE);
        dispatch(updateSelectedModule({ name: LIVE_DATA_MODULE }));
        navigate('/dashboard');
        clearLSOnComponentLeave();
    };

    const handleLogoClick = () => {
        if (localStorage.addOrEdit) {
            dispatch(
                displayModal({
                    showModal: true,
                    title: t('general.messages.notSaved'),
                    type: MODAL_TYPES.ConfirmationMessage,
                    message: 'general.messages.leavePage',
                    onLeave: () => {
                        closeModal();
                        navigateToDashboard();
                    },
                })
            );
        } else {
            navigateToDashboard();
        }
    };

    const getMandatorLogo = useCallback(() => {
        let mandatorLogo = undefined;
        if (selectedMandator) {
            mandatorLogo = logo ?? selectedMandator.logo;
        }
        return mandatorLogo;
    }, [selectedMandator, logo]);

    const defaultMaxWidth = width < BREAKPOINTS.lg ? 150 : undefined;

    return (
        <AppBar
            position="fixed"
            color="secondary"
            sx={{ zIndex: 1201 }}
            elevation={0}
            className="app-header">
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{ pr: 3 }}>
                    <MenuIcon sx={{ width: '24px', height: '24px' }} />
                </IconButton>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleLogoClick}
                    edge="start"
                    sx={{ flex: 0, justifyContent: 'flex-start' }}
                    role="logo"
                    className="header-logo">
                    <img src={getMandatorLogo()} alt="" width="130" height="40" />
                </IconButton>

                <div className="headerContainer">
                    {userMandatorsList.length > 0 && loggedUser && (
                        <MandatorMenu width={width} maxWidth={defaultMaxWidth} />
                    )}
                    {loggedUser && <ProfileMenu width={width} maxWidth={defaultMaxWidth} />}
                    {loggedUser && <LicenseMenu width={width} maxWidth={defaultMaxWidth} />}
                </div>
            </Toolbar>
        </AppBar>
    );
};
export default Header;
