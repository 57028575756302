import { ComponentType } from 'react';
import { TFunction } from 'react-i18next';
import { GridCellProps } from '@progress/kendo-react-grid';

import { IGridColumn } from '../../common/grid/interfaces';
import { COLUMN_VISIBLE, MIN_COLUMN_WIDTH } from '../../../constants';

/**
 * Returns columns for the Device Logs grid, which displays the log messages
 * of a device.
 * @param customCells A map of custom cell components.
 * @param t The translation function.
 * @param logTime The time of the log message.
 * @returns An array of IGridColumn objects.
 */
export const getLogMessageColumns = (
    customCells: Map<string, ComponentType<GridCellProps>>,
    t: TFunction,
    logTime: string
): IGridColumn[] => [
    {
        field: 'received',
        title: t('devices.logs.logMessage', {
            time: logTime,
        }),
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
        sortable: false,
        cell: customCells.get('message'),
    },
    {
        field: 'sent',
        title: t('devices.logs.reply', {
            time: logTime,
        }),
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
        sortable: false,
        cell: customCells.get('message'),
    },
    {
        field: 'details',
        title: t('devices.logs.details'),
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
        sortable: false,
    },
];
