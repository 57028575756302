import { Dispatch, SetStateAction } from 'react';
import {
    ChartDataProps,
    ChartPoint,
    GPSCoordinates,
    GraphResponse,
    Graphs,
} from '../app/common/interfaces';
import {
    Mandator,
    SitePointsData,
    CustomizationObject,
    SelectDataType,
    ExtendedProduct,
} from '../app/pages/interfaces';
import {
    BONNDORF_COORDINATES,
    ChartColors,
    DEFAULT_CHART_DATA,
    DEFAULT_CHART_TYPE,
    DashboardMarkerStatuses,
    NOMINATIM_GEOCODE_URL,
    PAYMENT_METHODS_TITLE,
    PointStatuses,
    SITES_RANKING_FILTERS,
    SITES_RANKING_TITLE,
} from '../constants';
import i18n from '../i18n';
import { geocode, sortArrayDescending, translateTerm } from '.';

/**
 * Returns the customization object for a given graph title.
 *
 * @param {string} graphTitle - The title of the graph.
 * @param {CustomizationObject[]} graphsCustomization - An array of customization objects.
 * @return {CustomizationObject | object} The customization object for the given graph title, or an empty object if not found.
 */
export const getGraphCustomization = (
    graphTitle: string,
    graphsCustomization: CustomizationObject[]
): CustomizationObject | object => {
    return (
        graphsCustomization.filter(
            (item: CustomizationObject) => item.configuration.system_name === graphTitle
        )[0] ?? {}
    );
};

/**
 * Parses and extracts the first element of the configuration property from each item in the data array.
 *
 * @param {CustomizationObject[]} data - An array of customization objects.
 * @return {CustomizationObject[]} - An array of customization objects with the configuration property parsed and extracted.
 */
export const changeGraphCustomizationData = (
    data: CustomizationObject[]
): CustomizationObject[] => {
    return data.map((item) => {
        return { ...item, configuration: JSON.parse(item.configuration as unknown as string)[0] };
    });
};

/**
 * Generates a custom card object based on the provided title, avatar, and statistics data.
 *
 * @param {string} title - The title of the card.
 * @param {string} avatar - The avatar of the card.
 * @param {GraphResponse} statisticsData - The statistics data used to generate the card.
 * @return {object} A custom card object with kind, title, avatar, and data properties.
 */
const generateCustomCard = (title: string, avatar: string, statisticsData: GraphResponse) => {
    const transactionsChartsData =
        title === 'charts.totalAmount'
            ? (statisticsData?.transactions_total_amount as ChartDataProps)
            : (statisticsData?.transactions_count as ChartDataProps);
    return {
        kind: 'custom',
        title,
        avatar,
        data: transactionsChartsData ?? DEFAULT_CHART_DATA,
    };
};

/**
 * Generates a chart for site ranking based on the provided transaction statistics and customization options.
 *
 * @param {GraphResponse} transactionStatistics - The transaction statistics data.
 * @param {CustomizationObject[]} graphsCustomization - The customization options for the chart.
 * @param {string} sites_ranking_filter - The filter to apply to the site ranking data.
 * @return {ChartDataProps} The generated chart data.
 */
export const generateSitesRankingChart = (
    transactionStatistics: GraphResponse,
    graphsCustomization: CustomizationObject[],
    sites_ranking_filter: string
): ChartDataProps => {
    const sitesRankingAmountFilter = sites_ranking_filter === SITES_RANKING_FILTERS[0].value;
    const rankingData = (transactionStatistics?.[sites_ranking_filter] as ChartDataProps[]) ?? [];

    const sitesRankingCustomization: CustomizationObject = getGraphCustomization(
        SITES_RANKING_TITLE,
        graphsCustomization
    ) as CustomizationObject;

    const trxTotalAmount: number =
        ((transactionStatistics?.transactions_total_amount as ChartDataProps)?.value as number) ??
        1;
    const trxTotalNumber: number =
        ((transactionStatistics?.transactions_count as ChartDataProps)?.value as number) ?? 1;

    const sitesRankingData = rankingData.map((item) => {
        const itemValue = sitesRankingAmountFilter
            ? [Number(((item?.amount ?? 0) / trxTotalAmount).toFixed(4))]
            : [Number(((item?.transactions ?? 0) / trxTotalNumber).toFixed(4))];
        return {
            name: item?.site_name,
            value: itemValue,
        };
    });

    let sitesRankingGraph: ChartDataProps = {
        kind: DEFAULT_CHART_TYPE,
        title: SITES_RANKING_TITLE,
        formatting: 'p',
        data: sortArrayDescending(sitesRankingData, 'value'),
        hasActions: true,
    };
    if (Object.keys(sitesRankingCustomization).length !== 0) {
        sitesRankingGraph = {
            ...sitesRankingGraph,
            kind: sitesRankingCustomization?.configuration?.type,
            seriesColor: sitesRankingCustomization?.configuration?.series_colors ?? undefined,
        };
    }

    return sitesRankingGraph;
};

/**
 * Generates a chart for the total quantity based on the provided transaction statistics and unit measure.
 *
 * @param {GraphResponse} transactionStatistics - The transaction statistics data.
 * @param {string} total_quantity_unit_measure - The unit measure for the total quantity.
 * @return {ChartDataProps} The chart data for the total quantity.
 */
export const generateTotalQuantityChart = (
    transactionStatistics: GraphResponse,
    total_quantity_unit_measure: string
): ChartDataProps => {
    const totalQuantityData =
        ((transactionStatistics?.article_quantities_ranking as ChartDataProps[]).find(
            (quantityData) => quantityData.unit_measure === total_quantity_unit_measure
        )?.articles_quantities as ChartDataProps[]) ?? [];

    const totalQuantityChart: ChartDataProps = {
        kind: DEFAULT_CHART_TYPE,
        title: 'charts.totalQuantity',
        formatting: 'n',
        data: totalQuantityData.map((item) => {
            return {
                name: item.article_name,
                value: [item.quantity ?? 0],
            };
        }),
    };
    return totalQuantityChart;
};

/**
 * Generates a chart for payment methods based on transaction statistics and customization options.
 *
 * @param {GraphResponse} transactionStatistics - The transaction statistics data.
 * @param {CustomizationObject[]} graphsCustomization - The customization options for the graph.
 * @param {string} payment_method_key - The key for the payment method.
 * @return {ChartDataProps} The chart data for the payment methods graph.
 */
export const generatePaymentMethodsGraph = (
    transactionStatistics: GraphResponse,
    graphsCustomization: CustomizationObject[],
    payment_method_key: string
): ChartDataProps => {
    const paymentMethodName = payment_method_key.split('_ranking')[0];

    const paymentData = (transactionStatistics?.[payment_method_key] as ChartDataProps[]) ?? [];

    const paymentMethodsCustomization: CustomizationObject | object = getGraphCustomization(
        PAYMENT_METHODS_TITLE,
        graphsCustomization
    );

    let paymentMethodsChart: ChartDataProps = {
        kind: DEFAULT_CHART_TYPE,
        title: PAYMENT_METHODS_TITLE,
        formatting: 'n', // number
        data: paymentData
            .filter((item) => item[paymentMethodName])
            .map((item) => {
                const translationKey = `charts.cardTypes.${item[paymentMethodName]?.toLowerCase()}`;
                return {
                    name: translateTerm(translationKey, item[paymentMethodName] as string),
                    value: [parseFloat((item.amount ?? 0).toFixed(2))],
                };
            }),
        hasActions: true,
        isPopoverVisible: true,
    };
    if (Object.keys(paymentMethodsCustomization).length !== 0) {
        const { configuration } = paymentMethodsCustomization as CustomizationObject;
        paymentMethodsChart = {
            ...paymentMethodsChart,
            kind: configuration?.type,
            seriesColor: configuration?.series_colors ?? undefined,
        };
    }
    return paymentMethodsChart;
};

/**
 * Calculates the graphs based on the given transaction statistics, site point data, and coordinates.
 *
 * @param {GraphResponse} transactionStatistics - The statistics of the transactions.
 * @param {SitePointsData} sitesPointData - The data of the site points.
 * @param {GPSCoordinates} coordinates - The coordinates of the site.
 * @return {Graphs} The calculated graphs.
 */
export const calculateGraphs = (
    transactionStatistics: GraphResponse,
    sitesPointData: SitePointsData,
    coordinates: GPSCoordinates
): Graphs => {
    const pointsStatus: ChartDataProps[] = pointStatusChartData(sitesPointData);
    const calculatedGraphs: Graphs = {};

    calculatedGraphs.totalAmount = generateCustomCard(
        'charts.totalAmount',
        'total',
        transactionStatistics
    );
    calculatedGraphs.transactions = generateCustomCard(
        'charts.transactions',
        'tickets',
        transactionStatistics
    );

    calculatedGraphs.sitesLocations = {
        kind: 'map',
        title: 'charts.sitesLocations',
        data: sitesPointData.statuses,
        mapCoordinates: coordinates,
    };
    calculatedGraphs.pointsStatus = {
        kind: 'column',
        title: 'charts.pointsStatus',
        formatting: 'n',
        data: pointsStatus,
        menuData: sitesPointData,
    };
    return calculatedGraphs;
};

/**
 * Order devices and points by status.
 *
 * @param {ChartDataProps[]} sites - An array of ChartDataProps objects representing the sites.
 * @return {SitePointsData} An object containing the calculated site points status.
 */
export const calculateSitePointsStatus = (sites: ChartDataProps[]): SitePointsData => {
    let statuses: ChartDataProps[] = [];
    let totalAvailablePoints: ChartPoint[] = [];
    let totalInUsePoints: ChartPoint[] = [];
    let totalOutOfOrderPoints: ChartPoint[] = [];
    let totalOccupiedPoints: ChartPoint[] = [];
    let totalUnknownPoints: ChartPoint[] = [];
    let totalReservedPoints: ChartPoint[] = [];

    sites.forEach((site) => {
        let siteStatus = DashboardMarkerStatuses.Available;
        const activeDevices = site.devices?.filter((device) => device.device_state === true) ?? [];
        const inactiveDevices =
            site.devices?.filter((device) => device.device_state === false) ?? [];

        const outOfOrderPoints =
            site.points?.filter((point) => point.status === PointStatuses.OutOfOrder) ?? [];
        const inUsePoints =
            site.points?.filter((point) => point.status === PointStatuses.InUse) ?? [];
        const reservedPoints =
            site.points?.filter((point) => point.status === PointStatuses.Reserved) ?? [];
        const occupiedPoints =
            site.points?.filter((point) => point.status === PointStatuses.Occupied) ?? [];
        const availablePoints =
            site.points?.filter((point) => point.status === PointStatuses.Available) ?? [];
        const unknownPoints =
            site.points?.filter((point) => point.status === PointStatuses.Unknown) ?? [];

        totalOutOfOrderPoints = outOfOrderPoints.concat(totalOutOfOrderPoints);
        totalInUsePoints = inUsePoints.concat(totalInUsePoints);
        totalReservedPoints = reservedPoints.concat(totalReservedPoints);
        totalOccupiedPoints = occupiedPoints.concat(totalOccupiedPoints);
        totalAvailablePoints = availablePoints.concat(totalAvailablePoints);
        totalUnknownPoints = unknownPoints.concat(totalUnknownPoints);

        if (outOfOrderPoints.length > 0) {
            siteStatus = DashboardMarkerStatuses.OutOfOrder;
        } else if (inUsePoints.length > 0 || reservedPoints.length > 0) {
            siteStatus = DashboardMarkerStatuses.InUse;
        } else if (occupiedPoints.length > 0) {
            siteStatus = DashboardMarkerStatuses.Occupied;
        } else if (unknownPoints.length > 0) {
            siteStatus = DashboardMarkerStatuses.Unknown;
        }

        statuses = [
            ...statuses,
            {
                ...site,
                devices: [...inactiveDevices, ...activeDevices],
                points: [
                    ...outOfOrderPoints,
                    ...inUsePoints,
                    ...reservedPoints,
                    ...occupiedPoints,
                    ...availablePoints,
                    ...unknownPoints,
                ],
                status: siteStatus,
            },
        ];
    });
    return {
        statuses,
        totalAvailablePoints,
        totalInUsePoints,
        totalOccupiedPoints,
        totalOutOfOrderPoints,
        totalReservedPoints,
        totalUnknownPoints,
    };
};

/**
 * Generates chart data for point statuses.
 *
 * @param {SitePointsData} sitesPointData - The data containing point statuses.
 * @return {Array} An array of objects containing name, value, and color for each point status.
 */
export const pointStatusChartData = (sitesPointData: SitePointsData) => {
    return [
        {
            name: 'points.statuses.available',
            value: [sitesPointData.totalAvailablePoints.length ?? 0],
            color: ChartColors.Green,
        },
        {
            name: 'points.statuses.outOfOrder',
            value: [sitesPointData.totalOutOfOrderPoints.length ?? 0],
            color: ChartColors.Red,
        },
        {
            name: 'points.statuses.occupied',
            value: [sitesPointData.totalOccupiedPoints.length ?? 0],
            color: ChartColors.Blue,
        },
        {
            name: 'points.statuses.inUse',
            value: [sitesPointData.totalInUsePoints.length ?? 0],
            color: ChartColors.Yellow,
        },
        {
            name: 'points.statuses.unknown',
            value: [sitesPointData.totalUnknownPoints.length ?? 0],
            color: ChartColors.Gray,
        },
    ];
};

/**
 * Retrieves the GPS coordinates of a given mandator by geocoding their address.
 *
 * @param {Mandator} currentMandator - The mandator object containing the address information.
 * @param {Dispatch<SetStateAction<GPSCoordinates>>} setMandatorCoordinates - The state setter function for the mandator coordinates.
 * @return {Promise<void>} - A promise that resolves when the mandator coordinates have been set.
 */
export const getMandatorCoordinates = async (
    currentMandator: Mandator,
    setMandatorCoordinates: Dispatch<SetStateAction<GPSCoordinates>>
): Promise<void> => {
    let currentMandatorCoordinates: GPSCoordinates = {
        latitude: BONNDORF_COORDINATES.lat,
        longitude: BONNDORF_COORDINATES.lng,
    };

    const addressQuery = `${currentMandator.street || ''} + ${currentMandator.city} + ${
        currentMandator.country
    }`;
    const address = await geocode(NOMINATIM_GEOCODE_URL(addressQuery));
    if (address && address[0]) {
        currentMandatorCoordinates = {
            latitude: address[0].lat,
            longitude: address[0].lon,
        };
    }

    setMandatorCoordinates(currentMandatorCoordinates);
};

/**
 * Translates the name property of each item in the provided select data.
 *
 * @param {SelectDataType[]} data - The select data to be translated.
 * @return {SelectDataType[]} The translated select data.
 */
export const getTranslatedSelectData = (data: SelectDataType[]): SelectDataType[] => {
    return data.map((item) => ({
        ...item,
        name: i18n.t(item.name),
    }));
};

/**
 * Generates properties for a select filter component.
 *
 * @param {SelectDataType[]} selectData - The data to be used for the select filter.
 * @param {any} handleChangeSelectValue - The function to be called when the select value changes.
 * @param {string} selectDefaultValue - The default value of the select filter.
 * @param {string} selectName - The name of the select filter.
 * @param {boolean} [isDataTranslated] - Whether the select data should be translated.
 * @return {object} An object containing the properties for the select filter component.
 */
export const generateSelectFilterProperties = (
    selectData: SelectDataType[],
    handleChangeSelectValue: any,
    selectDefaultValue: string,
    selectName: string,
    isDataTranslated = true
) => {
    const translatedData = getTranslatedSelectData(selectData);
    return {
        isSelectFilterAvailable: true,
        selectFilterData: isDataTranslated ? translatedData : selectData,
        handleChangeSelectFilterData: handleChangeSelectValue,
        selectFilterDefaultValue: selectDefaultValue,
        selectName: selectName,
    };
};

/**
 * Generates an array of unique unit measures from the given array of ExtendedProduct objects.
 *
 * @param {ExtendedProduct[]} mandatorProducts - The array of ExtendedProduct objects.
 * @return {string[]} An array of unique unit measures sorted in ascending order.
 */
export const getProductsUnitMeasures = (mandatorProducts: ExtendedProduct[]): string[] => {
    const productsUnitMeasures: string[] = [];
    mandatorProducts.forEach((product) => {
        if (!productsUnitMeasures.includes(product?.unit_measure ?? ''))
            productsUnitMeasures.push(product?.unit_measure ?? '');
    });
    return productsUnitMeasures.sort();
};

/**
 * Generates an array of unit measures with their corresponding uppercase values.
 *
 * @param {ExtendedProduct[]} mandatorProducts - The array of ExtendedProduct objects.
 * @return {object[]} An array of objects containing the unit measure and its uppercase value.
 */
export const getTotalQuantityUnitMeasures = (
    mandatorProducts: ExtendedProduct[]
): { name: string; value: string }[] => {
    const newUnitMeasures = getProductsUnitMeasures(mandatorProducts);
    const unitMeasuresData = newUnitMeasures.map((unitMeasure) => {
        return { name: unitMeasure, value: unitMeasure.toUpperCase() };
    });
    return unitMeasuresData;
};
