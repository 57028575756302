import { DataResult } from '@progress/kendo-data-query';
import { FormikProps } from 'formik';
import { Dispatch, RefObject, SetStateAction } from 'react';
import { IGridColumn, ModulePermissions } from '../app/common/interfaces';
import { ModuleType } from '../app/common/layout/userRightsDuck';
import { LayoutProps } from '../app/pages/interfaces';
import { MARKETPLACE_MENU_ITEMS } from '../constants';

/**
 * Removes empty attributes from an object.
 *
 * @param {object} obj - The object to remove empty attributes from.
 * @return {any} The object with empty attributes removed.
 */
export const removeEmptyAttributes = (obj: object): any => {
    return Object.fromEntries(
        Object.entries(obj)
            .filter(([_, v]) => v != null && v !== '')
            .map(([k, v]) => [k, v === Object(v) ? removeEmptyAttributes(v) : v])
    );
};

/**
 * Removes redundant attributes from an object based on a list of fields.
 *
 * @param {object} obj - The object to remove redundant attributes from.
 * @param {string[]} fields - The list of fields to keep.
 * @return {any} The object with redundant attributes removed.
 */
export const removeRedundantAttributes = (obj: object, fields: string[]): any => {
    return Object.fromEntries(
        Object.entries(obj)
            .filter(([key]) => !fields.includes(key))
            .map(([k, v]) => [k, v === Object(v) ? removeRedundantAttributes(v, fields) : v])
    );
};

/**
 * Recursively sanitizes the fields of an object by removing empty attributes and null ones.
 *
 * @param {object} obj - The object to sanitize.
 * @return {any} The sanitized object.
 */
export const sanitizeFields = (obj: object): any => {
    if (typeof obj === 'string') return sanitizeStringField(obj);
    if (!obj || obj instanceof Date) return obj;
    if (Array.isArray(obj))
        return obj.map((value) => (value !== null ? sanitizeFields(value) : value));
    if (Object.entries(obj).length > 1) {
        return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, sanitizeFields(v)]));
    }
    return obj;
};

/**
 * Sanitizes a string field by trimming whitespace and converting empty strings to null.
 *
 * @param {string} value - The string field to be sanitized.
 * @return {string | null} The sanitized string field, or null if the input was an empty string.
 */
export const sanitizeStringField = (value: string): string | null => {
    if (typeof value === 'string') {
        return value === '' ? null : value.trim();
    }
    return value;
};

/**
 * Normalizes a string by converting it to the decomposed normalization form (NFD) and removing any diacritical marks.
 *
 * @param {string} value - The string to be normalized.
 * @return {string} The normalized string.
 */
export const normalizeStringField = (value: string): string => {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

/**
 * Resets the values of multiple fields in a Formik form.
 *
 * @template T - The type of the form values.
 * @param {RefObject<FormikProps<T>>} formRef - A reference to the Formik form.
 * @param {string[]} fields - An array of field names to reset.
 * @param {any} [customValue] - An optional custom value to set the fields to.
 * @return {void}
 */
export const resetFormFields = <T>(
    formRef: RefObject<FormikProps<T>>,
    fields: string[],
    customValue?: any
): void => {
    if (formRef.current?.values) {
        fields.forEach((field) => {
            formRef.current?.setFieldValue(field, customValue ?? null);
        });
    }
};

/**
 * Deep clones an array or object.
 *
 * @param {T[] | DataResult | LayoutProps} value - The array or object to be deep cloned.
 * @return {T[] | DataResult | LayoutProps} The deep cloned array or object.
 */
export const deepCloneArray = <T>(value: T[] | DataResult | LayoutProps) => {
    return JSON.parse(JSON.stringify(value));
};

/**
 * Checks the user's rights for a given module.
 *
 * @param {ModuleType | null} module - The module to check the user's rights for.
 * @param {ModulePermissions[]} userRights - The user's rights.
 * @param {Map<string, boolean | undefined>} [payedModules] - A map of paid modules.
 * @param {Map<string, boolean | undefined>} [payedComponents] - A map of paid components.
 * @param {string[]} [activeModules] - An array of active modules.
 * @return {boolean} True if the user has rights for the module, false otherwise.
 */
export const checkModuleRights = (
    module: ModuleType | null,
    userRights: ModulePermissions[],
    payedModules?: Map<string, boolean | undefined>,
    payedComponents?: Map<string, boolean | undefined>,
    activeModules?: string[]
): boolean => {
    const currentModuleRights = userRights.filter((el) => el.module === module?.name);
    const moduleMarketplaceDependency = MARKETPLACE_MENU_ITEMS.includes(module?.name ?? '');
    let selectedModuleRight = true;

    const isCurrentModuleHidden = currentModuleRights.every(
        (currentModulePermissions: ModulePermissions) => {
            return (
                currentModulePermissions.view === false ||
                payedComponents?.get(currentModulePermissions.sub_component ?? '') === false
            );
        }
    );

    if (isCurrentModuleHidden || payedModules?.get(module?.name ?? '') === false) {
        selectedModuleRight = false;
    }
    if (moduleMarketplaceDependency) {
        const marketplaceMenuEntriesForCurrentModule = currentModuleRights.filter(
            (moduleItem) => moduleItem.service_id
        );
        const availableMenuEntries = marketplaceMenuEntriesForCurrentModule.some((menuEntry) => {
            return activeModules?.includes(menuEntry?.service_id ?? '') && menuEntry.view === true;
        });

        const menuEntriesForModuleWithoutDependency = currentModuleRights
            .filter((items) => !marketplaceMenuEntriesForCurrentModule.includes(items))
            .some((moduleItem) => {
                return (
                    moduleItem.view === true &&
                    payedComponents?.get(moduleItem.sub_component ?? '') !== false
                );
            });

        if (availableMenuEntries || menuEntriesForModuleWithoutDependency) {
            selectedModuleRight = true;
        }
        if (!availableMenuEntries && !menuEntriesForModuleWithoutDependency) {
            selectedModuleRight = false;
        }
    }
    return selectedModuleRight;
};

/**
 * Swaps the elements at the given indices in the provided array.
 *
 * @template T - The type of elements in the array.
 * @param {number} currentIndex - The index of the first element to swap.
 * @param {number} changedIndex - The index of the second element to swap.
 * @param {T[]} array - The array in which to swap the elements.
 * @return {T[]} The modified array with the swapped elements.
 */
export const changeArrayElements = <T>(
    currentIndex: number,
    changedIndex: number,
    array: T[]
): T[] => {
    if (array[currentIndex] && array[changedIndex])
        [array[currentIndex], array[changedIndex]] = [array[changedIndex], array[currentIndex]];
    return array;
};

/**
 * Sorts an array of objects by a specified field in descending order.
 *
 * @template T - The type of objects in the array.
 * @param {T[]} arr - The array of objects to be sorted.
 * @param {string} fieldName - The name of the field to sort by.
 * @return {T[]} The sorted array of objects in descending order.
 */
export const sortArrayDescending = <T extends Record<string, any>>(
    arr: T[],
    fieldName: string
): T[] => {
    return arr.sort((firstItem, secondItem) => secondItem[fieldName][0] - firstItem[fieldName][0]);
};

/**
 * Sorts an array of objects by a specified field in either ascending or descending order.
 *
 * @param {T[]} arr - The array of objects to be sorted.
 * @param {string} fieldName - The name of the field to sort by.
 * @param {string} [direction] - The sorting direction. If not provided, the array will be sorted in ascending order.
 * @return {T[]} The sorted array of objects.
 */
export const sortByField = <T extends Record<string, any>>(
    arr: T[],
    fieldName: string,
    direction?: string
): T[] => {
    if (direction === 'desc') {
        const sortDescending = (firstItem: T, secondItem: T) => {
            return typeof firstItem[fieldName] === 'string'
                ? firstItem[fieldName].toUpperCase() < secondItem[fieldName].toUpperCase()
                : firstItem[fieldName] < secondItem[fieldName];
        };
        return arr.sort((firstItem: T, secondItem: T) =>
            sortDescending(firstItem, secondItem) ? 1 : -1
        );
    }
    const sortAscending = (firstItem: T, secondItem: T) => {
        return typeof firstItem[fieldName] === 'string'
            ? firstItem[fieldName].toUpperCase() > secondItem[fieldName].toUpperCase()
            : firstItem[fieldName] > secondItem[fieldName];
    };
    return arr.sort((firstItem: T, secondItem: T) =>
        sortAscending(firstItem, secondItem) ? 1 : -1
    );
};

/**
 * Flattens an array of objects by a specified nested field.
 *
 * @param {T[]} arr - The array of objects to be flattened.
 * @param {string} nestedField - The name of the nested field.
 * @return {T[]} The flattened array of objects.
 */
export const flattenArray = <T extends Record<string, any>>(arr: T[], nestedField: string): T[] => {
    return arr.reduce((prev: T[], curr: T) => {
        prev = [...prev, curr];
        if (curr[nestedField]) {
            prev = [...prev, ...curr[nestedField]];
        }
        return prev;
    }, []);
};

/**
 * Compares two objects of the same type and returns an object containing arrays of the fields that are different,
 * processed by the function sent as parameter. The 'initialItem' param is optional; in case it is missing, the
 * 'initialItemDifferences' array will be empty.
 *
 * @template T - The type of the objects being compared.
 * @param {(key: string | number | symbol, value: any) => string} dataProcessor - The function used to process the
 *                                                                             data before comparison.
 * @param {Array<string | number | symbol>} keysArray - The array of keys to compare.
 * @param {T} currentItem - The object to compare with the initialItem.
 * @param {T} [initialItem] - The object to compare with the currentItem.
 * @return {{ currentItemDifferences: string[], initialItemDifferences: string[] }} - An object containing two arrays
 *                                                                                  of the fields that are different.
 */
export const generateComparisonDataItem = <T extends Record<string, any>>(
    dataProcessor: (key: string | number | symbol, value: any) => string,
    keysArray: Array<string | number | symbol>,
    currentItem: T,
    initialItem?: T
): { currentItemDifferences: string[]; initialItemDifferences: string[] } => {
    const comparisonData = {
        currentItemDifferences: [] as string[],
        initialItemDifferences: [] as string[],
    };

    Object.keys(currentItem).forEach((objectKey) => {
        const key = objectKey as keyof T;
        if (keysArray.includes(key)) {
            if ((initialItem && currentItem[key] !== initialItem[key]) || !initialItem) {
                const currentItemData = dataProcessor(key, currentItem[key]);
                const initialItemData = initialItem ? dataProcessor(key, initialItem[key]) : '';
                currentItemData !== '' &&
                    comparisonData.currentItemDifferences.push(currentItemData);
                initialItemData !== '' &&
                    comparisonData.initialItemDifferences.push(initialItemData);
            }
        }
    });
    return comparisonData;
};

// check to see if any item is assigned to parent

/**
 * Checks to see if any item is assigned to parent.
 *
 * @param {any} haystack - The array of items to check for bulk delete allowance.
 * @param {string} [conditionField] - The field to use for the condition check.
 * @return {boolean} Indicates whether bulk delete is allowed.
 */
export const isBulkDeleteAllowed = <T extends Record<string, any>>(
    haystack: any,
    conditionField?: string
): boolean => {
    return conditionField
        ? haystack.every((straw: T) => straw[conditionField].toString() === '0')
        : haystack.every((straw: T) => straw.toString() === '0');
};

/**
 * Checks if an item is allowed to be deleted based on the parent.
 *
 * @param {T[]} entities - The array of entities to check against.
 * @param {string} itemAttributeValue - The value of the item attribute to check.
 * @param {string} conditionField - The field to check for the condition.
 * @return {boolean} Returns true if the item is allowed to be deleted, false otherwise.
 */
export const isDeleteAllowed = <T>(
    entities: T[],
    itemAttributeValue: string,
    conditionField: string
): boolean => {
    const existingEntity = entities.find(
        (entity) => entity['id' as keyof T] === itemAttributeValue
    );

    return (existingEntity?.[conditionField as keyof T] as number) === 0;
};

/**
 * Finds a key in a Map object based on the corresponding value.
 *
 * @param {Map<string, string>} map - The Map object to search for the key.
 * @param {string} value - The value to find the corresponding key for.
 * @return {string | undefined} The key corresponding to the given value, or undefined if not found.
 */
export const getKeyByValue = (map: Map<string, string>, value: string): string | undefined => {
    return Array.from(map.keys()).find((key) => map.get(key) === value);
};

/**
 * Updates the grid columns by replacing the last element with the last element from the initial columns.
 *
 * @param {IGridColumn[]} columns - The current grid columns.
 * @param {IGridColumn[]} initialColumns - The initial grid columns.
 * @param {Dispatch<SetStateAction<IGridColumn[]>>} setColumns - The state setter function for the grid columns.
 * @return {void} This function does not return a value.
 */
export const updateGridColumns = (
    columns: IGridColumn[],
    initialColumns: IGridColumn[],
    setColumns: Dispatch<SetStateAction<IGridColumn[]>>
): void => {
    // update only the last element
    const newColumns = columns.map((column, index) =>
        index === columns.length - 1 ? initialColumns[initialColumns.length - 1] : column
    );
    setColumns(newColumns);
};

/**
 * Returns an array containing the elements from array1 that do not have a matching
 * attribute value in array2.
 *
 * @param {T[]} array1 - The first array to compare.
 * @param {T[]} array2 - The second array to compare.
 * @param {string} attribute - The attribute to compare between objects in the arrays.
 * @return {T[]} An array containing the elements from array1 that do not have a matching
 * attribute value in array2.
 */
export const getDifference = <T>(array1: T[], array2: T[], attribute: string): T[] => {
    return array1.filter((object1) => {
        return !array2.some((object2) => {
            return object1[attribute as keyof T] === object2[attribute as keyof T];
        });
    });
};

/**
 * Returns an object containing the key-value pairs from the 'newObject' that are different from the corresponding values in the 'initialObject'.
 *
 * @param {any} initialObject - The initial object to compare against.
 * @param {any} newObject - The object to compare with the initial object.
 * @return {any} An object containing the key-value pairs from the 'newObject' that are different from the corresponding values in the 'initialObject'.
 */
export const getObjectsDifferences = (initialObject: any, newObject: any): any => {
    const changedObject: any = {};
    for (const key in initialObject) {
        if (
            Object.prototype.hasOwnProperty.call(newObject, key) &&
            initialObject[key] !== newObject[key]
        ) {
            changedObject[key] = newObject[key];
        }
    }
    return changedObject;
};

/**
 * Checks if an array is nullish, meaning all its elements are null or undefined.
 *
 * @param {T[]} arr - The array to check.
 * @return {boolean} Returns true if all elements in the array are null or undefined, false otherwise.
 */
export const isNullishArray = <T>(arr: T[]): boolean => {
    const allNull = arr.every((element) => element === null);
    const allUndefined = arr.every((element) => element === undefined);

    if (allNull || allUndefined) return true;
    return false;
};

export const isValidUUID = (stringForTest: string) => {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(stringForTest);
};

export const convertUserRightsDataForState = (userRights: any, componentState?: boolean) => {
    const newData = userRights.map((right: any) => {
        const [firstSubComponentPart, ...subComponentParts] =
            right?.sub_component?.split('.') ?? [];
        const isMarketplacePage = isValidUUID(firstSubComponentPart);
        let newRight = right;
        if (isMarketplacePage) {
            newRight = componentState
                ? { ...right, sub_component: subComponentParts.join('.') }
                : {
                      ...right,
                      sub_component: subComponentParts.join('.'),
                      service_id: firstSubComponentPart,
                  };
        }
        return newRight;
    });
    return newData;
};

/**
 * Checks if an object is not empty, meaning it has at least one key-value pair.
 *
 * @param {object} obj - The object to check.
 * @return {boolean} Returns true if the object is not empty, false otherwise.
 */
export function isEmptyObject(obj: object): boolean {
    return Object.keys(obj).length === 0;
}
