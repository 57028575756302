import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';

import { closeModal, isBulkDeleteAllowed } from '../../../../helpers';

interface UnassignTemplatesProps {
    numberOfSites: number | number[];
    onDeleteTemplate?: () => void;
}

/**
 * Confirmation dialog for deleting templates.
 *
 * @param {{ onDeleteTemplate?: () => void, numberOfSites: number | number[] }} props
 * @returns {JSX.Element}
 */
const UnassignTemplates = ({
    numberOfSites,
    onDeleteTemplate,
}: UnassignTemplatesProps): JSX.Element => {
    const { t } = useTranslation();
    const [unassignTemplate, setUnassignTemplate] = useState(false);
    const cancelBtn = useMemo(() => t('general.labels.cancel'), [t]);
    const confirmBtn = useMemo(() => t('general.labels.ok'), [t]);
    const bulkDeleteTemplates = Array.isArray(numberOfSites);

    const isTemplateAssigned = useMemo(() => {
        return bulkDeleteTemplates ? !isBulkDeleteAllowed(numberOfSites) : numberOfSites > 0;
    }, [bulkDeleteTemplates, numberOfSites]);

    // do the delete request only if checkbox is checked or template(s) is(are) not assigned
    // delete will handle also the assignation part
    const handleDeleteTemplate = useCallback(() => {
        if (!isTemplateAssigned) {
            onDeleteTemplate?.();
        } else {
            unassignTemplate ? onDeleteTemplate?.() : closeModal();
        }
    }, [isTemplateAssigned, onDeleteTemplate, unassignTemplate]);

    const confirmationMessage = isTemplateAssigned ? (
        <>
            <Typography variant="h3" align="center">
                {bulkDeleteTemplates
                    ? t('digitalReceipts.deleteTemplatesRestriction')
                    : t('digitalReceipts.deleteTemplateRestriction', {
                          sites_count: numberOfSites,
                          count: numberOfSites,
                      })}
            </Typography>
            <Typography variant="h3" align="center" marginTop={3}>
                {bulkDeleteTemplates
                    ? t('digitalReceipts.deleteTemplatesRestrictionSolution')
                    : t('digitalReceipts.deleteTemplateRestrictionSolution')}
            </Typography>
        </>
    ) : (
        <Typography variant="h3" align="center">
            {t('general.messages.delete.template', {
                count: bulkDeleteTemplates ? 2 : 1,
            })}
        </Typography>
    );

    const actionButtons = useMemo(() => {
        return (
            <>
                <Button variant="outlined" sx={{ px: 6 }} onClick={closeModal}>
                    {cancelBtn}
                </Button>
                <Button variant="contained" sx={{ px: 6 }} onClick={handleDeleteTemplate}>
                    {confirmBtn}
                </Button>
            </>
        );
    }, [handleDeleteTemplate, cancelBtn, confirmBtn]);

    const handleChangeCheckboxValue = (event: SyntheticEvent<Element, Event>, checked: boolean) => {
        setUnassignTemplate(checked);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: 2, pt: 2 }}>
            {confirmationMessage}

            {isTemplateAssigned && (
                <FormControlLabel
                    value={unassignTemplate}
                    control={<Checkbox />}
                    label={t('digitalReceipts.unassign', {
                        count: bulkDeleteTemplates ? 2 : 1,
                    })}
                    onChange={handleChangeCheckboxValue}
                />
            )}
            <Box sx={{ display: 'flex', gap: 1, mt: 4 }}>{actionButtons}</Box>
        </Box>
    );
};

export default UnassignTemplates;
