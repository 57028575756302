import Color from 'color';
import { Mandator } from '../app/pages/interfaces';
import { DEFAULT_THEME } from '../constants';
import { updateTheme } from '../themes/themeDuck';
import { staticColors } from '../themes/themes';

/**
 * Updates the selected theme based on the provided mandator and dispatch function.
 *
 * @param {Mandator | null} mandator - The mandator object or null.
 * @param {(action: any) => any} dispatch - The dispatch function.
 * @return {void}
 */
export const updateSelectedTheme = (
    mandator: Mandator | null,
    dispatch: (action: any) => any
): void => {
    const currentTheme = mandator?.theme ? JSON.parse(mandator.theme) : DEFAULT_THEME;
    dispatch(updateTheme(currentTheme));
};

/**
 * Converts an RGBA color value to its corresponding hexadecimal representation.
 *
 * @param {string} rgbaColor - The RGBA color value to convert.
 * @return {string} The hexadecimal representation of the RGBA color value.
 */
export const rgbaToHexa = (rgbaColor: string): string => {
    const color = new Color(rgbaColor);
    return color.hexa();
};

/**
 * Returns the opposite color of the given hex color.
 *
 * @param {string} hex - The hex color value.
 * @return {string} The opposite color, either black or white.
 */
export const getOppositeColor = (hex: string): string => {
    if (hex?.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? staticColors.black : staticColors.white;
};

/**
 * Adds an alpha value to a hexadecimal color.
 *
 * @param {string} hexaColor - The hexadecimal color to which the alpha value will be added.
 * @param {number} opacity - The opacity value to be added.
 * @return {string} The hexadecimal color with the added alpha value.
 */
export const addAlphaToHex = (hexaColor: string, opacity: number): string => {
    const color = new Color(hexaColor);
    const currentOpacity = color.alpha();
    return color.alpha(currentOpacity - opacity).hexa();
};

/**
 * Darkens the given hexadecimal color by the specified magnitude.
 *
 * @param {string} hexaColor - The hexadecimal color to darken.
 * @param {number} magnitude - The magnitude by which to darken the color.
 * @return {string} The darkened hexadecimal color.
 */
export const darkenColor = (hexaColor: string, magnitude: number): string => {
    const color = new Color(hexaColor);
    return color.darken(magnitude).hexa();
};
