import { sortByField } from '.';
import { Field, Template, TemplateField } from '../app/pages/interfaces';

/**
 * Retrieves template data based on the provided type.
 *
 * @param {Template[]} templates - Array of templates to filter and map
 * @param {string} type - Type of template to filter by
 * @returns Sorted template data based on the template name
 */
export const getTemplateData = (templates: Template[], type: string) => {
    const rawTemplateList = templates
        .filter((template) => template.type === type)
        .map((templateItem) => ({
            id: templateItem.id,
            name: templateItem.name,
        }));
    return sortByField(rawTemplateList, 'name');
};

/**
 * Returns the translated term for a given template field or field in the specified language.
 *
 * @param {TemplateField | Field} item - The template field or field to get the translated term for.
 * @param {string} language - The language to get the translated term in.
 * @return {string | undefined} The translated term if it exists and is not empty, otherwise undefined.
 */
export const getTranslatedTerm = (
    item: TemplateField | Field,
    language: string
): string | undefined => {
    const translatedTerm =
        item.custom_translation && JSON.parse(item.custom_translation ?? '')[language];
    return /\S/.test(translatedTerm) ? translatedTerm : undefined;
};
