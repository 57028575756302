import { Dispatch, SetStateAction, useEffect } from 'react';
import { localForageEmitter } from '../../helpers';
import { GridNames } from '../../constants';

/**
 * A custom React hook that listens for data changes in localForage and updates the grid name state accordingly.
 *
 * @param {Dispatch<SetStateAction<`${GridNames}` | undefined>>} setGridName - A state update function to set the grid name.
 * @param {`${GridNames}`} gridName - The name of the grid to be updated.
 * @return {void}
 */
export const useDataChangedLocalForage = (
    setGridName: Dispatch<SetStateAction<`${GridNames}` | undefined>>,
    gridName: `${GridNames}`
) => {
    useEffect(() => {
        const handleDataChange = () => {
            setGridName(gridName);
        };
        localForageEmitter.on('dataChanged', handleDataChange);

        return () => {
            // Cleanup event listener on component unmount
            localForageEmitter.off('dataChanged', handleDataChange);
        };
    }, [gridName, setGridName]);
};
