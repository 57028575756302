import { Dispatch, SetStateAction } from 'react';
import i18n from '../i18n';
import { NavigateFunction } from 'react-router-dom';

import { displayModal } from '../app/common/modal/modalDuck';
import { logout } from '../app/auth/authDuck';

import { MODAL_TYPES, Operations, SUCCESSFUL_ACTION, ToasterType } from '../constants';
import { clearLSOnComponentLeave, closeModal, navigateAfterFulfilledAction, showToaster } from '.';
import { SelectedEntities } from '../app/pages/interfaces';

interface DeleteEntity {
    component: string;
    deleteEntity: any;
    entityId: string;
    dispatch: (action: any) => any;

    entity?: string;
    isDeleteAllowed?: boolean;
    loggedUserId?: string;
    message?: string;
    parent?: string;
    selectedMandatorId?: string;
    navigate?: NavigateFunction;
    getData?: () => void | Promise<void>;
}

/**
 * Deletes an entity and performs necessary actions based on the result.
 *
 * @param {any} deleteEntity - The function to delete the entity.
 * @param {string | string[]} entityId - The ID of the entity to delete.
 * @param {(action: any) => any} dispatch - The dispatch function to dispatch actions.
 * @param {NavigateFunction} [navigate] - The function to navigate to a new page.
 * @param {() => void} [getData] - The function to get data after deletion.
 * @param {string} [loggedUserId] - The ID of the logged-in user.
 * @param {string} [selectedMandatorId] - The ID of the selected mandator.
 * @param {Dispatch<SetStateAction<SelectedEntities[]>>} [resetSelectedIdsVariable] - The function to reset selected IDs.
 * @return {Promise<void>} A promise that resolves when the deletion is complete.
 */
export const onDeleteEntity = async (
    deleteEntity: any,
    entityId: string | string[],
    dispatch: (action: any) => any,
    navigate?: NavigateFunction,
    getData?: () => void,
    loggedUserId?: string,
    selectedMandatorId?: string,
    resetSelectedIdsVariable?: Dispatch<SetStateAction<SelectedEntities[]>>
): Promise<void> => {
    closeModal();
    const res = await dispatch(deleteEntity(entityId));
    if (deleteEntity.fulfilled.match(res)) {
        if (loggedUserId === entityId || selectedMandatorId === entityId) {
            await dispatch(logout());
            return;
        } else {
            if (getData) {
                showToaster(ToasterType.Success, SUCCESSFUL_ACTION, Operations.Deleted);
                // get data
                getData();
                resetSelectedIdsVariable?.([]);
            } else navigateAfterFulfilledAction(navigate as NavigateFunction, Operations.Deleted);
        }
    }
    clearLSOnComponentLeave();
};

/**
 * Function that displays a confirmation modal before performing a delete action.
 * If the deletion is allowed, it calls onDeleteEntity to perform the deletion.
 * If the deletion is not allowed, it only closes the modal.
 *
 * @param {DeleteEntity} obj - An object containing the component name, the delete action thunk, the navigate function,
 * the dispatch function, the function to get the data, the entity name, the parent entity name, the logged user ID,
 * the selected mandator ID, the ID of the entity to delete, a custom message for the confirmation modal and a flag
 * to indicate if the deletion is allowed or not.
 */
export const handleDeleteEntity = ({
    component,
    deleteEntity,
    entity,
    entityId,
    isDeleteAllowed = true,
    loggedUserId,
    message = 'general.messages.deleteConfirmation',
    parent,
    selectedMandatorId,
    dispatch,
    getData,
    navigate,
}: DeleteEntity) => {
    const modalTitle = i18n.t(`general.labels.modalHeaders.${entity ?? component}`);
    dispatch(
        displayModal({
            showModal: true,
            title: modalTitle,
            type: MODAL_TYPES.ConfirmationMessage,
            message,
            component,
            parent,
            onLeave: () => {
                isDeleteAllowed
                    ? onDeleteEntity(
                          deleteEntity,
                          entityId,
                          dispatch,
                          navigate,
                          getData,
                          loggedUserId,
                          selectedMandatorId
                      )
                    : closeModal();
            },
            ...(!isDeleteAllowed
                ? {
                      confirmCustomLabel: i18n.t('general.labels.ok'),
                      isCancelButtonHidden: true,
                  }
                : {}),
        })
    );
};
