import { Dispatch, SetStateAction, useEffect } from 'react';
import { localForageEmitter } from '../../helpers';
import { GridNames } from '../../constants';

/**
 * A custom React hook that listens for removed data in localForage and updates the grid name state accordingly.
 *
 * @param {Dispatch<SetStateAction<`${GridNames}` | undefined>>} setGridName - A state update function to set the grid name.
 * @return {void}
 */
export const useRemoveLocalForage = (
    setGridName: Dispatch<SetStateAction<`${GridNames}` | undefined>>
) => {
    useEffect(() => {
        const handleDataChange = () => {
            setGridName(undefined);
        };
        localForageEmitter.on('dataRemoved', handleDataChange);

        return () => {
            // Cleanup event listener on component unmount
            localForageEmitter.off('dataRemoved', handleDataChange);
        };
    }, [setGridName]);
};
