import { FocusEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikErrors, FormikValues, FormikTouched } from 'formik';
import ReactCountryFlag from 'react-country-flag';

import { InfoRounded } from '@mui/icons-material';
import {
    Box,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    SxProps,
    Tooltip,
    Typography,
} from '@mui/material';

import { useAppSelector } from '../../hooks';

import { checkRequiredField } from '../../../helpers';
import { REQUIRED_FIELD_CLASS_NAME } from '../../../constants';

import TooltipButton from '../buttons/TooltipButton';
interface SelectProps {
    data: Record<string, any>[];
    titleEntry: string;
    valueEntry: string;
    onChange: (e: SelectChangeEvent<unknown>) => void;

    className?: string;
    countryValue?: string;
    defaultValue?: string | null;
    disabled?: boolean;
    errors?: FormikErrors<FormikValues>;
    formChild?: boolean;
    hideErrors?: boolean;
    infoLabel?: string;
    label?: string;
    name?: string;
    placeholder?: string;
    sx?: SxProps;
    tooltip?: string;
    touched?: FormikTouched<FormikValues>;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    renderValue?: (value: string | null) => ReactNode;
}

/**
 * Renders a select component with optional country flag, tooltip, label, error handling, and placeholder.
 *
 * @param {SelectProps} props - The properties for the select component.
 * @param {Record<string, any>[]} props.data - The data for the select.
 * @param {string} [props.name] - The name of the select.
 * @param {(e: SelectChangeEvent<unknown>) => void} props.onChange - The change event handler.
 * @param {(e: FocusEvent<HTMLInputElement>) => void} [props.onBlur] - The blur event handler.
 * @param {string} props.valueEntry - The value entry for the select.
 * @param {string} props.titleEntry - The title entry for the select.
 * @param {string | null} [props.defaultValue] - The default value for the select.
 * @param {string} [props.tooltip] - The tooltip for the select.
 * @param {string} [props.countryValue] - The country value for the select.
 * @param {boolean} [props.formChild] - Flag indicating if the select is a child of a form.
 * @param {string} [props.placeholder] - The placeholder for the select.
 * @param {boolean} [props.disabled] - Flag indicating if the select is disabled.
 * @param {(value: string | null) => ReactNode} [props.renderValue] - The render value function for the select.
 * @param {string} [props.label] - The label for the select.
 * @param {FormikErrors<FormikValues>} [props.errors] - The errors for the select.
 * @param {string} [props.className] - The CSS class name for the select.
 * @param {boolean} [props.hideErrors] - Flag indicating if errors should be hidden.
 * @param {FormikTouched<FormikValues>} [props.touched] - The touched state for the select.
 * @param {SxProps} [props.sx] - The custom CSS styling for the select.
 * @param {string} [props.infoLabel] - The information label for the select.
 * @return {JSX.Element} The rendered select component.
 */
const SelectComponent = ({
    className,
    countryValue,
    data,
    defaultValue,
    disabled,
    errors,
    formChild,
    hideErrors,
    infoLabel,
    label,
    name,
    placeholder,
    sx,
    titleEntry,
    tooltip,
    touched,
    valueEntry,
    onBlur,
    onChange,
    renderValue,
}: SelectProps): JSX.Element => {
    const { t } = useTranslation();
    const { validationSchema } = useAppSelector((state) => state.formData);

    /**
     * Calculates the default value based on the provided data, value entry, and default value.
     *
     * @return {string} The default value for the select component.
     */
    const getDefaultValue = () => {
        if (defaultValue) return defaultValue;
        return data[0] ? data[0][valueEntry] : '';
    };

    const fieldName = name ?? '';
    const applyError = !!(touched?.[fieldName] && errors?.[fieldName]);
    const errorMessage = applyError ? errors?.[fieldName] : '';

    const fieldClassName =
        validationSchema && checkRequiredField(validationSchema, fieldName)
            ? REQUIRED_FIELD_CLASS_NAME
            : '';

    return (
        <Tooltip placement="bottom" title={tooltip || ''}>
            {!formChild ? (
                <FormControl variant="standard" className={className}>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        {countryValue && (
                            <ReactCountryFlag
                                countryCode={countryValue || ''}
                                svg
                                style={{
                                    width: '1em',
                                    height: '1em',
                                }}
                            />
                        )}
                        <Select
                            sx={{ mr: 1.6 }}
                            labelId="demo-simple-select-standard-label"
                            value={getDefaultValue()}
                            className={className}
                            defaultValue={defaultValue}
                            onChange={onChange}
                            onBlur={onBlur}
                            renderValue={renderValue}>
                            {data.map((item) => {
                                return (
                                    <MenuItem key={item[valueEntry]} value={item[valueEntry]}>
                                        {item[titleEntry]}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Box>
                </FormControl>
            ) : (
                <FormControl className={className} variant="outlined" size="small" sx={sx}>
                    {label && (
                        <Typography variant="h3" className={fieldClassName}>
                            {label}
                            {infoLabel && (
                                <TooltipButton
                                    sx={{ py: 0, px: 1 }}
                                    role="info-button"
                                    title={infoLabel}
                                    icon={<InfoRounded />}
                                />
                            )}
                        </Typography>
                    )}

                    <Select
                        value={defaultValue || ''}
                        onChange={onChange}
                        name={fieldName}
                        onBlur={onBlur}
                        displayEmpty
                        placeholder={placeholder}
                        disabled={disabled}
                        role={fieldName}
                        error={applyError}>
                        {placeholder && (
                            <MenuItem key="-1" value="">
                                <em>{placeholder}</em>
                            </MenuItem>
                        )}
                        {data.map((item) => {
                            return (
                                <MenuItem
                                    key={item[valueEntry]}
                                    value={item[valueEntry]}
                                    sx={{
                                        gap: '2px',
                                    }}>
                                    {item.icon && <img src={item.icon} alt="Menu item icon" />}
                                    <Tooltip title={t(item[titleEntry])} disableInteractive>
                                        <div
                                            style={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}>
                                            {t(item[titleEntry])}
                                        </div>
                                    </Tooltip>
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {errors && !hideErrors && (
                        <Typography
                            color="#d63232"
                            variant="caption"
                            className="errorHeight"
                            role={`error-${fieldName}`}>
                            <>{errorMessage}</>
                        </Typography>
                    )}
                </FormControl>
            )}
        </Tooltip>
    );
};
export default SelectComponent;
