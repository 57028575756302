import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import {
    GetRequestProps,
    TwintPointsReqProps,
    TwintSitesStateTypes,
    UpdateTwintSiteProps,
} from '../interfaces';
import { buildQueryParams } from '../../../helpers';
import { INITIAL_DATA_STATE, URLS } from '../../../constants';
import { Interceptor } from '../../common';

const initialState: TwintSitesStateTypes = {
    twintSite: null,
    twintSites: INITIAL_DATA_STATE,
};

export const getSites = createAsyncThunk(
    'twint/sites/list',
    async ({ data_state }: GetRequestProps, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.TwintSites}`, {
                params: { ...queryParams },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const getSitePoints = createAsyncThunk(
    'twint/site/points',
    async ({ site_id, data_state }: TwintPointsReqProps, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.TwintSites}/${site_id}/points`, {
                params: { ...queryParams },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for getting twint site details by id
export const getTwintSiteById = createAsyncThunk(
    'twint/site/get',
    async (site_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.TwintSites}/${site_id}`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for enroll site in twint system
export const enrollSiteInTwint = createAsyncThunk(
    'twint/site/enroll',
    async ({ merchant_id, site_id }: UpdateTwintSiteProps, { rejectWithValue }) => {
        try {
            return await Interceptor().patch(`${URLS.TwintSites}/${site_id}`, { merchant_id });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

const twintSlice = createSlice({
    name: 'twint',
    initialState,
    reducers: {
        resetTwintSite: (state) => {
            state.twintSite = initialState.twintSite;
        },
        resetTwintSites: (state) => {
            state.twintSites = initialState.twintSites;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSites.fulfilled, (state, { payload }) => {
            state.twintSites.data = [...payload.data];
            state.twintSites.total = payload.meta.total;
        });
        builder.addCase(getTwintSiteById.fulfilled, (state, { payload }) => {
            state.twintSite = payload.data[0];
        });
    },
});

export const { resetTwintSite, resetTwintSites } = twintSlice.actions;
export default twintSlice.reducer;
