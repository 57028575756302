import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { URLS, INITIAL_DATA_STATE } from '../../../constants';
import { Interceptor } from '../../common';
import {
    GetRequestProps,
    Mandator,
    MandatorsStateTypes,
    UpdateMandatorModel,
    UpdateSelectedMandatorsModel,
} from '../interfaces';
import { buildQueryParams } from '../../../helpers';

const initialState: MandatorsStateTypes = {
    mandator: null, // get mandator by id - mandator details
    mandators: [], // all mandators from the system
    selectedMandator: null, // selected mandator from app header
    userMandators: INITIAL_DATA_STATE, // mandators that are assigned to logged user - paginated list
    userMandatorsList: [], // mandators that are assigned to logged user - full list
};

// create a thunk for getting paginated mandators by logged user
export const getMandators = createAsyncThunk(
    'mandators/list/paginated',
    async ({ data_state }: GetRequestProps, { rejectWithValue }) => {
        try {
            // build pagination, sorting and filtering params
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.Mandators}`, {
                params: { ...queryParams },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for getting all mandators by logged user
export const getMandatorsList = createAsyncThunk(
    'mandators/list/unpaginated',
    async (_, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Mandators}`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for get mandators of the system
export const getAllMandators = createAsyncThunk(
    'mandators/list/all',
    async (_, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Mandators}`, {
                params: { all_mandators: true },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);
// create a thunk for updating mandator list
export const updateMandators = createAsyncThunk(
    'mandators/list/update',
    async (updateSelectedMandatorsModel: UpdateSelectedMandatorsModel, { rejectWithValue }) => {
        try {
            // axios request
            return await Interceptor().put(URLS.Mandators, { ...updateSelectedMandatorsModel });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for add mandator
export const addMandator = createAsyncThunk(
    'mandators/mandator/add',
    async (mandator: Mandator, { rejectWithValue }) => {
        try {
            return await Interceptor().post(`${URLS.Mandators}`, { ...mandator });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk for getting mandator details
export const getMandatorById = createAsyncThunk(
    'mandators/mandator/get',
    async (mandatorId: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Mandators}/${mandatorId}`);
            return response.data.data[0];
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk for updating a mandator
export const updateMandator = createAsyncThunk(
    'mandators/mandator/update',
    async ({ mandator, mandatorId }: UpdateMandatorModel, { rejectWithValue }) => {
        try {
            return await Interceptor().put(`${URLS.Mandators}/${mandatorId}`, { ...mandator });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk for delete a mandator
export const deleteMandator = createAsyncThunk(
    'mandators/mandator/delete',
    async (mandatorId: string, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.Mandators}/${mandatorId}`);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// reducers
const mandatorsSlice = createSlice({
    name: 'mandators',
    initialState,
    reducers: {
        updateItems: (state, action) => {
            state.userMandators.data = [...action.payload];
        },
        resetMandator: (state) => {
            state.mandator = initialState.mandator;
        },

        updateSelectedMandator: (state, { payload }) => {
            state.selectedMandator = payload;
            state.mandator = null; //reset mandator after selected mandator has been updated
        },
        resetAllMandators: (state) => {
            state.mandators = initialState.mandators;
        },
        resetUserMandators: (state) => {
            state.userMandators = initialState.userMandators;
        },
    },
    extraReducers: (builder) => {
        // get user mandators paginated list
        builder.addCase(getMandators.fulfilled, (state, { payload }) => {
            state.userMandators.data = [...payload.data];
            state.userMandators.total = payload.meta.total;
        });
        // get user mandators full list
        builder.addCase(getMandatorsList.fulfilled, (state, { payload }) => {
            state.userMandatorsList = [...payload.data];
        });
        builder.addCase(getMandatorById.fulfilled, (state, { payload }) => {
            state.mandator = payload;
        });

        builder.addCase(getAllMandators.fulfilled, (state, { payload }) => {
            state.mandators = [...payload.data];
        });
    },
});
export const {
    resetAllMandators,
    resetMandator,
    resetUserMandators,
    updateItems,
    updateSelectedMandator,
} = mandatorsSlice.actions;
export default mandatorsSlice.reducer;
