// point status icons
import outOfOrderStatus from '../assets/images/statuses/1.5x/out_of_order.png';
import inUseStatus from '../assets/images/statuses/1.5x/in_use.png';
import occupiedStatus from '../assets/images/statuses/1.5x/occupied.png';
import availableStatus from '../assets/images/statuses/1.5x/available.png';
import reservedStatus from '../assets/images/statuses/1.5x/reserved.png';
import unknownStatus from '../assets/images/statuses/1.5x/unknown.png';
import { ChartDataProps } from '../app/common/interfaces';

// dashboard layout breakpoints - these are different from theme breakpoints because layout plugin has other layout scale
export const LAYOUT_BREAKPOINTS = {
    xxs: 0,
    xs: 390,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
};

// available chart types
export enum ChartTypes {
    TotalAmount = 'totalAmount',
    DailyTransactions = 'dailyTransactions',
    Transactions = 'transactions',
    PaymentMethods = 'paymentMethods',
    SitesRanking = 'sitesRanking',
    SitesLocations = 'sitesLocations',
    TransactionsGrid = 'transactionsGrid',
    PointsStatus = 'pointsStatus',
    TotalQuantity = 'totalQuantity',
    EdentifyUnitsCount = 'edentifyUnitsCount',
    VehicleTagsCount = 'vehicleTagsCount',
    EdentifyUnitsGrid = 'edentifyUnitsGrid',
    VehicleTagsGrid = 'vehicleTagsGrid',
}

/**
 * These colors should be the same as the ones defined for kendo charts
 * $kendo-series-a: #8bb31d;
 * $kendo-series-b: #0093d3;
 * $kendo-series-c: #ef7d00;
 * $kendo-series-d: #db062b;
 * $kendo-series-e: #fec700;
 * $kendo-series-f: #878787;
 */
export enum ChartColors {
    Green = '#8bb31d',
    Blue = '#0093d3',
    Orange = '#ef7d00',
    Red = '#db062b',
    Yellow = '#fec700',
    Gray = '#878787',
}
export enum DashboardMarkerStatuses {
    Available = 'available',
    InUse = 'in_use',
    OutOfOrder = 'out_of_order',
    Occupied = 'occupied',
    Unknown = 'unknown',
}
export const POINT_ICON_STATUSES = new Map<string, string>([
    ['available', availableStatus],
    ['in_use', inUseStatus],
    ['occupied', occupiedStatus],
    ['out_of_order', outOfOrderStatus],
    ['unknown', unknownStatus],
    ['reserved', reservedStatus],
]);

export const DEFAULT_CHART_DATA: ChartDataProps = { value: 0, growth: 0 };

export const PAYMENT_METHODS_TYPES = [
    { name: 'charts.methodTypes.payment_medium_ranking', value: 'payment_medium_ranking' },
    { name: 'charts.methodTypes.payment_type_ranking', value: 'payment_type_ranking' },
    {
        name: 'charts.methodTypes.payment_function_ranking',
        value: 'payment_function_ranking',
    },
    { name: 'charts.methodTypes.payment_scheme_ranking', value: 'payment_scheme_ranking' },
    {
        name: 'charts.methodTypes.payment_entry_mode_ranking',
        value: 'payment_entry_mode_ranking',
    },
];

export const SITES_RANKING_FILTERS = [
    { name: 'transactions.amount', value: 'sites_amount_ranking' },
    { name: 'services.manageTransactions.transactions', value: 'sites_transactions_ranking' },
];
