import { ComponentType } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { IGridColumn } from '../../common/interfaces';

import {
    COLUMN_VISIBLE,
    FREQUENT_LANGUAGES,
    LANGUAGE_CODES,
    MIN_COLUMN_WIDTH,
} from '../../../constants';

/**
 * Returns an array of column definitions for the Translations grid.
 *
 * @param {{ customCells: Map<string, ComponentType<GridCellProps>> }} props
 * @property {Map<string, ComponentType<GridCellProps>>} customCells - A map of custom cell components.
 * @param {string} [lastColumnName] - The name of the last column.
 *
 * @returns {IGridColumn[]} An array of column definitions.
 */
export const getTranslationColumns = (
    customCells: Map<string, ComponentType<GridCellProps>>,
    lastColumnName?: string
): IGridColumn[] => {
    const columns: IGridColumn[] = [
        {
            field: 'systemLanguage',
            title: 'digitalReceipts.systemLanguage',
            editable: false,
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable: false,
        },
    ];

    LANGUAGE_CODES.forEach((lang) => {
        columns.push({
            field: lang,
            title: lang.toUpperCase(),
            minWidth: MIN_COLUMN_WIDTH,
            show: FREQUENT_LANGUAGES.includes(lang) ? COLUMN_VISIBLE : !COLUMN_VISIBLE,
            sortable: false,
            cell:
                lang === lastColumnName
                    ? customCells.get(lastColumnName)
                    : customCells.get('language'),
        });
    });

    return columns;
};
