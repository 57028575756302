import { SetStateAction } from 'react';
import i18n from '../i18n';

import { displayModal } from '../app/common/modal/modalDuck';
import { store } from '../app/store';

import { MODAL_TYPES, Operations, SUCCESSFUL_SELECTED_ACTION, ToasterType } from '../constants';
import { ControlRadioButton } from '../app/common/interfaces';
import { closeModal } from './handleModalVisibility';
import { showToaster } from './formActions';

interface customBulkDelete {
    component: string;
    dispatch: (action: any) => any;
    onDeleteItems: any;
    message?: string;
    customCheckboxLabel?: string;
}

/**
 * Updates the selected entities with the specified field values.
 *
 * @param {string} entities - The entities to update.
 * @param {string} fieldName - The name of the field.
 * @param {(fields: any) => Promise<void>} onSubmit - The function to handle form submission.
 * @param {string} [labelName='general.labels.status'] - The label name for the update.
 * @param {ControlRadioButton[]} [customStatusControls] - Custom status controls for the update.
 * @param {string | boolean} [customInitialValue] - Custom initial value for the field.
 */
export const updateSelectedEntities = (
    entities: string,
    fieldName: string,
    onSubmit: (fields: any) => Promise<void>,
    labelName = 'general.labels.status',
    customStatusControls?: ControlRadioButton[],
    customInitialValue?: string | boolean
) => {
    const statusControls: ControlRadioButton[] = [
        {
            label: i18n.t('general.labels.activeAll'),
            value: true,
        },
        {
            label: i18n.t('general.labels.inactiveAll'),
            value: false,
        },
    ];
    const formInitialValues = { [fieldName]: customInitialValue ?? true };
    store.dispatch(
        displayModal({
            showModal: true,
            title: i18n.t(`general.messages.bulkTooltips.update.${entities}`),

            type: MODAL_TYPES.UpdateSelectedItems,
            labelName,
            fieldName,
            formInitialValues,
            statusControls: customStatusControls ?? statusControls,
            onSubmit,
        })
    );
};

/**
 * Handles the custom bulk delete action by displaying a modal with a checkbox confirmation.
 *
 * @param {customBulkDelete} options - The options for the custom bulk delete action.
 * @param {string} options.component - The name of the component.
 * @param {Dispatch} options.dispatch - The Redux dispatch function.
 * @param {string} [options.customCheckboxLabel] - The label for the checkbox.
 * @param {Function} options.onDeleteItems - The function to call when the delete items action is confirmed.
 * @param {string} [options.message='general.messages.deleteConfirmation'] - The message to display in the modal.
 */
export const handleCustomBulkDelete = ({
    component,
    dispatch,
    customCheckboxLabel,
    onDeleteItems,
    message = 'general.messages.deleteConfirmation',
}: customBulkDelete) => {
    const modalTitle = i18n.t(`general.labels.modalHeaders.${component}`);
    dispatch(
        displayModal({
            showModal: true,
            title: modalTitle,
            type: MODAL_TYPES.CheckboxConfirmationModal,
            message,
            component,
            customCheckboxLabel,
            onLeave: onDeleteItems,
        })
    );
};

/**
 * This method is called after a successful bulk operation, such as deleting multiple items
 * It calls the closeModal() function to close the modal
 * It displays a success toaster message
 * It resets the selected entities IDs variable to an empty array
 * It calls the getData() function to fetch and update the data displayed in the app
 */
export const onSuccessfulBulkAction = (
    getData: () => void,
    setSelectedIDs: (value: SetStateAction<string[]>) => void
) => {
    closeModal();
    showToaster(ToasterType.Success, SUCCESSFUL_SELECTED_ACTION, Operations.Deleted);
    setSelectedIDs([]);
    getData();
};
