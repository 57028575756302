import { useState, useCallback, createRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Typography,
} from '@mui/material';
import { Lock, Visibility, VisibilityOff } from '@mui/icons-material';

// redux
import { useAppDispatch, useAppSelector, useTranslateFormErrors } from '../hooks';
import { changePassword, login } from './authDuck';

// common components, interfaces, constants and helpers
import {
    getCognitoPasswordValidations,
    PasswordValidationError,
    sanitizeFields,
    validatePassword,
    validatePasswordOnChangedLanguage,
} from '../../helpers';
import { AuthHeader, PasswordValidations, ErrorComponent } from '.';
import './auth.scss';
interface ChangePasswordFormValues {
    verificationCode: string;
    password: string;
    retypedPassword: string;
    showPassword: boolean;
    showConfirmPassword: boolean;
}
/**
 * Renders a form for changing the password.
 *
 * @return {JSX.Element} The rendered form component.
 */
const ChangePassword = (): JSX.Element => {
    const { t } = useTranslation();
    const { error } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const formRef = createRef<FormikProps<ChangePasswordFormValues>>();

    const initialValues: ChangePasswordFormValues = {
        verificationCode: '',
        password: '',
        retypedPassword: '',
        showPassword: false,
        showConfirmPassword: false,
    };

    const validationSchema = useCallback(
        () =>
            Yup.object().shape({
                verificationCode: Yup.string()
                    .matches(/^\d*$/, {
                        message: t('general.validations.codeNumberField'),
                    })
                    .required(t('general.validations.required.code'))
                    .min(
                        5,
                        t('general.validations.codeMinLength', {
                            val: 5,
                        })
                    ),
                password: getCognitoPasswordValidations(8, 20, 'password', true),
                retypedPassword: Yup.string()
                    .oneOf([Yup.ref('password'), null], t('password.match'))
                    .required(t('general.validations.required.newPassword')),
            }),
        [t]
    );
    const [passwordValidations, setPasswordValidations] = useState<PasswordValidationError[]>([]);

    const validate = async (values: ChangePasswordFormValues) => {
        const password = values.password;

        let passValidations: PasswordValidationError[] = passwordValidations;

        if (localStorage.languageChanged) {
            passValidations = await validatePasswordOnChangedLanguage(validationSchema);
        }

        validatePassword({
            validationSchema,
            password,
            passwordValidations: passValidations,
            setPasswordValidations,
        });
    };

    const handleResetPassword = async (fields: ChangePasswordFormValues) => {
        fields = sanitizeFields(fields);
        try {
            const res = await dispatch(
                changePassword({
                    username: localStorage.email || '',
                    newPassword: fields.password,
                    confirmationCode: fields.verificationCode,
                })
            );
            if (changePassword.fulfilled.match(res)) {
                dispatch(login({ username: localStorage.email || '', password: fields.password }));
                navigate('/');
            }
        } catch (err) {
            console.log(err);
        }
    };
    useTranslateFormErrors(formRef);

    return (
        <>
            <AuthHeader />
            <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                className="reset-password-page">
                <Grid item xs={8} sm={7} md={5} lg={4} xl={3}>
                    <Box className="reset-password-content" sx={{ p: 5 }}>
                        <Formik
                            innerRef={formRef}
                            initialValues={initialValues}
                            onSubmit={(fields) => {
                                handleResetPassword(fields);
                            }}
                            validationSchema={validationSchema}
                            validate={(values) => validate(values)}
                            validateOnMount>
                            {({ values, errors, touched, handleChange, handleBlur, setValues }) => (
                                <Form className="reset-password-form">
                                    <Typography variant="h1" className="white-text" sx={{ mb: 3 }}>
                                        {t('authentication.resetPassword.title')}
                                    </Typography>
                                    {error && <ErrorComponent error={error} />}
                                    <FormControl variant="standard">
                                        <InputLabel
                                            htmlFor="verificationCode"
                                            className="form-input">
                                            {t('general.labels.code')}
                                        </InputLabel>
                                        <Input
                                            id="verificationCode"
                                            name="verificationCode"
                                            type="text"
                                            className="form-input"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{ role: 'code' }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        className="form-input"
                                                        sx={{ padding: 0 }}>
                                                        <Lock className="form-input" />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <FormLabel className="form-error" role="error-code">
                                            {errors.verificationCode && touched.verificationCode
                                                ? errors.verificationCode
                                                : ''}
                                        </FormLabel>
                                    </FormControl>

                                    <FormControl variant="standard">
                                        <InputLabel htmlFor="password" className="form-input">
                                            {t('password.newPassword')}
                                        </InputLabel>
                                        <Input
                                            type={values.showPassword ? 'text' : 'password'}
                                            name="password"
                                            id="password"
                                            className="form-input"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            inputProps={{ role: 'password' }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        className="form-input"
                                                        sx={{ padding: 0 }}
                                                        onClick={() =>
                                                            setValues({
                                                                ...values,
                                                                showPassword: !values.showPassword,
                                                            })
                                                        }>
                                                        {values.showPassword ? (
                                                            <Visibility className="form-input" />
                                                        ) : (
                                                            <VisibilityOff className="form-input" />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                    <FormControl variant="standard">
                                        <InputLabel
                                            htmlFor="retypedPassword"
                                            className="form-input">
                                            {t('password.confirmNewPassword')}
                                        </InputLabel>
                                        <Input
                                            type={values.showConfirmPassword ? 'text' : 'password'}
                                            name="retypedPassword"
                                            id="retypedPassword"
                                            className="form-input"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            inputProps={{ role: 'retypedPassword' }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        className="form-input"
                                                        sx={{ padding: 0 }}
                                                        onClick={() =>
                                                            setValues({
                                                                ...values,
                                                                showConfirmPassword:
                                                                    !values.showConfirmPassword,
                                                            })
                                                        }>
                                                        {values.showConfirmPassword ? (
                                                            <Visibility className="form-input" />
                                                        ) : (
                                                            <VisibilityOff className="form-input" />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <FormLabel
                                            className="form-error"
                                            role="error-retypedPassword">
                                            {errors.retypedPassword && touched.retypedPassword
                                                ? errors.retypedPassword
                                                : ''}
                                        </FormLabel>
                                    </FormControl>

                                    {passwordValidations && (
                                        <PasswordValidations errors={passwordValidations} />
                                    )}
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                        className="form-submit-button">
                                        {t('authentication.resetPassword.setNewPassword')}
                                    </Button>
                                    <Button
                                        href="/"
                                        className="mb-3 mx-auto forgot-pass-text white-text">
                                        {t('authentication.backToLogin')}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default ChangePassword;
