import i18n from '../i18n';
import { HistoryResources } from '../app/pages/interfaces';
import { convertNumberToString, htmlDecode, htmlEncode } from './convertors';
import { isEmptyObject } from './dataStructureHandlers';
import { convertDateToFormat } from './dateHandlers';

/**
 * Used to get rid of unnecessary mandator customization history data
 * @param data an object representing the mandator history
 * @returns simplified mandator customization data
 */
export const getCustomizationHistory = (data: any) => {
    if (data.theme) {
        const themeValues = JSON.parse(data.theme);
        return {
            ...data,
            theme: {
                ...themeValues,
                colours: {
                    primary: themeValues.colours.primary,
                    secondary: themeValues.colours.secondary,
                },
            },
        };
    }
    return data;
};

/**
 * @param objectValue an object representing the history value
 * @returns the object with the translation property attached
 */
export const changeValuesForObject = (objectValue: object) => {
    let newValue = objectValue;
    if (Object.hasOwn(newValue, 'translation')) {
        const parsedValue = JSON.parse((newValue as any).translation);
        newValue = {
            ...newValue,
            translation: [parsedValue],
        };
    }
    return newValue;
};

/**
 * Formats the history value to be displayed
 * @param value data value to be formatted
 * @param momentDateTimeFormat date format
 * @param isoLanguage lnguage code
 * @param valueType type of the value
 * @returns formatted history value
 */
export const formatHistoricalValue = (
    value: string,
    momentDateTimeFormat: string,
    isoLanguage: string,
    valueType?: string
) => {
    let newValue;
    switch (valueType) {
        case 'number':
            newValue =
                value &&
                convertNumberToString(value, isoLanguage, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 6,
                });
            break;
        case 'date':
            newValue = value && convertDateToFormat(value, momentDateTimeFormat);
            break;
        default:
            newValue = value;
    }
    if (newValue) {
        return typeof newValue === 'string' ? htmlDecode(htmlEncode(newValue)) : newValue;
    } else {
        return typeof value === 'string' ? htmlDecode(htmlEncode(value)) : value;
    }
};

/**
 * Used to format the user mandator-role link data
 * @param data an object representing the user history
 * @returns formatted user history with mandator role links as arrays of strings
 */
export const formatUserHistoryData = (data: any) => {
    const mandators_and_roles: any[] = [];
    const newData = { ...data };
    if (data.mandator_role_link && data.mandator_role_link.length > 0) {
        data.mandator_role_link.forEach((roleLink: { roles: any; mandators: any }) => {
            const mandators = roleLink.mandators.map((mandator: any) => mandator.mandator_name);
            const roles = roleLink.roles.map((role: any) => role.role_name);
            mandators_and_roles?.push({
                mandators: mandators.join(', '),
                roles: roles.join(', '),
            });
        });
    }
    delete newData.preferred_mandator;
    if (mandators_and_roles.length > 0) newData.mandator_role_link = mandators_and_roles;
    return newData;
};

/**
 * Used to format the history data according to the resource name
 * @param data an object representing the history data
 * @returns formatted history data according to the resource name
 */
export const formatData = (data: any, resourceName: string) => {
    switch (resourceName) {
        case HistoryResources.Mandator:
            return getCustomizationHistory(data);
        case HistoryResources.User:
            return formatUserHistoryData(data);
        default:
            return data;
    }
};

/**
 * Used to format the user mandator-role link data
 * @param data an object representing the user history
 * @returns formatted user history with mandator role links as arrays of strings
 */
export const getReportHistory = (data: any) => {
    const newData: { name: string; order: number }[] = [];
    if (!isEmptyObject(data) && data?.template_fields?.length > 0) {
        data?.template_fields?.forEach((field: any) => {
            newData.push({
                ...field,
                name: i18n.t(`reports.fields.${field.name}`),
            });
        });
        return { ...data, template_fields: newData };
    }
    return data;
};
