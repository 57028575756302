import axios from 'axios';

/**
 * Asynchronously geocodes a given Nominatim URL using the Axios library.
 *
 * @param {string} nominatimURL - The Nominatim URL to geocode.
 * @return {Promise<any>} A Promise that resolves to the geocoded data, or rejects with an error.
 */
export const geocode = async (nominatimURL: string): Promise<any> => {
    try {
        return await axios.get(nominatimURL).then((content) => content.data);
    } catch (err) {
        console.log(err);
    }
};
