import { DataResult } from '@progress/kendo-data-query';
import { CustomDataState } from '../../common/interfaces';

export interface HistoryStateTypes {
    histories: DataResult;
}

export enum HistoryResources {
    Device = 'device',
    EmailTemplate = 'email_template',
    FieldTranslation = 'mandator_field_translation',
    Mandator = 'mandator',
    Point = 'point',
    Price = 'price',
    Product = 'product',
    Site = 'site',
    User = 'user',
    ReportTemplate = 'template',
}
export interface HistoryGetReqProps {
    data_state: CustomDataState;
    resource_id: string;
    resource_name: `${HistoryResources}`;
    url: string;
}
export interface History {
    after_value: object;
    before_value: object;
    change_time: string;
    user: string;
}
