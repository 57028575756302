import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { Interceptor } from '../../common';

import { ModulesStateTypes, ModuleStatusReqProps, UpdateModuleFieldsReq } from '../interfaces';

import { URLS } from '../../../constants';

const initialState: ModulesStateTypes = {
    modules: [],
    module: null,
    activeModules: [],
};

export const getModules = createAsyncThunk(
    'marketplace/modules',
    async (_, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.MarketplaceModules}`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const getModuleById = createAsyncThunk(
    'marketplace/module',
    async (module_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.MarketplaceModules}/${module_id}`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const changeModuleStatus = createAsyncThunk(
    'marketplace/module/activation',
    async ({ moduleId, active }: ModuleStatusReqProps, { rejectWithValue }) => {
        try {
            const response = await Interceptor().post(
                `${URLS.MarketplaceModules}/${moduleId}/activations`,
                { active }
            );
            return response;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// get all module fields
export const getModuleFields = createAsyncThunk(
    'marketplace/module/fields',
    async (moduleUrl: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${moduleUrl}`);
            return response;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// updated the fields for a module
export const updateModuleFields = createAsyncThunk(
    'marketplace/module/fields/create',
    async ({ moduleUrl, moduleFields }: UpdateModuleFieldsReq, { rejectWithValue }) => {
        try {
            const response = await Interceptor().patch(`${moduleUrl}`, moduleFields);
            return response;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);
const modulesSlice = createSlice({
    name: 'marketplace',
    initialState,
    reducers: {
        resetModule: (state) => {
            state.module = initialState.module;
        },
        updateActiveModules: (state, { payload }) => {
            state.activeModules = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getModules.fulfilled, (state, { payload }) => {
            state.modules = [...payload];
        });
        builder.addCase(getModuleById.fulfilled, (state, { payload }) => {
            state.module = payload;
        });
    },
});

export const { updateActiveModules } = modulesSlice.actions;
export default modulesSlice.reducer;
