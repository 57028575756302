import { createSlice } from '@reduxjs/toolkit';
import i18n from '../../i18n';
import { addCountryCodeToLanguage, getFormats } from '../../helpers';
import { FormatsTypes } from './interfaces';

interface CommonDataTypes {
    formats: FormatsTypes;
    ISOLanguageCode: string;
    language: string;
}

const initialState: CommonDataTypes = {
    formats: getFormats(i18n.language),
    ISOLanguageCode: addCountryCodeToLanguage(i18n.language),
    language: i18n.language,
};

// reducers
const commonDataSlice = createSlice({
    name: 'commonData',
    initialState,
    reducers: {
        updateLanguage: (state, { payload }) => {
            state.language = payload;
        },
        updateFormats: (state, { payload }) => {
            state.formats = payload;
        },
        updateISOLanguageCode: (state, { payload }) => {
            state.ISOLanguageCode = payload;
        },
    },
});

export const { updateLanguage, updateFormats, updateISOLanguageCode } = commonDataSlice.actions;
export default commonDataSlice.reducer;
