import { Route, Routes } from 'react-router';
import { nanoid } from '@reduxjs/toolkit';
// public pages
import { Login, ResetPassword, ForgotPassword, ChangePassword, Eula } from '../auth';
import NotFound from '../../NotFound';

// private pages
import PrivateRoute from '../routing/PrivateRoute';
import DefaultLayout from '../common/layout/DefaultLayout';

import { AppPrivateRoutes } from './AppPrivateRoutes';

/**
 * Component that renders the main routes of the application.
 *
 * It renders the public routes, like the login, forgot password, etc.
 * and then renders the private routes by mapping over the AppPrivateRoutes array.
 *
 * The private routes are rendered using the PrivateRoute component which
 * wraps each route in a DefaultLayout component.
 *
 * The component also renders a not found page for all other routes.
 *
 * @returns {JSX.Element} the routes component
 */
const AppRoutes = (): JSX.Element => {
    const generatePrivateRoutes = () => {
        return AppPrivateRoutes.map((route) => {
            const element = (
                <PrivateRoute>
                    <DefaultLayout
                        component={route.component}
                        payed={route.payed}
                        userRight={route.userRight}
                    />
                </PrivateRoute>
            );
            const hasChildren = route.children ? true : false;

            return (
                <Route
                    path={route.path}
                    key={nanoid()}
                    // add element attribute only for paths without children
                    element={hasChildren ? undefined : element}>
                    {/* add index route only for bested paths */}
                    {hasChildren && <Route index element={element} />}
                    {/* add children if they exist */}
                    {hasChildren &&
                        route.children?.map((child) => {
                            return (
                                <Route
                                    key={nanoid()}
                                    path={child.path}
                                    element={
                                        <PrivateRoute>
                                            <DefaultLayout component={child.component} />
                                        </PrivateRoute>
                                    }
                                />
                            );
                        })}
                </Route>
            );
        });
    };
    return (
        <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/eula" element={<Eula />} />

            {/* private routes following  */}
            {generatePrivateRoutes()}
        </Routes>
    );
};
export default AppRoutes;
