import { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import 'hammerjs';
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';
import { ToastContainer, Slide } from 'react-toastify';
import { LocalizationProvider, IntlProvider } from '@progress/kendo-react-intl';
import { IconsContext } from '@progress/kendo-react-common';
import i18n from './i18n';
// kendo internalization
import { loadKendoMessagesAndSettings } from './kendoLocalization';
// redux
import { Provider } from 'react-redux';
import { store } from './app/store';
//local forage
import localforage from 'localforage';

import CustomThemeProvider from './themes/CustomThemeProvider';
// ducks
import { loadUser } from './app/auth/authDuck';
import { updateFormats, updateLanguage, updateISOLanguageCode } from './app/common/commonDataDuck';

//helpers
import { addCountryCodeToLanguage, getFormats, clearLSOnComponentLeave } from './helpers';
import AppRoutes from './app/routing/AppRoutes';

function App() {
    const [language, setLanguage] = useState(store.getState().commonData.language);

    useEffect(() => {
        // amplify configuration
        Amplify.configure({
            Auth: {
                Cognito: {
                    userPoolId: process.env.REACT_APP_USER_POOL_ID as string,
                    userPoolClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID as string,
                },
            },
        });

        store.dispatch(loadUser());

        onPageHasUnsavedChanges();
        onAppLeave();

        loadKendoMessagesAndSettings(language);

        i18n.on('languageChanged', (lng: string) => {
            store.dispatch(updateLanguage(lng));
            store.dispatch(updateFormats(getFormats(lng)));
            store.dispatch(updateISOLanguageCode(addCountryCodeToLanguage(lng)));
            setLanguage(lng);
            loadKendoMessagesAndSettings(lng);
            localStorage.setItem('languageChanged', '1');
        });

        //local forage config
        localforage.config({
            name: 'hecone', // DB name
            storeName: 'heconeCoreStore', // Data store name
            version: 1.0, // DB version
            description: 'Local storage for HecOne web app',
        });

        return () => {
            onAllChangesSaved();
            afterAppLeave();
        };
    }, []);

    function beforeUnloadListener(event: BeforeUnloadEvent) {
        // Show prompt based on state
        if (localStorage.addOrEdit === 'true' && event) {
            event.preventDefault();
            event.returnValue = '';
            return '';
        }
    }

    // A function that invokes a callback when the page has unsaved changes.
    const onPageHasUnsavedChanges = () => {
        window.addEventListener('beforeunload', beforeUnloadListener);
    };

    // A function that invokes a callback when the page's unsaved changes are resolved.
    const onAllChangesSaved = () => {
        window.removeEventListener('beforeunload', beforeUnloadListener);
    };

    const onAppLeave = () => {
        window.addEventListener('pagehide', clearLSOnComponentLeave);
    };

    const afterAppLeave = () => {
        window.removeEventListener('pagehide', clearLSOnComponentLeave);
    };

    return (
        <Provider store={store}>
            <CustomThemeProvider>
                <LocalizationProvider language={language}>
                    <IntlProvider locale={language}>
                        <IconsContext.Provider value={{ type: 'font' }}>
                            <ToastContainer hideProgressBar transition={Slide} />
                            <Router>
                                <section className="main-page">
                                    <AppRoutes />
                                </section>
                            </Router>
                        </IconsContext.Provider>
                    </IntlProvider>
                </LocalizationProvider>
            </CustomThemeProvider>
        </Provider>
    );
}

export default App;
