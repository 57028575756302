import { Graphs } from '../app/common/interfaces';

export const calculateEdentifyGraphs = (edentifyUnits: number, vehicleTags: number) => {
    const calculatedGraphs: Graphs = {};

    calculatedGraphs.edentifyUnitsCount = generateCustomCard(
        'charts.edentify.unitsCount',
        'edentifyUnitsTotal',
        edentifyUnits
    );
    calculatedGraphs.vehicleTagsCount = generateCustomCard(
        'charts.edentify.vehicleTagsCount',
        'vehicleTagsTotal',
        vehicleTags
    );

    return calculatedGraphs;
};

const generateCustomCard = (title: string, avatar: string, statisticsData: number) => {
    return {
        kind: 'custom',
        title,
        avatar,
        data: { value: statisticsData, growth: 0 },
    };
};
