import { ComponentType } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { MIN_COLUMN_WIDTH, COLUMN_VISIBLE } from '../../../constants';
import { IGridColumn } from '../../common/interfaces';

/**
 * Returns an array of column definitions for the Users grid.
 *
 * @param {ComponentType<GridCellProps>} CustomCell - A custom cell component.
 *
 * @returns {IGridColumn[]} - An array of column definitions.
 */
export const getUsersColumns = (CustomCell: ComponentType<GridCellProps>): IGridColumn[] => {
    return [
        {
            field: 'first_name',
            title: 'users.firstName',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'last_name',
            title: 'users.lastName',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'email',
            title: 'general.labels.email',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
        {
            field: 'enabled',
            title: 'general.labels.status',
            filter: 'boolean',
            cell: CustomCell,
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
        },
    ];
};
