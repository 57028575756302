import { FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import {
    Box,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    IconButton,
    InputAdornment,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    SxProps,
    Typography,
    TypographyProps,
} from '@mui/material';
import { Cancel, Clear } from '@mui/icons-material';
import { nanoid } from '@reduxjs/toolkit';

import { useAppSelector } from '../../hooks';

import { EllipsisText } from '..';
import { MultiselectItem } from '../interfaces';
import { checkRequiredField } from '../../../helpers';
import { REQUIRED_FIELD_CLASS_NAME } from '../../../constants';

const CHIP_MAX_WIDTH = 250;
const CHIP_ICON_WIDTH = 30;
interface FormInputProps {
    data: MultiselectItem[];
    selectedValues: string[] | [];
    clearAll: () => void;
    handleChange: (event: SelectChangeEvent<string[] | [] | string>) => void;
    handleDeleteItem: (item: string) => void;

    customChipClassName?: string;
    customErrorStyle?: SxProps;
    customSelectClassName?: string;
    disabled?: boolean;
    errors?: FormikErrors<FormikValues>;
    fieldName?: string;
    isAllSelected?: boolean;
    label?: string;
    labelVariant?: TypographyProps['variant'];
    maxHeight?: string;
    parentFieldName?: string;
    placeholder?: string;
    selectHeight?: string;
    sx?: SxProps;
    touched?: FormikTouched<FormikValues>;
    handleBlur?: (e: FocusEvent<HTMLInputElement>) => void;
}

/**
 * Renders a multiple select chip component with dynamic data and interactions.
 *
 * @param {FormInputProps} props - Props for the component.
 * @param {MultiselectItem[]} props.data - Data for the multiple select chip.
 * @param {string} props.label - Label for the multiple select chip.
 * @param {TypographyProps['variant']} props.labelVariant - Variant of the label typography.
 * @param {string} props.fieldName - Name of the field.
 * @param {string[] | []} props.selectedValues - Selected values in the chip.
 * @param {FormikErrors<FormikValues>?} props.errors - Errors related to the field.
 * @param {FormikTouched<FormikValues>?} props.touched - Touched status of the field.
 * @param {(event: SelectChangeEvent<string[] | [] | string>) => void} props.handleChange - Handler for change event.
 * @param {(e: FocusEvent<HTMLInputElement>) => void} props.handleBlur - Handler for blur event.
 * @param {(item: string) => void} props.handleDeleteItem - Handler for deleting an item.
 * @param {boolean} props.isAllSelected - Flag indicating if all items are selected.
 * @param {() => void} props.clearAll - Function to clear all selected items.
 * @param {boolean} props.disabled - Flag indicating if the chip is disabled.
 * @param {string} props.maxHeight - Maximum height of the chip.
 * @param {SxProps} props.sx - Styling object for the chip.
 * @param {SxProps} props.selectHeight - Height for the Select element.
 * @param {string} props.placeholder - Placeholder text for the chip.
 * @param {string} props.parentFieldName - Parent field name.
 * @param {string} props.customSelectClassName - Custom class name for the select component.
 * @return {JSX.Element} The JSX element representing the multiple select chip.
 */
const MultipleSelectChip = ({
    customChipClassName,
    customErrorStyle,
    customSelectClassName,
    data,
    disabled,
    errors,
    fieldName,
    isAllSelected,
    label,
    labelVariant,
    maxHeight,
    parentFieldName,
    placeholder,
    selectedValues,
    selectHeight,
    sx,
    touched,
    clearAll,
    handleBlur,
    handleChange,
    handleDeleteItem,
}: FormInputProps): JSX.Element => {
    const { t } = useTranslation();
    const { validationSchema } = useAppSelector((state) => state.formData);
    const inputFieldName = fieldName || '';
    const applyError = touched?.[inputFieldName] && errors?.[inputFieldName] ? true : false;
    const errorText = applyError ? errors?.[inputFieldName] : '';

    const myDeleteIcon = disabled ? (
        <></>
    ) : (
        <Cancel onMouseDown={(event) => event.stopPropagation()} />
    );
    const isData = data.length > 0 && data.filter((item) => item.visible).length > 0;

    const fieldClassName =
        label &&
        validationSchema &&
        checkRequiredField(validationSchema, parentFieldName ?? inputFieldName)
            ? REQUIRED_FIELD_CLASS_NAME
            : '';

    const selectedCheckbox = isAllSelected ?? data.length === selectedValues.length;

    return (
        <FormControl sx={sx} size="small">
            {label && (
                <Typography variant={labelVariant} className={fieldClassName}>
                    {label}
                </Typography>
            )}

            <Select
                multiple
                value={selectedValues}
                name={fieldName}
                onChange={handleChange}
                onBlur={handleBlur}
                sx={{ borderWidth: '1px', height: selectHeight }}
                disabled={disabled}
                error={applyError}
                role={fieldName}
                className={customSelectClassName}
                inputProps={{
                    'aria-label': 'multipleChoiceInput',
                }}
                displayEmpty
                endAdornment={
                    selectedValues.length > 0 && (
                        <InputAdornment position="end" sx={{ right: '20px', position: 'absolute' }}>
                            <IconButton onClick={clearAll} sx={{ p: 0 }} disabled={disabled}>
                                <Clear />
                            </IconButton>
                        </InputAdornment>
                    )
                }
                renderValue={(selected) => {
                    if (selected.length === 0 && placeholder) {
                        return <em>{placeholder}</em>;
                    } else {
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 0.5,
                                    maxHeight: maxHeight ?? '130px',
                                    overflowY: 'auto',
                                }}>
                                {(selected as string[]).map((item: string, index: number) => (
                                    <Chip
                                        className={`${customChipClassName} chipStyle`}
                                        key={index}
                                        label={
                                            <EllipsisText
                                                variant="body2"
                                                maxWidth={CHIP_MAX_WIDTH - CHIP_ICON_WIDTH}>
                                                {t(
                                                    data.find(
                                                        ({ id }: MultiselectItem) => id === item
                                                    )?.name ?? ''
                                                )}
                                            </EllipsisText>
                                        }
                                        clickable
                                        disabled={disabled}
                                        deleteIcon={myDeleteIcon}
                                        onDelete={() => handleDeleteItem(item)}
                                    />
                                ))}
                            </Box>
                        );
                    }
                }}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            maxHeight: '250px',
                            overflowY: 'auto',
                            width: '15rem',
                        },
                    },
                }}>
                {isData && (
                    <MenuItem key={nanoid()} value="all" className="selectAllOption">
                        <ListItemIcon>
                            <Checkbox color="secondary" checked={selectedCheckbox} />
                        </ListItemIcon>
                        <ListItemText primary={t('general.labels.selectAll')} />
                    </MenuItem>
                )}
                {isData && <Divider sx={{ marginTop: '0px!important' }} />}
                {data.map(
                    ({ id, name, visible }: MultiselectItem) =>
                        visible && (
                            <MenuItem key={id} value={id} className="multipleSelectOption">
                                <ListItemIcon>
                                    <Checkbox
                                        color="secondary"
                                        checked={selectedValues.includes(id as never)}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={t(name)} />
                            </MenuItem>
                        )
                )}
            </Select>
            <Typography
                color="#d63232"
                variant="caption"
                className="errorHeight"
                sx={customErrorStyle}>
                <>{errorText}</>
            </Typography>
        </FormControl>
    );
};

export default MultipleSelectChip;
