import {
    ComponentType,
    Dispatch,
    memo,
    ReactElement,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    Grid,
    GridCellProps,
    GridColumn,
    GridDataStateChangeEvent,
    GridDetailRowProps,
    GridExpandChangeEvent,
    GridHeaderSelectionChangeEvent,
    GridItemChangeEvent,
    GridNoRecords,
    GridPagerSettings,
    GridRowClickEvent,
    GridSelectionChangeEvent,
} from '@progress/kendo-react-grid';
import { State, getter, DataResult } from '@progress/kendo-data-query';
import { Checkbox } from '@progress/kendo-react-inputs';

import { useAppSelector } from '../../hooks';
import { CustomDataState, IGridColumn, GridDataResult } from './interfaces';
import ColumnMenu from './ColumnMenu';
import { RowRender, CellRender } from './rendering';
import {
    EDIT_FIELD,
    EXPAND_COLUMN_WIDTH,
    EXPANDED_FIELD,
    GRID_PADDING,
    INITIAL_GRID_STATE,
    SELECTED_FIELD,
    GridNames,
} from '../../../constants';
import { expandItem, saveOrUpdateUserPreferences } from '../../../helpers';
import FleetnetTooltip from '../../fleetnet-router/fleetnetCommon/FleetnetTooltip';

export interface GridComponentProps<T> {
    columns: IGridColumn[];
    initialColumns: IGridColumn[];
    result: GridDataResult<T>;
    setColumns: Dispatch<SetStateAction<IGridColumn[]>>;

    allInEdit?: boolean;
    className?: string;
    customGridCell?: boolean;
    customPagination?: number[];
    defaultHeight?: string;
    defaultMaxHeight?: string;
    detailComponent?: ComponentType<GridDetailRowProps>;
    fieldName?: string;
    initialGridState?: CustomDataState;
    isFormChild?: boolean;
    isPageable?: boolean;
    noDataMessage?: JSX.Element | string;
    serverDataState?: State;
    showTooltip?: boolean;
    tooltipFieldName?: string;
    tooltipMsg?: any;
    enterCellEdit?: (dataItem: any) => void;
    handleItemChange?: (event: GridItemChangeEvent) => void;
    handleRowClick?: (event: GridRowClickEvent) => void;
    onDataChange?: (data: T[]) => void;
    onServerDataStateChange?: (event: GridDataStateChangeEvent) => void;
    updateRowData?: () => void;
    gridName?: `${GridNames}`;
}
const selectedValueGetter = getter(SELECTED_FIELD);
const DETAIL_CELL_CLASSNAME = 'k-detail-cell';

const pageable: GridPagerSettings = {
    buttonCount: 5,
    info: true,
    pageSizes: [20, 50, 100, 150],
    previousNext: true,
    responsive: false,
    type: 'numeric',
};
/**
 * A custom component that renders a custom Kendo React Grid based on the provided settings.
 */

const GridComponent = <T extends Record<never, never>>({
    allInEdit,
    className,
    columns,
    customGridCell,
    customPagination,
    defaultHeight,
    defaultMaxHeight,
    detailComponent,
    fieldName,
    initialColumns,
    initialGridState,
    isFormChild,
    isPageable,
    noDataMessage,
    result,
    serverDataState,
    showTooltip,
    tooltipFieldName,
    tooltipMsg,
    enterCellEdit,
    handleItemChange,
    handleRowClick,
    onDataChange,
    onServerDataStateChange,
    setColumns,
    updateRowData,
    gridName,
}: GridComponentProps<T>) => {
    const { t } = useTranslation();
    const { sideWidth } = useAppSelector((state) => state.layout);
    const minGridWidth = useRef<number>(0);
    const grid = useRef<HTMLElement | null>(null);
    const [gridData, setGridData] = useState(result);

    const [gridCurrent, setGridCurrent] = useState(0);
    const [applyMinWidth, setApplyMinWidth] = useState(false);
    const [isScrollVisible, setIsScrollVisible] = useState(false);
    const [dataState, setDataState] = useState<CustomDataState>(
        initialGridState ?? INITIAL_GRID_STATE
    );
    const [indeterminateClassName, setIndeterminateClassName] = useState('');

    const customSelector = useMemo(() => (className ? '.' + className : ''), [className]);
    const gridContainer = document.querySelector(customSelector + ' .k-grid-content');
    const gridNoRecords = document.querySelector(customSelector + ' .k-grid-norecords');

    useEffect(() => {
        setGridData(result);
    }, [result]);

    const data = useMemo(() => {
        return Array.isArray(gridData) ? gridData : gridData.data;
    }, [gridData]);

    const populatedColumns = useMemo(() => {
        return columns.filter((col) => Object.keys(col).length > 0);
    }, [columns]);

    const handleResize = useCallback(() => {
        const offsetWidth = grid.current?.offsetWidth;
        if (offsetWidth) {
            if (offsetWidth < minGridWidth.current && !applyMinWidth) {
                setApplyMinWidth(true);
            } else if (offsetWidth > minGridWidth.current) {
                setGridCurrent(offsetWidth);
                setApplyMinWidth(false);
            }
        }
    }, [applyMinWidth]);

    useEffect(() => {
        minGridWidth.current = 0;
        grid.current = document.querySelector(customSelector + '.k-grid');
        window.addEventListener('resize', handleResize);
        populatedColumns.forEach((col: IGridColumn) => {
            if (col.minWidth && col.show) minGridWidth.current += col.minWidth;
        });
        if (grid.current?.offsetWidth) {
            setGridCurrent(grid.current.offsetWidth);
            grid.current.offsetWidth < minGridWidth.current
                ? setApplyMinWidth(true)
                : setApplyMinWidth(false);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [sideWidth, populatedColumns, handleResize, customSelector]);

    const gridPadding = useMemo(() => {
        return document.body.clientHeight > window.innerHeight ? GRID_PADDING * 2 : GRID_PADDING;
    }, [window.innerHeight, document.body.clientHeight]);

    const setWidth = (width?: number, minWidth?: number) => {
        const columnsNumber = populatedColumns.filter((col) => col.show === true).length;
        let fixedColumns = 0;
        let fixedColumnsWidth = 0;
        populatedColumns.forEach((col: IGridColumn) => {
            if (col.width && col.show) {
                fixedColumns += 1;
                fixedColumnsWidth = fixedColumnsWidth + col.width;
            }
        });
        if (width) return width;
        if (applyMinWidth) return minWidth;
        if (isScrollVisible) {
            return detailComponent
                ? (gridCurrent - gridPadding - fixedColumnsWidth - EXPAND_COLUMN_WIDTH) /
                      (columnsNumber - fixedColumns)
                : (gridCurrent - gridPadding - fixedColumnsWidth) / (columnsNumber - fixedColumns);
        } else {
            return detailComponent
                ? (gridCurrent - fixedColumnsWidth - EXPAND_COLUMN_WIDTH) /
                      (columnsNumber - fixedColumns)
                : (gridCurrent - fixedColumnsWidth) / (columnsNumber - fixedColumns);
        }
    };

    const manageGridScrollable = () => {
        gridContainer?.scrollHeight &&
        gridContainer?.clientHeight &&
        gridContainer?.scrollHeight > gridContainer?.clientHeight
            ? setIsScrollVisible(true)
            : setIsScrollVisible(false);
    };

    const onDataStateChange = useCallback(
        (event: GridDataStateChangeEvent) => {
            setDataState(event.dataState);
            if (serverDataState) onServerDataStateChange?.(event);
            const clickedElementParent = event.target?.element?.parentElement;
            if (
                gridContainer &&
                clickedElementParent &&
                !clickedElementParent.className.includes(DETAIL_CELL_CLASSNAME)
            ) {
                gridContainer.scrollTop = 0;
                gridContainer.scrollLeft = 0;
            }
        },
        [serverDataState, onServerDataStateChange, gridContainer]
    );

    useEffect(() => {
        if (serverDataState) setDataState(serverDataState);
    }, [serverDataState]);

    const onSelectionChange = useCallback(
        (event: GridSelectionChangeEvent) => {
            if (onDataChange) {
                const checkboxElement = event.syntheticEvent.target as HTMLInputElement;
                const checked = checkboxElement.checked;
                const updatedData = data.map((dataItem) => {
                    if (dataItem === event.dataItem || dataItem.id === event.dataItem.id) {
                        return {
                            ...dataItem,
                            selected: checked,
                        };
                    } else return { ...dataItem };
                });
                onDataChange(updatedData);
            }
        },
        [data, onDataChange]
    );

    const onHeaderSelectionChange = useCallback(
        (event: GridHeaderSelectionChangeEvent) => {
            if (onDataChange) {
                const checkboxElement = event.syntheticEvent.target as HTMLInputElement;
                const checked = checkboxElement.checked;
                const updatedData = data.map((item) => {
                    return { ...item, selected: checked };
                });
                onDataChange(updatedData);
            }
        },
        [data, onDataChange]
    );

    const saveColumns = useCallback(
        (newColumns: IGridColumn[]) => {
            if (gridName) {
                const visibleColumns = newColumns
                    .filter((col) => col.show)
                    .map((col) => col.field as string);
                saveOrUpdateUserPreferences({ key: gridName, columns: visibleColumns });
            }
        },
        [gridName]
    );

    const onChangeColumns = useCallback(
        (newColumns: IGridColumn[]) => {
            setColumns(newColumns);
            saveColumns(newColumns);
        },
        [saveColumns, setColumns]
    );
    const onColumnsReset = useCallback(() => {
        setColumns(initialColumns);
        saveColumns(initialColumns);
    }, [initialColumns, setColumns, saveColumns]);

    const pager = useCallback(() => {
        const customPager = customPagination
            ? { ...pageable, pageSizes: customPagination }
            : pageable;
        return isPageable === false ? false : customPager;
    }, [customPagination, isPageable]);

    const onExpandChange = useCallback(
        (event: GridExpandChangeEvent) => {
            const newObject = JSON.parse(JSON.stringify(event.dataItem));
            newObject.expanded = event.value;
            const newData = expandItem(newObject, gridData as DataResult, fieldName);
            setGridData(newData);
        },
        [gridData, fieldName]
    );

    const setMaxHeight = useCallback(() => {
        const top = grid.current?.getBoundingClientRect().top ?? 0;
        const maxHeight = window.innerHeight - top - 24;
        return isPageable !== false && !isFormChild
            ? {
                  maxHeight: maxHeight,
              }
            : undefined;
    }, [isFormChild, isPageable]);

    const setGridClassName = useCallback(() => {
        const hasNoRecords =
            gridData && Array.isArray(gridData) ? gridData.length > 0 : gridData.data?.length > 0;
        let gridClassName = `${className} ${indeterminateClassName}`;
        if (!isScrollVisible) {
            gridClassName = `${gridClassName} no-scrollable`;
        }
        if (gridNoRecords && hasNoRecords) {
            gridClassName = `${gridClassName} no-records`;
        }
        if (handleRowClick) {
            gridClassName = `${gridClassName} row-selection`;
        } else {
            gridClassName = `${gridClassName} row-selection-revert`;
        }
        return gridClassName;
    }, [className, gridNoRecords, indeterminateClassName, isScrollVisible]);

    const generateStyle = useCallback(() => {
        const maxHeight = defaultMaxHeight ? { maxHeight: defaultMaxHeight } : setMaxHeight();
        const defaultNoScrollableHeight =
            !isScrollVisible && defaultHeight ? { height: defaultHeight } : {};
        return { ...maxHeight, ...defaultNoScrollableHeight };
    }, [defaultHeight, defaultMaxHeight, isScrollVisible, setMaxHeight]);

    const rowRender = (row: ReactElement<HTMLTableRowElement>) => {
        return <RowRender tr={row} updateRowData={updateRowData} />;
    };

    const cellRender = (
        td: React.ReactElement<HTMLTableCellElement> | null,
        props: GridCellProps
    ) => {
        return <CellRender originalProps={props} td={td} enterEdit={enterCellEdit} />;
    };

    const itemHasSelectField = useCallback((item: Record<string, unknown>) => {
        return Object.prototype.hasOwnProperty.call(item, SELECTED_FIELD);
    }, []);

    const headerCheckSelector = useCallback(() => {
        let atLeastOne = false;
        let allSelected = true;
        const dataArray = (gridData as DataResult).data ?? gridData;

        dataArray.forEach((item) => {
            if (itemHasSelectField(item)) {
                if (selectedValueGetter(item)) {
                    atLeastOne = true;
                } else {
                    allSelected = false;
                }
            } else {
                allSelected = false;
            }
        });

        const newIndeterminateClassName = atLeastOne && !allSelected ? 'indeterminate' : '';
        indeterminateClassName !== newIndeterminateClassName &&
            setIndeterminateClassName(newIndeterminateClassName);
        return allSelected;
    }, [indeterminateClassName, itemHasSelectField, gridData]);

    const getAllInEditData = useCallback(() => {
        const extractedData = Array.isArray(gridData) ? gridData : gridData.data;
        return extractedData.map((item) => ({ ...item, [EDIT_FIELD]: true }));
    }, [gridData]);

    const tooltipCustomCell = useCallback(() => {
        return (
            <FleetnetTooltip
                tooltipMsg={tooltipMsg}
                tooltipFieldName={tooltipFieldName}
                titleCaseEnabled={true}
            />
        );
    }, [tooltipFieldName, tooltipMsg]);

    const checkboxCustomCell = useCallback(() => {
        return (
            <td className="custom-grid-header-column">
                <Checkbox disabled={true} checked={headerCheckSelector()} />
            </td>
        );
    }, [headerCheckSelector]);

    const getGridHeaderCell = useCallback(
        (column: IGridColumn) => {
            let headerCell = undefined;
            if (showTooltip && column?.field === tooltipFieldName) {
                headerCell = tooltipCustomCell;
            }
            if (column.field === SELECTED_FIELD && customGridCell) {
                headerCell = checkboxCustomCell;
            }
            return headerCell;
        },
        [checkboxCustomCell, customGridCell, showTooltip, tooltipCustomCell, tooltipFieldName]
    );

    const lockedIndexes = useMemo(() => {
        const isSelectedColAvailable =
            populatedColumns.filter((col) => col.field === SELECTED_FIELD)?.length > 0;
        const lastColumnIndex = populatedColumns.length - 1;
        return isSelectedColAvailable ? [0, 1, lastColumnIndex] : [0, lastColumnIndex];
    }, [populatedColumns]);

    return (
        <Grid
            ref={manageGridScrollable}
            style={generateStyle()}
            className={setGridClassName()}
            resizable={true}
            pageable={pager()}
            data={!allInEdit ? gridData : getAllInEditData()}
            {...dataState}
            sortable={true}
            selectedField={SELECTED_FIELD}
            onDataStateChange={onDataStateChange}
            onSelectionChange={onSelectionChange}
            onHeaderSelectionChange={onHeaderSelectionChange}
            detail={detailComponent}
            expandField={EXPANDED_FIELD}
            onExpandChange={onExpandChange}
            editField={EDIT_FIELD}
            onRowClick={handleRowClick}
            onItemChange={handleItemChange}
            cellRender={enterCellEdit ? cellRender : undefined}
            rowRender={updateRowData ? rowRender : undefined}>
            <GridNoRecords>{noDataMessage}</GridNoRecords>
            {populatedColumns?.map((column, index) => {
                const sortableColumn = column.sortable === true || column.sortable === undefined;
                const isLockedColumn = lockedIndexes.includes(index);
                return (
                    column.show && (
                        <GridColumn
                            headerCell={getGridHeaderCell(column)}
                            headerSelectionValue={
                                column.field === SELECTED_FIELD ? headerCheckSelector() : undefined
                            }
                            resizable={column.field !== SELECTED_FIELD}
                            key={index}
                            field={column.field}
                            title={column.title ? t(column.title) : ''}
                            cell={column.cell}
                            sortable={!!sortableColumn}
                            editable={column.editable}
                            locked={isLockedColumn}
                            columnMenu={
                                column.title && !column.isHiddenColumnMenu
                                    ? (props) => (
                                          <ColumnMenu
                                              {...props}
                                              columns={populatedColumns}
                                              onColumnsSubmit={onChangeColumns}
                                              onColumnsReset={onColumnsReset}
                                          />
                                      )
                                    : undefined
                            }
                            width={setWidth(column.width, column.minWidth)}
                        />
                    )
                );
            })}
        </Grid>
    );
};
export default memo(GridComponent);
