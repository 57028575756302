import { AxiosError } from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { buildQueryParams } from '../../../helpers';
import { TransactionsStateTypes, GetFileRequestProps, GetTransactionsProps } from '../interfaces';
import { Interceptor } from '../../common';
import { URLS, INITIAL_DATA_STATE } from '../../../constants';

const initialState: TransactionsStateTypes = {
    transaction: null,
    transactions: INITIAL_DATA_STATE,
};

// create a thunk for get transactions
export const getTransactions = createAsyncThunk(
    'transactions/list',
    async (
        { data_state, mandator_id, start_date, end_date }: GetTransactionsProps,
        { rejectWithValue }
    ) => {
        try {
            // build pagination, sorting and filtering params
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.Transactions}`, {
                params: {
                    mandator_id,
                    start_date,
                    end_date,
                    ...queryParams,
                },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for get transaction infos
export const getTransactionById = createAsyncThunk(
    'transactions/transaction/get',
    async (transactionId: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Transactions}/${transactionId}`, {
                params: {
                    additional_info: true,
                },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const getTransactionsReportLink = createAsyncThunk(
    'transactions/export',
    async (
        {
            data_state,
            format,
            mandator_id,
            emails,
            start_date,
            end_date,
            template_id,
        }: GetFileRequestProps,
        { rejectWithValue }
    ) => {
        try {
            delete data_state?.skip;
            delete data_state?.take;
            if (data_state) {
                const { sort, filter } = data_state;
                const queryParams = buildQueryParams({ sort, filter });
                const response = await Interceptor().get(`${URLS.TransactionsReport}`, {
                    params: {
                        format,
                        mandator_id,
                        emails: JSON.stringify(emails),
                        template_id,
                        start_date,
                        end_date,
                        ...queryParams,
                    },
                });

                return response.data;
            }
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// reducers
const transactionsSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        updateSelectedItems: (state, action) => {
            state.transactions.data = [...action.payload];
        },
        resetTransactions: (state) => {
            state.transactions = initialState.transactions;
        },
        resetTransaction: (state) => {
            state.transaction = initialState.transaction;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTransactions.fulfilled, (state, { payload }) => {
            state.transactions.data = payload.data ?? [];
            state.transactions.total = payload.meta.total;
        });
        builder.addCase(getTransactionById.fulfilled, (state, { payload }) => {
            state.transaction = payload.data;
        });
    },
});
export const { updateSelectedItems, resetTransaction, resetTransactions } =
    transactionsSlice.actions;
export default transactionsSlice.reducer;
