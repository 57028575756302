import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { INITIAL_DATA_STATE, URLS } from '../../../constants';
import { buildQueryParams } from '../../../helpers';
import { Interceptor } from '../../common';
import {
    GetRequestProps,
    RolesStateTypes,
    UpdateRoleModel,
    UserPermissionFormValues,
} from '../interfaces';

const initialState: RolesStateTypes = {
    allRoles: [],
    role: null,
    roles: INITIAL_DATA_STATE,
};

// create thunk for getting the user roles
export const getRoles = createAsyncThunk(
    'roles/list/paginated',
    async ({ data_state }: GetRequestProps, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.Roles}`, {
                params: {
                    ...queryParams,
                },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk for getting the all roles
export const getAllRoles = createAsyncThunk(
    'roles/list/unpaginated',
    async (_, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Roles}`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk for adding a role
export const addRole = createAsyncThunk(
    'roles/role/add',
    async (role: UserPermissionFormValues, { rejectWithValue }) => {
        try {
            return await Interceptor().post(`${URLS.Rights}`, { ...role });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);
// create thunk for getting a role by id
export const getRoleById = createAsyncThunk(
    'roles/role/get',
    async (roleId: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Roles}/${roleId}`);
            return response.data.data[0];
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);
//create thunk for updating a role
export const updateRole = createAsyncThunk(
    'roles/role/update',
    async ({ role, roleId }: UpdateRoleModel, { rejectWithValue }) => {
        try {
            return await Interceptor().put(`${URLS.Rights}/${roleId}`, { ...role });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for deleting a role
export const deleteRole = createAsyncThunk(
    'roles/role/delete',
    async (roleId: string, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.Roles}/${roleId}`);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);
// reducers
const rolesSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        resetRole: (state) => {
            state.role = initialState.role;
        },
        resetRoles: (state) => {
            state.roles = initialState.roles;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRoles.fulfilled, (state, { payload }) => {
            state.roles.data = payload.data;
            state.roles.total = payload.meta.total;
        });
        builder.addCase(getAllRoles.fulfilled, (state, { payload }) => {
            state.allRoles = payload.data;
        });
        builder.addCase(getRoleById.fulfilled, (state, { payload }) => {
            state.role = payload;
        });
    },
});

// Action creators are generated for each case reducer function
export const { resetRole, resetRoles } = rolesSlice.actions;
export default rolesSlice.reducer;
