/**
 * Returns an HTMLCollection of elements with the specified tag name from the provided HTML string.
 *
 * @param {string} html - The HTML string to search for elements.
 * @param {string} htmlTag - The tag name of the elements to retrieve.
 * @return {HTMLCollection} - The collection of elements with the specified tag name.
 */
export const getHtmlElementByTagName = (html: string, htmlTag: string): HTMLCollection => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.getElementsByTagName(htmlTag);
};

/**
 * Converts an HTML string into plain text by removing HTML tags and replacing line breaks.
 *
 * @param {string} html - The HTML string to convert.
 * @return {string | null} The plain text representation of the HTML string.
 */
export const convertHTMLToPlainText = (html: string): string | null => {
    //keep html brakes
    html = html.replace(/<\/p>/g, '\n');
    html = html.replace(/<\/div>/g, '\n');
    html = html.replace(/<\/h>/g, '\n');
    html = html.replace(/<br>/g, '\n');
    html = html.replace(/<br( )*\/>/g, '\n');

    //parse html into text
    const dom = new DOMParser().parseFromString('<!doctype html><body>' + html, 'text/html');
    return dom.body.textContent;
};
