import { Box, Grid, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { Module } from '../../interfaces';
import module_default_image from '../../../../assets/images/module_default_image.png';

interface ModuleDetailsProps {
    module: Module;
}

/**
 * General details tab component for Module Details page
 *
 * @param {ModuleDetailsProps} props - component props
 * @param {Module} props.module - module object
 * @returns {JSX.Element} - component JSX
 */
const GeneralDetailsTab = ({ module }: ModuleDetailsProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Grid container spacing={1} mt={2} ml={7} height="fit-content">
            <Grid item xs={10} lg={6} xl={4} height="fit-content">
                <img
                    alt={t('marketplace.moduleImage')}
                    width="90%"
                    src={
                        module?._links?.banner.href
                            ? module?._links?.banner.href
                            : module_default_image
                    }
                />
            </Grid>
            <Grid item xs={10} lg={6} xl={7}>
                <Box>
                    <Typography variant="h3">{module.summary}</Typography>
                    <Typography variant="body1">{module.description}</Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

export default GeneralDetailsTab;
