import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Box, Chip, SelectChangeEvent } from '@mui/material';

import { EllipsisText, MultipleSelectChip } from '../..';
import { MultiselectItem } from '../../interfaces';

interface MultipleSelectCellProps extends GridCellProps {
    multiselectData: MultiselectItem[];
    selectedValues: string[];
    clearAllOptions: () => void;
    handleChangeOptions: (event: SelectChangeEvent<string | string[] | []>) => void;
    handleDeleteOption: (item: string) => void;

    component?: JSX.Element;
}

const MultipleSelectCell = (props: MultipleSelectCellProps) => {
    const {
        className,
        component,
        dataItem,
        multiselectData,
        selectedValues,
        clearAllOptions,
        handleChangeOptions,
        handleDeleteOption,
        render,
    } = props;
    const { t } = useTranslation();

    const selectedValuesToDisplay = useMemo(() => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5,
                }}>
                {selectedValues.map((item: string, index: number) => (
                    <Chip
                        className="chipStyle multipleSelectCell"
                        key={index}
                        label={
                            <EllipsisText variant="body2">
                                {t(
                                    multiselectData.find(({ id }: MultiselectItem) => id === item)
                                        ?.name ?? ''
                                )}
                            </EllipsisText>
                        }
                        clickable
                        disabled={true}
                    />
                ))}
            </Box>
        );
    }, [multiselectData, selectedValues, t]);

    const defaultRendering = (
        <td className={className}>
            {dataItem.inEdit ? (
                <MultipleSelectChip
                    data={multiselectData}
                    selectedValues={selectedValues}
                    handleChange={handleChangeOptions}
                    handleDeleteItem={handleDeleteOption}
                    clearAll={clearAllOptions}
                    sx={{ width: '100%' }}
                    customSelectClassName="multiple-select-cell"
                    customChipClassName="custom-select-cell"
                    selectHeight="40px"
                    customErrorStyle={{ height: '0px', marginBottom: '0px' }}
                />
            ) : (
                <>
                    {selectedValuesToDisplay}
                    {component}
                </>
            )}
        </td>
    );

    return render ? render(defaultRendering, props) : defaultRendering;
};

export default MultipleSelectCell;
