import { TFunction } from 'i18next';
import i18n from '../i18n';

/**
 * Translates a term using the provided translation key and raw term.
 *
 * @param {string} translationKey - The translation key used to retrieve the translation.
 * @param {string} rawTerm - The raw term to be translated.
 * @param {TFunction} [translate] - An optional translation function to use instead of the default i18n function.
 * @return {string} The translated term if it exists in the i18n resources, otherwise the raw term.
 */
export const translateTerm = (
    translationKey: string,
    rawTerm: string,
    translate?: TFunction
): string => {
    const translatedValue = translate ? translate(translationKey) : translationKey;
    return i18n.exists(translationKey) ? translatedValue : rawTerm;
};
