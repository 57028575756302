import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Interceptor, TooltipButton } from '..';
import { getFileName, showToaster } from '../../../helpers';
import { useAppSelector, useDownloadFile } from '../../hooks';
import { ToasterType } from '../../../constants';
import { DownloadButtonState, FileExtension } from '../../pages/interfaces';

interface DownloadButtonProps {
    disabled: boolean;
    getData: () => Promise<any>;

    mandatorName?: string;
}
/**
 * Function that renders a download button component.
 *
 * @param {DownloadButtonProps} props - The component props containing:
 * @param {boolean} props.disabled - Flag indicating if the button is disabled.
 * @param {() => Promise<any>} props.getData - Function to fetch data for download.
 * @param {string} props.mandatorName - Optional name of the mandator.
 * @return {JSX.Element} The download button component.
 */
const DownloadButton = ({ disabled, getData, mandatorName }: DownloadButtonProps): JSX.Element => {
    const { t } = useTranslation();
    const [buttonState, setButtonState] = useState<DownloadButtonState>(
        DownloadButtonState.Primary
    );
    const { formats } = useAppSelector((state) => state.commonData);

    const preDownloading = () => setButtonState(DownloadButtonState.Load);

    const postDownloading = () => setButtonState(DownloadButtonState.Primary);

    const onErrorDownloadFile = () => {
        setButtonState(DownloadButtonState.Primary);
        showToaster(ToasterType.Error, t('general.messages.downloadFailed'));
    };

    const getFile = useCallback(async () => {
        // get file url
        const response = await getData();

        // get file content
        return Interceptor().get(response, { responseType: 'blob' });
    }, [getData]);

    const { download } = useDownloadFile({
        apiDefinition: getFile,
        preDownloading,
        postDownloading,
        onError: onErrorDownloadFile,
        fileName: getFileName(formats.momentDateTimeFormat, FileExtension.CSV, mandatorName),
    });
    return (
        <TooltipButton
            title={t('transactions.downloadTransactions')}
            buttonVariant="contained"
            icon={<FileDownloadIcon />}
            onClick={download}
            disabled={disabled || buttonState === DownloadButtonState.Load}
        />
    );
};

export default DownloadButton;
