import { ReactNode, useCallback } from 'react';
import { FormikErrors, FormikTouched, FormikValues, getIn } from 'formik';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { OutlinedInput, FormControl, Typography, TextareaAutosize, SxProps } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';

import { useAppSelector } from '../../hooks';

import { ChangeEventParameter, BlurEventParameter, InputType, InputValue } from '../interfaces';
import { AutoCompleteChip, CheckList, TooltipButton } from '..';

import { REQUIRED_FIELD_CLASS_NAME, ZERO_LIMIT } from '../../../constants';
import { checkRequiredField } from '../../../helpers';
import { Fields } from '../../pages/interfaces';

interface FormInputProps {
    fieldName: string;
    type: InputType;

    autoComplete?: string;
    autoCompleteOptions?: string[];
    autoCompleteValues?: string[];
    checkListData?: Fields[];
    className?: string;
    countryCode?: string;
    disabled?: boolean;
    endAdornment?: ReactNode;
    errors?: FormikErrors<FormikValues>;
    errorSx?: SxProps;
    infoButtonLabel?: string;
    label?: string;
    maxLimit?: number;
    minLimit?: number;
    numberFormat?: string;
    placeholder?: string;
    readOnly?: boolean;
    requiredField?: boolean;
    step?: number;
    sx?: SxProps;
    touched?: FormikTouched<FormikValues>;
    value?: InputValue;
    handleChange?: (e: ChangeEventParameter) => void;
    handleBlur?: (e: BlurEventParameter) => void;
    handlePhoneChange?: (formattedValue: string) => void;
    onChangeAutocompleteChipValue?: (value: string[]) => void;
}

/**
 * Renders a form input element based on the provided props.
 *
 * @param {FormInputProps} props - The props object containing the following properties:
 *   - label (optional): The label for the input element.
 *   - fieldName: The name of the input element.
 *   - value: The value of the input element.
 *   - type: The type of the input element.
 *   - handleChange: The function to handle the change event of the input element.
 *   - handleBlur: The function to handle the blur event of the input element.
 *   - errors: The errors object from Formik.
 *   - touched: The touched object from Formik.
 *   - handlePhoneChange: The function to handle the phone change event of the input element (only for phone type).
 *   - disabled: Whether the input element is disabled.
 *   - className: The custom class name for the input element.
 *   - minLimit: The minimum limit for the input element (only for number type).
 *   - maxLimit: The maximum limit for the input element (only for number type).
 *   - countryCode: The country code for the input element (only for phone type).
 *   - endAdornment: The end adornment for the input element.
 *   - autoComplete: The autocomplete attribute for the input element.
 *   - sx: The styling object for the FormControl component.
 *   - errorSx: The styling object for the error message.
 *   - placeholder: The placeholder text for the input element.
 *   - readOnly: Whether the input element is read-only.
 *   - numberFormat: The number format for the input element (only for number type).
 *   - step: The step value for the input element (only for number type).
 *   - infoButtonLabel: The label for the info button (optional).
 *   - requiredField: Whether the input field is required.
 *   - autoCompleteOptions: Data for autocomplete input;
 *   - onChangeAutocompleteChipValue: The function to handle the change event of the autocomplete field;
 *   - autoCompleteValues: Values for autocomplete;
 *   - checkListData: Data for checklist input;
 * @return {JSX.Element} The rendered form input element.
 */
const FormInput = ({
    autoComplete,
    autoCompleteOptions,
    autoCompleteValues,
    checkListData,
    className,
    countryCode,
    disabled,
    endAdornment,
    errors,
    errorSx,
    fieldName,
    infoButtonLabel,
    label,
    maxLimit,
    minLimit,
    numberFormat,
    placeholder,
    readOnly,
    requiredField,
    step,
    sx,
    touched,
    type,
    value,
    handleBlur,
    handleChange,
    handlePhoneChange,
    onChangeAutocompleteChipValue,
}: FormInputProps): JSX.Element => {
    const { validationSchema } = useAppSelector((state) => state.formData);
    const inputFieldName = fieldName || '';
    const applyError =
        getIn(touched, inputFieldName) && getIn(errors, inputFieldName) ? true : false;

    const errorText: string | string[] = applyError ? getIn(errors, inputFieldName) : '';
    const customErrorText =
        Array.isArray(errorText) && type === 'autoCompleteChip'
            ? errorText.filter((error) => error !== undefined)[0]
            : errorText;

    const setNumberInputClassName = useCallback(() => {
        let numberInputClassName = '';
        if (applyError) {
            numberInputClassName = 'k-invalid';
        }
        if ((minLimit as number) >= ZERO_LIMIT) {
            numberInputClassName = `${numberInputClassName} no-negative-values`;
        }
        return numberInputClassName;
    }, [applyError, minLimit]);

    //restrict typing - in number inputs with min >= 0
    const positiveNumberInputs = document.querySelectorAll('.no-negative-values');
    positiveNumberInputs.forEach((input) => {
        input.addEventListener('keydown', (event: any) => {
            if (
                (event.keyCode as number) === 189 ||
                (event.keyCode as number) === 173 ||
                (event.keyCode as number) === 109
            ) {
                event.preventDefault();
            }
        });
    });

    const renderInputElement = () => {
        switch (type) {
            case 'phone':
                return (
                    <PhoneInput
                        placeholder={label}
                        value={value as string}
                        onChange={(_inputValue, _data, _event, formattedValue) => {
                            handlePhoneChange?.(formattedValue);
                        }}
                        onBlur={handleBlur}
                        enableAreaCodes={true}
                        disabled={disabled}
                        country={countryCode}
                        buttonClass={disabled ? 'disabledDropdownFlag' : ''}
                        inputClass="phoneInput"
                        containerStyle={{
                            borderColor: '#878787',
                        }}
                        inputStyle={{
                            fontSize: '14px',
                            fontWeight: '400',
                            fontFamily: 'Open Sans-regular',
                            color: '#343A40',
                            borderColor: '#878787',
                            height: '40px',
                            width: '100%',
                        }}
                        inputProps={{
                            name: fieldName,
                            role: fieldName,
                            'aria-label': fieldName,
                        }}
                    />
                );
            case 'textarea':
                return (
                    <TextareaAutosize
                        minRows={5}
                        maxRows={5}
                        className="textarea"
                        value={value || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={fieldName}
                        disabled={disabled}
                        role={fieldName}
                    />
                );
            case 'number':
                return (
                    <div role={fieldName}>
                        <NumericTextBox
                            value={value as number}
                            name={fieldName}
                            placeholder={placeholder ?? label}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            min={minLimit}
                            max={maxLimit}
                            disabled={disabled}
                            className={setNumberInputClassName()}
                            format={numberFormat}
                            step={step}
                        />
                    </div>
                );
            case 'autoCompleteChip':
                return (
                    <AutoCompleteChip
                        inputValues={autoCompleteValues ?? []}
                        autoCompleteOptions={autoCompleteOptions}
                        onChange={onChangeAutocompleteChipValue as (value: string[]) => void}
                        isDisabled={disabled}
                    />
                );
            case 'checklist':
                return (
                    <CheckList
                        fieldName="name"
                        data={checkListData as Fields[]}
                        draggable={true}
                        isFormChild={true}
                        onChange={handleChange as unknown as (list: Fields[]) => void}
                        className="form-checklist"
                        disabled={disabled}
                    />
                );
            default:
                return (
                    <OutlinedInput
                        name={fieldName}
                        value={value}
                        size="small"
                        placeholder={placeholder ?? label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={disabled}
                        error={applyError}
                        role={fieldName}
                        type={type}
                        inputProps={{
                            min: minLimit,
                            max: maxLimit,
                            'aria-label': fieldName,
                        }}
                        endAdornment={endAdornment}
                        autoComplete={autoComplete}
                        readOnly={readOnly}
                        className="custom-outline-input"
                    />
                );
        }
    };
    const customClassName = className ? className : 'formControl';

    const isFieldRequired = validationSchema && checkRequiredField(validationSchema, fieldName);
    const requiredFieldClassName =
        (requiredField ?? isFieldRequired) ? REQUIRED_FIELD_CLASS_NAME : '';
    return (
        <FormControl className={customClassName} variant="outlined" sx={sx}>
            {label && (
                <Typography variant="h3" className={requiredFieldClassName}>
                    {label}
                    {infoButtonLabel && (
                        <TooltipButton
                            sx={{ py: 0, px: 1 }}
                            role="info-button"
                            title={infoButtonLabel}
                            icon={<InfoRounded />}
                        />
                    )}
                </Typography>
            )}
            {renderInputElement()}
            {errors && !className && (
                <Typography
                    color="#d63232"
                    variant="caption"
                    className="errorHeight"
                    role={`error-${fieldName}`}
                    sx={errorSx}>
                    {customErrorText}
                </Typography>
            )}
        </FormControl>
    );
};

export default FormInput;
