import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
    DataForDelete,
    GetRequestProps,
    ProviderTemplateFormValues,
    ProviderTemplatesStateTypes,
    UpdateProviderTemplateModel,
} from '../interfaces';
import { Interceptor } from '../../common';
import { INITIAL_DATA_STATE, URLS } from '../../../constants';
import { buildQueryParams } from '../../../helpers';

const initialState: ProviderTemplatesStateTypes = {
    mandatorProviderTemplates: [],
    providerTemplate: null,
    providerTemplates: INITIAL_DATA_STATE,
};

// create a thunk for getting provider templates (paginated)
export const getProviderTemplates = createAsyncThunk(
    'providerTemplates/list',
    async ({ data_state }: GetRequestProps, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.ProviderTemplates}`, {
                params: { ...queryParams },
            });

            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk for add provider template
export const addProviderTemplate = createAsyncThunk(
    'providerTemplates/template/add',
    async (providerTemplate: ProviderTemplateFormValues, { rejectWithValue }) => {
        try {
            return await Interceptor().post(`${URLS.ProviderTemplates}`, { ...providerTemplate });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk for getting mandator provider templates
export const getMandatorProviderTemplates = createAsyncThunk(
    'providerTemplates/mandator/templates/list',
    async (mandator_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(
                `${URLS.Mandators}/${mandator_id}/providers/templates`
            );
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//create a thunk for getting provider template by id
export const getProviderTemplateById = createAsyncThunk(
    'providerTemplates/template/get',
    async (providerTemplateId: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(
                `${URLS.ProviderTemplates}/${providerTemplateId}`
            );
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for updating a provider template
export const updateProviderTemplate = createAsyncThunk(
    'providerTemplates/template/update',
    async (
        { providerTemplate, providerTemplateId }: UpdateProviderTemplateModel,
        { rejectWithValue }
    ) => {
        try {
            return await Interceptor().put(`${URLS.ProviderTemplates}/${providerTemplateId}`, {
                ...providerTemplate,
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk for deleting selected provider templates
export const deleteProviderTemplates = createAsyncThunk(
    'providerTemplates/delete/bulk',
    async (providerTemplatesForDelete: DataForDelete, { rejectWithValue }) => {
        try {
            const response = await Interceptor().patch(
                `${URLS.ProviderTemplates}`,
                providerTemplatesForDelete
            );

            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for deleting a provider template
export const deleteProviderTemplate = createAsyncThunk(
    'providerTemplates/template/delete',
    async (provider_id: string, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.ProviderTemplates}/${provider_id}`);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//reducers
const providerTemplatesSlice = createSlice({
    name: 'providerTemplates',
    initialState,
    reducers: {
        updateItems: (state, action) => {
            state.providerTemplates.data = [...action.payload];
        },
        resetProviderTemplate: (state) => {
            state.providerTemplate = initialState.providerTemplate;
        },
        resetProviderTemplates: (state) => {
            state.providerTemplates = initialState.providerTemplates;
        },
        resetMandatorProviderTemplates: (state) => {
            state.mandatorProviderTemplates = initialState.mandatorProviderTemplates;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProviderTemplates.fulfilled, (state, { payload }) => {
            state.providerTemplates.data = [...payload.data];
            state.providerTemplates.total = payload.meta.total;
        });
        builder.addCase(getMandatorProviderTemplates.fulfilled, (state, { payload }) => {
            state.mandatorProviderTemplates = [...payload.data];
        });
        builder.addCase(getProviderTemplateById.fulfilled, (state, { payload }) => {
            state.providerTemplate = payload.data[0];
        });
    },
});

export const {
    updateItems,
    resetProviderTemplate,
    resetProviderTemplates,
    resetMandatorProviderTemplates,
} = providerTemplatesSlice.actions;
export default providerTemplatesSlice.reducer;
