import { useState, useEffect } from 'react';
import { useAppSelector } from '.';
import { ModulePermissions } from '../common/interfaces';

// handles user rights upon component actions
export const useRights = (
    componentRights: Map<string, string>
): Record<string, ModulePermissions> | undefined => {
    const { userRights } = useAppSelector((state) => state.userRights);
    const [rights, setRights] = useState<Record<string, ModulePermissions>>();
    useEffect(() => {
        const result: Map<string, ModulePermissions> = new Map();
        componentRights.forEach((value, key) => {
            if (key !== 'component') {
                result.set(key, userRights.filter((el) => el.sub_component === value)[0]);
            } else {
                result.set(key, userRights.filter((el) => el[key] === value)[0]);
            }
        });
        setRights(Object.fromEntries(result));
    }, [userRights]);
    return rights;
};
