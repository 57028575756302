import { Dispatch, SetStateAction } from 'react';
import i18n from '../i18n';

import { displayModal } from '../app/common/modal/modalDuck';

import {
    BulkActionsOptions,
    MODAL_TYPES,
    Operations,
    SUCCESSFUL_SELECTED_ACTION,
    ToasterType,
} from '../constants';
import { closeModal, showToaster } from '.';
import { DataForDelete, SelectedEntities } from '../app/pages/interfaces';

interface DeleteEntities {
    component: string;
    deleteEntities: any;
    selectedEntities: any;
    dispatch: (action: any) => any;
    getData: () => void;
    isDeleteAllowed?: boolean;
    message?: string;
    setSelectedEntitiesIds?: Dispatch<SetStateAction<any>>;
}

/**
 * Deletes multiple entities asynchronously.
 *
 * @param {any[]} selectedEntities - The entities to be deleted.
 * @param {any} deleteEntities - The function to delete entities.
 * @param {(action: any) => any} dispatch - The Redux dispatch function.
 * @param {() => void} getData - The function to get data.
 * @param {Dispatch<SetStateAction<SelectedEntities[]>>} [setSelectedEntitiesIds] - The function to set selected entities IDs.
 * @return {Promise<void>} A promise that resolves when the entities are deleted.
 */
const onDeleteEntities = async (
    selectedEntities: any,
    deleteEntities: any,
    dispatch: (action: any) => any,
    getData: () => void,
    setSelectedEntitiesIds?: Dispatch<SetStateAction<SelectedEntities[]>>
): Promise<void> => {
    closeModal();
    let entitiesToDelete = selectedEntities;
    if (typeof selectedEntities[0] !== 'string') {
        entitiesToDelete = {
            ids: selectedEntities.map((selectedEntity: any) => selectedEntity.id),
            action: BulkActionsOptions.Delete,
        };
    }
    const response = await dispatch(deleteEntities(entitiesToDelete));

    if (deleteEntities.fulfilled.match(response)) {
        showToaster(ToasterType.Success, SUCCESSFUL_SELECTED_ACTION, Operations.Deleted);
        getData();
        setSelectedEntitiesIds?.([]);
    }
};

/**
 * Handles the deletion of multiple entities.
 *
 * @param {DeleteEntities} options - The options for deleting entities.
 * @param {string} options.component - The component name.
 * @param {Function} options.deleteEntities - The function to delete entities.
 * @param {Array} options.selectedEntities - The array of selected entities.
 * @param {Function} options.dispatch - The Redux dispatch function.
 * @param {Function} options.getData - The function to get data.
 * @param {string} [options.message='general.messages.deleteConfirmation'] - The confirmation message.
 * @param {boolean} [options.isDeleteAllowed=true] - Whether deletion is allowed.
 * @param {Function} [options.setSelectedEntitiesIds] - The function to set selected entities IDs.
 * @return {void}
 */
export const handleDeleteEntities = ({
    component,
    deleteEntities,
    selectedEntities,
    dispatch,
    getData,
    message = 'general.messages.deleteConfirmation',
    isDeleteAllowed = true,
    setSelectedEntitiesIds,
}: DeleteEntities): void => {
    const modalTitle = i18n.t(`general.labels.modalHeaders.${component}`);
    dispatch(
        displayModal({
            showModal: true,
            title: modalTitle,
            type: MODAL_TYPES.ConfirmationMessage,
            message,
            component,
            onLeave: () => {
                isDeleteAllowed
                    ? onDeleteEntities(
                          selectedEntities,
                          deleteEntities,
                          dispatch,
                          getData,
                          setSelectedEntitiesIds
                      )
                    : closeModal();
            },
            ...(!isDeleteAllowed
                ? {
                      confirmCustomLabel: i18n.t('general.labels.ok'),
                      isCancelButtonHidden: true,
                  }
                : {}),
        })
    );
};
