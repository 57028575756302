import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { EditorTools, EditorToolsSettings, EditorUtils } from '@progress/kendo-react-editor';
import { Button } from '@progress/kendo-react-buttons';

import { useAppDispatch } from '../../hooks';
import { displayModal } from '../modal/modalDuck';

import { MODAL_TYPES } from '../../../constants';
import { CustomUploadImageProps } from '../interfaces';

const imageSettings = EditorToolsSettings.image;

interface InsertImageProps extends EditorTools.InsertImageProps, CustomUploadImageProps {}

/**
 * Custom implementation of the InsertImage tool from KendoReact Editor.
 * It overrides the default behavior of the tool by opening a modal dialog
 * when the button is clicked. The dialog is a custom component that allows
 * uploading an image or selecting one from a list of existing images.
 *
 * @param {EditorView} props.view - The KendoReact Editor view.
 * @param {string} props.saveUrl - The URL where the image will be saved.
 * @param {ImageMetaData} props.fileMetadata - Metadata for the image file.
 * @param {string} props.uploadImageLabel - The label for the upload button.
 * @param {string} props.imagePath - The initial image path.
 * @returns {JSX.Element} A button component that opens the modal dialog when clicked.
 */
const InsertImage = ({
    fileMetadata,
    imagePath,
    saveUrl,
    uploadImageLabel,
    view,
}: InsertImageProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const state = view && view.state;
    const nodeType = state ? state.schema.nodes[imageSettings.node] : undefined;

    const openDialog = useCallback(() => {
        dispatch(
            displayModal({
                showModal: true,
                title: t('general.labels.insertImage'),
                type: MODAL_TYPES.UploadImage,
                saveUrl,
                fileMetadata,
                uploadImageLabel,
                editorView: view,
                imagePath,
            })
        );
    }, [dispatch, fileMetadata, imagePath, saveUrl, t, uploadImageLabel, view]);

    return (
        <Button
            onClick={openDialog}
            disabled={!nodeType || !state || !EditorUtils.canInsert(state, nodeType)}
            onMouseDown={(e) => e.preventDefault()}
            onPointerDown={(e) => e.preventDefault()}
            title={t('general.labels.insertImage')}
            {...imageSettings.props}
        />
    );
};
export default InsertImage;
