import localforage from 'localforage';
import { EventEmitter } from 'events';
import { SelectionRange } from '@progress/kendo-react-dateinputs';

import {
    GeneralUserPreferences,
    UserPreferencesForDashboard,
    DashboardSelectValues,
    PreservedState,
    RemoveUserPreferencesDataProps,
} from '../app/pages/interfaces';
import { GridNames, ToasterType } from '../constants';
import { showToaster } from '.';
import i18n from '../i18n';

export const localForageEmitter = new EventEmitter();

interface UserPreferencesProps {
    key: `${GridNames}`;
    state?: PreservedState;
    columns?: string[];
    dashboardSelectedCharts?: DashboardSelectValues;
    searchInputValue?: string;
    transactionStatus?: string[];
    time?: SelectionRange;
    showCharts?: boolean;
}

/**
 * Removes user preferences from localforage storage asynchronously.
 *
 * @param {string} key - The key associated with the data to be removed.
 * @param {Dispatch<SetStateAction<null>>} props.resetData - A function that is used to reset the state for user preferences
 * @returns {Promise<void>} A promise that resolves once the item is removed and the data is reset.
 *
 * @throws Will display a toaster error notification if the removal fails.
 */
export const removeUserPreferencesData = async ({
    key,
    resetData,
}: RemoveUserPreferencesDataProps): Promise<void> => {
    try {
        // Remove the item from local storage asynchronously
        await localforage.removeItem(key);
        resetData({});
        localForageEmitter.emit('dataRemoved');
    } catch (err) {
        // Handle any errors that occurred during removal
        showToaster(
            ToasterType.Error,
            i18n.t('general.messages.localForageRemoveAction', {
                item: key,
            }),
            '',
            true
        );
    }
};

/**
 * General method that asynchronously retrieves user preferences data from local forage based on the provided key.
 * @param {`${GridNames}`} key - The key associated with the user preferences to be fetched.
 *
 * @returns {Promise<GeneralUserPreferences | UserPreferencesForDashboard | null>} A promise that resolves to the user preferences object.
 *          The object can be of type `GeneralUserPreferences`, `UserPreferencesForDashboard`, or `null` if no preferences are found or an error occurs.
 *
 * @throws Will show a toaster notification if there is an error during retrieval.
 */
export const getUserPreferences = async (
    key: `${GridNames}`
): Promise<GeneralUserPreferences | UserPreferencesForDashboard | null> => {
    try {
        // Fetch the item from local storage asynchronously
        const value = await localforage.getItem(key);
        return value as GeneralUserPreferences | UserPreferencesForDashboard | null;
    } catch (err) {
        // Handle any errors that occurred during retrieval
        showToaster(ToasterType.Error, (err as string) ?? '', '', true);
        return null;
    }
};

/**
 * General function that stores user preferences data in localforage based on a key
 * @param {`${GridNames}`} key - The key associated with the data to be stored
 * @param {PreservedState} state - The grid’s state to be saved
 * @param {string[]} columns -  The columns to be saved
 * @param {DashboardSelectValues} dashboardSelectedCharts - The Dashboard selected charts
 * @param {string} searchInputValue - The search input by which the user filters the grid data
 * @param {string[]} transactionStatus - The list of selected transaction statuses
 * @param {SelectionRange} time - Start and end time of the selected time range
 * @param {boolean} showCharts - Indicates if the charts should be shown
 * @returns {Promise<void>} A promise that resolves to localForage data updated
 */

export const saveOrUpdateUserPreferences = async ({
    key,
    state,
    columns,
    dashboardSelectedCharts,
    searchInputValue,
    transactionStatus,
    time,
    showCharts,
}: UserPreferencesProps): Promise<void> => {
    const objectToSave: GeneralUserPreferences | UserPreferencesForDashboard = {
        columns,
        state,
        ...(searchInputValue && { searchInputValue }),
        ...(dashboardSelectedCharts && { dashboardSelectedCharts }),
        ...(transactionStatus && { transactionStatus }),
        ...(time && { time }),
        showCharts,
    };
    try {
        const item = await localforage.getItem(key);
        if (!item) {
            await localforage.setItem(key, objectToSave);
        } else {
            const updatedItem = updateStorageItem(item, objectToSave);
            await localforage.setItem(key, updatedItem);
        }
        localForageEmitter.emit('dataChanged');
    } catch (err) {
        console.error('Error updating or adding item:', err);
        return;
    }
};

/**
 * Function that recursively updates an item nested values by the new provided ones
 * @param {GeneralUserPreferences | UserPreferencesForDashboard} item - The item containing values to be updated
 * @param {GeneralUserPreferences | UserPreferencesForDashboard} newItem -  The item containing new values
 * @returns {GeneralUserPreferences | UserPreferencesForDashboard} The updated item
 */
const updateStorageItem = (
    item: GeneralUserPreferences | UserPreferencesForDashboard,
    newItem: GeneralUserPreferences | UserPreferencesForDashboard
): GeneralUserPreferences | UserPreferencesForDashboard => {
    for (const key in newItem) {
        const itemKey = key as keyof (GeneralUserPreferences | UserPreferencesForDashboard);

        if (newItem[itemKey] !== undefined) {
            if (
                typeof newItem[itemKey] === 'object' &&
                !(newItem[itemKey] instanceof Date) &&
                newItem[itemKey] !== null &&
                !Array.isArray(newItem[itemKey])
            ) {
                if (!item[itemKey]) {
                    item[itemKey] = {} as any;
                }
                updateStorageItem(
                    item[itemKey] as GeneralUserPreferences | UserPreferencesForDashboard,
                    newItem[itemKey] as GeneralUserPreferences | UserPreferencesForDashboard
                );
            } else {
                (item[itemKey] as any) = newItem[itemKey];
            }
        }
    }
    return item;
};
