import { GridItemChangeEvent, GridRowClickEvent } from '@progress/kendo-react-grid';
import { DataResult } from '@progress/kendo-data-query';
import { NavigateFunction } from 'react-router-dom';
import { EDIT_FIELD } from '../constants/kendoConstants';
import { showToaster } from './formActions';
import { Operations, SUCCESSFUL_ACTION, ToasterType } from '../constants';

/**
 * Returns a new array of objects with the same properties as the input array,
 * but with the EDIT_FIELD property set to true for the object with the same id
 * as the clicked row, and false for all other objects.
 *
 * @template T - The type of objects in the input array.
 * @param {GridRowClickEvent} event - The event object containing the clicked row data.
 * @param {T[]} data - The input array of objects.
 * @return {T[]} A new array of objects with the EDIT_FIELD property updated.
 */
export const handleRowClick = <T extends Record<never, never>>(
    event: GridRowClickEvent,
    data: T[]
): T[] => {
    return data.map((item) => ({
        ...item,
        [EDIT_FIELD]:
            item['id' as keyof typeof item] === event.dataItem.id &&
            !item[EDIT_FIELD as keyof typeof item],
    }));
};

/**
 * Returns a new array of objects with the EDIT_FIELD property updated based on the comparison of the given id and the specified index in each object.
 *
 * @param {T[keyof T]} id - The id to compare against.
 * @param {T[]} data - The input array of objects.
 * @param {string} [index='id'] - The index to compare against the id in each object.
 * @return {T[]} A new array of objects with the EDIT_FIELD property updated.
 */
export const handleEditButtonClick = <T extends Record<never, never>>(
    id: T[keyof T],
    data: T[],
    index = 'id'
): T[] => {
    return data.map((item) => ({
        ...item,
        [EDIT_FIELD]: item[index as keyof typeof item] === id,
    }));
};

/**
 * Returns a new array of objects with the EDIT_FIELD property set to undefined for each object in the input array.
 *
 * @template T - The type of objects in the input array.
 * @param {T[]} data - The input array of objects.
 * @return {T[]} A new array of objects with the EDIT_FIELD property set to undefined.
 */
export const exitEdit = <T extends Record<never, never>>(data: T[]): T[] => {
    return data.map((item) => ({
        ...item,
        [EDIT_FIELD]: undefined,
    }));
};

/**
 * Updates the value of a specific field in an array of objects based on the provided event data.
 *
 * @template T - The type of objects in the input array.
 * @param {GridItemChangeEvent} event - The event object containing the changed data.
 * @param {T[]} data - The input array of objects.
 * @param {string} index - The index of the field to update.
 * @return {T[]} A new array of objects with the updated field value.
 */
export const handleItemChange = <T extends Record<never, never>>(
    event: GridItemChangeEvent,
    data: T[],
    index: string
): T[] => {
    const inEditID = event.dataItem[index];
    const field = event.field || '';
    return data.map((item) =>
        item[index as keyof typeof item] === inEditID ? { ...item, [field]: event.value } : item
    );
};

/**
 * Expands an item in a grid data result based on a provided data item and field name.
 *
 * @template T - The type of the data item.
 * @param {T} dataItem - The data item to expand.
 * @param {DataResult} gridData - The grid data result to expand the item in.
 * @param {string} [fieldName='id'] - The name of the field to compare the data item with.
 * @return {DataResult} The updated grid data result with the item expanded.
 */
export const expandItem = <T>(dataItem: T, gridData: DataResult, fieldName = 'id'): DataResult => {
    const gridDataResult = Array.isArray(gridData) ? gridData : gridData.data;
    return {
        total: gridData.total || gridDataResult.length,
        data: gridDataResult.map((item) => {
            const newItem = JSON.parse(JSON.stringify(item));
            if (newItem[fieldName] === dataItem[fieldName as keyof typeof dataItem]) {
                newItem.expanded = dataItem['expanded' as keyof typeof dataItem];
            }
            return newItem;
        }),
    };
};

/**
 * Copies the value of a specified field from a data item to the clipboard.
 *
 * @param {T} dataItem - The data item from which to copy the field value.
 * @param {keyof T} field - The name of the field to copy.
 * @param {string} [customMessageOnCopy] - An optional custom message to display in a success toaster.
 * @return {void} This function does not return anything.
 */
export const copyItemToClipboard = <T extends Record<string, string>>(
    dataItem: T,
    field: keyof T,
    customMessageOnCopy?: string
): void => {
    navigator.clipboard.writeText(dataItem[field]);
    customMessageOnCopy
        ? showToaster(ToasterType.Success, customMessageOnCopy, undefined, true)
        : showToaster(ToasterType.Success, SUCCESSFUL_ACTION, Operations.CopiedToClipboard);
};

/**
 * Redirect the user to the item's view page, on row click.
 * @param dataItem The item checked on row click
 * @param location The item's page
 * @param specificItem specific item path element
 * @returns {void}
 */
export const openItemOnRowClick = (
    dataItem: any,
    navigate: NavigateFunction,
    location: string,
    specificItem?: string
): void => {
    const itemLocation = specificItem ? `/${specificItem}` : '';
    navigate(`/${location}/${dataItem.id}${itemLocation}/view`);
};
