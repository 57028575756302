import { NavigateFunction } from 'react-router';
import { Operations, SUCCESSFUL_ACTION, ToasterType } from '../constants';
import { clearLSOnComponentLeave } from './clearStorage';
import { showToaster } from './formActions';

export const navigateBack = () => window.history.back();

/**
 * Navigates to the parent location.
 *
 * @param {NavigateFunction} navigate - The function used for navigation.
 * @return {void} This function does not return a value.
 */
export const navigateToParent = (navigate: NavigateFunction): void => {
    navigate('..');
};
/**
 * Navigates to the specified path using the provided navigation function.
 *
 * @param {NavigateFunction} navigate - The function used for navigation.
 * @param {string} customPath - The path to navigate to. If not provided, the function will navigate to the root path.
 * @return {void} This function does not return a value.
 */
export const navigateToPath = (navigate: NavigateFunction, customPath: string): void => {
    navigate(customPath ?? '');
};

/**
 * Navigates after a fulfilled action.
 *
 * @param {NavigateFunction} navigate - The function used for navigation.
 * @param {`${Operations}`} [operation] - The operation string.
 * @return {void} This function does not return a value.
 */
export const navigateAfterFulfilledAction = (
    navigate: NavigateFunction,
    operation?: `${Operations}`
): void => {
    if (operation) {
        showToaster(ToasterType.Success, SUCCESSFUL_ACTION, operation);
    }
    clearLSOnComponentLeave();
    navigateToParent(navigate);
};

/**
 * Navigates to a child location after a fulfilled action.
 *
 * @param {NavigateFunction} navigate - The function used for navigation.
 * @param {string} customPath - The path to navigate to. If not provided, the function will navigate to the root path.
 * @param {`${Operations}`} [operation] - The operation string. If provided, a success toaster will be shown with the operation message.
 * @return {void} This function does not return a value.
 */
export const navigateToChildAfterFulfilledAction = (
    navigate: NavigateFunction,
    customPath: string,
    operation?: `${Operations}`
): void => {
    if (operation) {
        showToaster(ToasterType.Success, SUCCESSFUL_ACTION, operation);
    }
    localStorage.removeItem('addOrEdit');
    navigateToPath(navigate, customPath);
};
