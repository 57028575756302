import { SetStateAction } from 'react';
import { TFunction } from 'react-i18next';
import { INITIAL_GRID_STATE, LARGER_INITIAL_GRID_STATE, SortDirection } from '../constants';
import { SelectedEntities } from '../app/pages/interfaces';

/**
 * Formats a message based on the provided translation function and parameters.
 *
 * @param {TFunction} t - The translation function.
 * @param {string} [entity] - The optional entity for the message.
 * @param {string} [customMessage] - The optional custom message.
 * @return {JSX.Element[]} The formatted message as an array of JSX elements.
 */
export const formatMessage = (
    t: TFunction,
    entity?: string,
    customMessage?: string
): JSX.Element[] => {
    const message = entity
        ? t(`${'general.messages.kendoNoDataPlaceholder'}.${entity}`)
        : t(customMessage || '');
    return message.split('\n').map((i, key) => {
        return <div key={key}>{i}</div>;
    });
};

/**
 * Returns the initial grid state with the specified field name and sort direction.
 *
 * @param {string} fieldName - The name of the field to sort by.
 * @param {SortDirection} [sortDirection=SortDirection.Ascending] - The direction to sort the field in. Defaults to SortDirection.Ascending.
 * @param {boolean} [nonPageableGrid] - Whether the grid is non-pageable. Defaults to false.
 * @param {boolean} [customGridState] - Whether to use a custom grid state. Defaults to false.
 * @return {object} The initial grid state object with the specified field name and sort direction.
 */
export const getInitialGridState = (
    fieldName: string,
    sortDirection: SortDirection = SortDirection.Ascending,
    nonPageableGrid?: boolean,
    customGridState?: boolean
) => {
    const initialGridState = customGridState
        ? { ...LARGER_INITIAL_GRID_STATE }
        : { ...INITIAL_GRID_STATE };
    return {
        ...(nonPageableGrid ? {} : initialGridState),
        sort: [
            {
                field: fieldName,
                dir: sortDirection,
            },
        ],
    };
};

/**
 * Used to update the selection of the grid's items
 * @param newChangedData =>  new updated data when a selection is made
 * @param allSelectedItems => contains all IDs  of the selected entities or objects of type SelectedEntities
 * @param setNewSelectedItems => used to update the selected entities variable
 */
export const updateGridSelectedItems = (
    newChangedData: any[],
    allSelectedItems: (string | SelectedEntities)[],
    setNewSelectedItems: (value: SetStateAction<any>) => void,
    assignmentsFieldName?: string
) => {
    let itemsToAdd: (string | SelectedEntities)[] = [];

    if (!assignmentsFieldName) {
        itemsToAdd = newChangedData
            .filter((item) => item?.selected)
            .filter((selectedItem) => !allSelectedItems.includes(selectedItem.id))
            .map((newSelectedItems) => newSelectedItems?.id);
    } else {
        itemsToAdd = newChangedData
            .filter((item) => item?.selected)
            .filter(
                (selectedItem) =>
                    !allSelectedItems.find(
                        (element) => (element as SelectedEntities).id === selectedItem.id
                    )
            )
            .map((newSelectedItems) => {
                return {
                    id: newSelectedItems?.id,
                    number_of_assignations: newSelectedItems[assignmentsFieldName],
                };
            });
    }
    const itemsToRemove = newChangedData
        .filter((item) => item.selected === false)
        .filter((changedItems) =>
            assignmentsFieldName
                ? allSelectedItems.find(
                      (element) => (element as SelectedEntities).id === changedItems.id
                  )
                : allSelectedItems.includes(changedItems.id)
        )
        .map((item) => item.id);

    let newSelectedIDs = allSelectedItems;
    if (itemsToRemove) {
        newSelectedIDs = newSelectedIDs.filter(
            (item) =>
                !itemsToRemove.includes(assignmentsFieldName ? (item as SelectedEntities).id : item)
        );
    }
    setNewSelectedItems([...newSelectedIDs, ...itemsToAdd]);
};

/**
 * Checks if the bulk actions buttons should be visible based on the provided data.
 *
 * @param {(string | SelectedEntities)[]} data - The data used to determine the visibility of the buttons.
 * @return {boolean} Returns true if the buttons should be visible, false otherwise.
 */
export const checkBulkActionsButtonsVisibility = (data: (string | SelectedEntities)[]): boolean => {
    return data.length > 0;
};

/**
 * Checks if the given item ID is present in the selected items.
 *
 * @param {SelectedEntities[]} selectedItems - The array of selected items.
 * @param {string} itemId - The ID of the item to check.
 * @return {boolean} Returns true if the item ID is found in the selected items, false otherwise.
 */
export const checkGridDataSelection = (
    selectedItems: SelectedEntities[],
    itemId: string
): boolean => {
    return selectedItems.find((item) => item.id === itemId) ? true : false;
};
