import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { URLS, INITIAL_DATA_STATE } from '../../../constants';
import { Interceptor } from '../../common';
import { ExtendedGetReqProps, Site, SitesStateTypes, UpdateSiteModel } from '../interfaces';
import { buildQueryParams } from '../../../helpers';

const initialState: SitesStateTypes = {
    sites: INITIAL_DATA_STATE,
    site: null,
};

// create a thunk for get sites
export const getSites = createAsyncThunk(
    'sites/list',
    async ({ mandator_id, data_state }: ExtendedGetReqProps, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.Sites}`, {
                params: { mandator_id, ...queryParams },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for add site
export const addSite = createAsyncThunk(
    'sites/site/add',
    async (site: Site, { rejectWithValue }) => {
        try {
            return await Interceptor().post(`${URLS.Sites}`, { ...site });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//create thunk for deleting site
export const deleteSite = createAsyncThunk(
    'site/site/delete',
    async (siteId: string, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.Sites}/${siteId}`);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk for deleting selected sites
export const deleteSites = createAsyncThunk(
    'sites/deleteSelected',
    async (sitesForDelete: string[], { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.Sites}`, {
                data: sitesForDelete,
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk for getting site details
export const getSiteById = createAsyncThunk(
    'sites/site/get',
    async (site_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Sites}/${site_id}`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk for updating a site
export const updateSite = createAsyncThunk(
    'sites/site/update',
    async ({ site, site_id }: UpdateSiteModel, { rejectWithValue }) => {
        try {
            return await Interceptor().put(`${URLS.Sites}/${site_id}`, { ...site });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// reducers
const sitesSlice = createSlice({
    name: 'sites',
    initialState,
    reducers: {
        updateItems: (state, action) => {
            state.sites.data = [...action.payload];
        },
        resetSite: (state) => {
            state.site = initialState.site;
        },
        resetSites: (state) => {
            state.sites = initialState.sites;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSites.fulfilled, (state, { payload }) => {
            state.sites.data = [...payload.data];
            state.sites.total = payload.meta.total;
        });
        builder.addCase(getSiteById.fulfilled, (state, { payload }) => {
            state.site = payload.data[0];
        });
    },
});

export const { updateItems, resetSite, resetSites } = sitesSlice.actions;
export default sitesSlice.reducer;
