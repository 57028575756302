import { ComponentType } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { IGridColumn } from '../../common/grid/interfaces';
import { COLUMN_VISIBLE, MIN_COLUMN_WIDTH } from '../../../constants';

/**
 * Returns an array of column definitions for the Provider Templates grid.
 *
 * @param {{ customCells?: Map<string, ComponentType<GridCellProps>>, sortable?: boolean }} props
 * @property {Map<string, ComponentType<GridCellProps>>} [customCells] - A map of custom cell components.
 * @property {boolean} [sortable] - Whether the columns are sortable or not.
 *
 * @returns {IGridColumn[]} An array of column definitions.
 */
export const getProviderTemplatesColumns = (
    CustomCell: ComponentType<GridCellProps>
): IGridColumn[] => [
    {
        field: 'name',
        title: 'general.labels.name',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'created_at',
        title: 'general.labels.createdAt',
        filter: 'date',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'number_of_mandators',
        title: 'mandators.mandatorCount',
        filter: 'numeric',
        cell: CustomCell,
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
        sortable: false,
    },
];
