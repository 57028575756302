import { createRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
    FormControl,
    InputLabel,
    Box,
    FormLabel,
    Button,
    OutlinedInput,
    InputAdornment,
    IconButton,
} from '@mui/material';

// redux
import { useAppDispatch, useTranslateFormErrors } from '../hooks';
import { changeOldPassword } from './authDuck';

// common components, interfaces, constants and helpers
import { DialogProps } from '../common/interfaces';
import { getCognitoPasswordValidations, sanitizeFields, showToaster } from '../../helpers';
import { Operations, SUCCESSFUL_ACTION, ToasterType } from '../../constants';

interface ChangePasswordValues {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    showOldPassword: boolean;
    showNewPassword: boolean;
    showConfirmPassword: boolean;
}

/**
 * Renders a form for changing the user's old password.
 *
 * @param {DialogProps} props - The props for the component.
 * @param {Function} props.handleModalClose - a function to close the modal.
 * @return {JSX.Element} The rendered ChangeOldPassword component.
 */
const ChangeOldPassword = ({ handleModalClose }: DialogProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const initialValues: ChangePasswordValues = {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        showOldPassword: false,
        showNewPassword: false,
        showConfirmPassword: false,
    };
    const formRef = createRef<FormikProps<ChangePasswordValues>>();
    useTranslateFormErrors(formRef);

    const validationSchema = useCallback(
        () =>
            Yup.object().shape({
                oldPassword: getCognitoPasswordValidations(8, 20, 'oldPassword', true),
                newPassword: getCognitoPasswordValidations(8, 20, 'newPassword', true).matches(
                    /^\S+$/,
                    t('general.validations.passwordEmptySpaces')
                ),
                confirmNewPassword: Yup.string()
                    .oneOf([Yup.ref('newPassword'), null], t('password.match'))
                    .required(t('general.validations.required.newPassword')),
            }),
        [t]
    );
    const onSubmit = async (fields: ChangePasswordValues) => {
        fields = sanitizeFields(fields);
        const response = await dispatch(
            changeOldPassword({
                oldPassword: fields.oldPassword,
                newPassword: fields.newPassword,
            })
        );

        if (changeOldPassword.fulfilled.match(response)) {
            handleModalClose();
            showToaster(ToasterType.Success, SUCCESSFUL_ACTION, Operations.Updated);
            return;
        }

        if (changeOldPassword.rejected.match(response)) {
            const error = response.payload as string;
            showToaster(ToasterType.Error, error);
        }
    };
    const onClose = () => {
        handleModalClose();
    };
    return (
        <Box sx={{ mt: 2, mx: 4 }}>
            <Formik
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={async (fields) => {
                    onSubmit(fields);
                }}
                validationSchema={validationSchema}>
                {({ values, touched, errors, handleChange, handleBlur, setValues }) => (
                    <Form className="change-password-form">
                        <FormControl variant="outlined" sx={{ my: 1 }}>
                            <InputLabel htmlFor="oldPassword" className="outlined-input">
                                {t('password.oldPassword')}
                            </InputLabel>
                            <OutlinedInput
                                type={values.showOldPassword ? 'text' : 'password'}
                                name="oldPassword"
                                id="oldPassword"
                                autoComplete="current-password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                inputProps={{ role: 'oldPassword' }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            sx={{ padding: 0 }}
                                            onClick={() =>
                                                setValues({
                                                    ...values,
                                                    showOldPassword: !values.showOldPassword,
                                                })
                                            }>
                                            {values.showOldPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label={t('password.oldPassword')}
                            />
                            <FormLabel className="form-error" role="error-oldPassword">
                                {errors.oldPassword && touched.oldPassword
                                    ? errors.oldPassword
                                    : ''}
                            </FormLabel>
                        </FormControl>
                        <FormControl variant="outlined" sx={{ my: 1 }}>
                            <InputLabel htmlFor="newPassword">
                                {t('password.newPassword')}
                            </InputLabel>
                            <OutlinedInput
                                type={values.showNewPassword ? 'text' : 'password'}
                                name="newPassword"
                                id="newPassword"
                                autoComplete="new-password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                inputProps={{ role: 'newPassword' }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            sx={{ padding: 0 }}
                                            onClick={() =>
                                                setValues({
                                                    ...values,
                                                    showNewPassword: !values.showNewPassword,
                                                })
                                            }>
                                            {values.showNewPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label={t('password.newPassword')}
                            />
                            <FormLabel className="form-error" role="error-newPassword">
                                {errors.newPassword && touched.newPassword
                                    ? errors.newPassword
                                    : ''}
                            </FormLabel>
                        </FormControl>
                        <FormControl variant="outlined" sx={{ my: 1 }}>
                            <InputLabel htmlFor="confirmNewPassword">
                                {t('password.confirmNewPassword')}
                            </InputLabel>
                            <OutlinedInput
                                type={values.showConfirmPassword ? 'text' : 'password'}
                                name="confirmNewPassword"
                                id="confirmNewPassword"
                                autoComplete="new-password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                inputProps={{ role: 'confirmNewPassword' }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            sx={{ padding: 0 }}
                                            onClick={() =>
                                                setValues({
                                                    ...values,
                                                    showConfirmPassword:
                                                        !values.showConfirmPassword,
                                                })
                                            }>
                                            {values.showConfirmPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label={t('password.confirmNewPassword')}
                            />
                            <FormLabel className="form-error" role="error-confirmNewPassword">
                                {errors.confirmNewPassword && touched.newPassword
                                    ? errors.confirmNewPassword
                                    : ''}
                            </FormLabel>
                        </FormControl>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button variant="outlined" onClick={onClose} sx={{ px: 8 }}>
                                {t('general.labels.cancel')}
                            </Button>
                            <Button type="submit" variant="contained" sx={{ px: 8 }}>
                                {t('general.labels.save')}
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};
export default ChangeOldPassword;
