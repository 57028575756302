import { ComponentType } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { IGridColumn } from '../../common/interfaces';
import { COLUMN_VISIBLE, MIN_COLUMN_WIDTH } from '../../../constants';

/**
 * Returns an array of IGridColumn objects for the products assignment table.
 * @param {Map<string, ComponentType<GridCellProps>>} [customCells] - A map of custom cell components.
 * @param {boolean} [sortable] - Whether the columns in the grid are sortable.
 * @param {boolean} [isHiddenColumnMenu] - Whether the column menu is hidden.
 *
 * @returns {IGridColumn[]} - An array of IGridColumn objects.
 */
export const getProductAssignmentColumns = (
    customCells?: Map<string, ComponentType<GridCellProps>>,
    isHiddenColumnMenu?: boolean,
    sortable?: boolean
): IGridColumn[] => {
    return [
        {
            field: 'product_name',
            title: 'general.labels.name',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable,
            isHiddenColumnMenu,
            editable: false,
        },
        {
            field: 'product_code',
            title: 'products.code',
            filter: 'numeric',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            sortable,
            isHiddenColumnMenu,
            editable: false,
        },
        {
            field: 'mandator_product_code',
            title: 'products.mandatorCode',
            filter: 'numeric',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            cell: customCells?.get('mandator_product_code'),
            sortable,
            isHiddenColumnMenu,
        },
        {
            field: 'mandator_product_name',
            title: 'products.mandatorName',
            filter: 'text',
            minWidth: MIN_COLUMN_WIDTH,
            show: COLUMN_VISIBLE,
            cell: customCells?.get('mandator_product_name'),
            sortable,
            isHiddenColumnMenu,
        },
    ];
};
