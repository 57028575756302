import { GridCellProps } from '@progress/kendo-react-grid';
import { ComponentType } from 'react';
import { MIN_COLUMN_WIDTH, COLUMN_VISIBLE } from '../../../constants';
import { IGridColumn } from '../../common/grid/interfaces';

interface GetProductsProps {
    customCells?: Map<string, ComponentType<GridCellProps>>;
    extendedProducts?: boolean;
    mandatorProductColumns?: boolean;
    sortable?: boolean;
}

/**
 * Returns an array of column definitions for the Products grid.
 *
 * @param {{ customCells?: Map<string, ComponentType<GridCellProps>>, sortable?: boolean, extendedProducts?: boolean, mandatorProductColumns?: boolean }} props
 * @property {Map<string, ComponentType<GridCellProps>>} [customCells] - A map of custom cell components.
 * @property {boolean} [sortable] - Whether the columns are sortable or not.
 * @property {boolean} [extendedProducts] - If true, the columns are for the extended products grid.
 * @property {boolean} [mandatorProductColumns] - If true, the columns are for the mandator products grid.
 *
 * @returns {IGridColumn[]} An array of column definitions.
 */
export const getProductsColumns = ({
    customCells,
    extendedProducts,
    mandatorProductColumns,
    sortable,
}: GetProductsProps): IGridColumn[] => {
    if (extendedProducts) {
        return [
            {
                field: 'product',
                title: 'products.product',
                minWidth: MIN_COLUMN_WIDTH,
                show: COLUMN_VISIBLE,
                cell: customCells?.get('product'),
                sortable: false,
            },
            {
                field: 'unit_price',
                title: 'general.labels.unitPrice',
                minWidth: MIN_COLUMN_WIDTH,
                show: COLUMN_VISIBLE,
                editable: false,
                sortable,
            },
            {
                field: 'currency',
                title: 'sites.currency',
                minWidth: MIN_COLUMN_WIDTH,
                show: COLUMN_VISIBLE,
                editable: false,
                sortable,
            },
            {
                field: 'unit_measure',
                title: 'products.unitMeasure',
                minWidth: MIN_COLUMN_WIDTH,
                show: COLUMN_VISIBLE,
                editable: false,
                sortable,
            },
            {
                field: 'tax_percentage',
                title: 'sites.taxPercentage',
                minWidth: MIN_COLUMN_WIDTH,
                show: COLUMN_VISIBLE,
                editable: false,
                sortable,
            },
            {
                field: 'max_power',
                title: 'products.power',
                minWidth: MIN_COLUMN_WIDTH,
                show: COLUMN_VISIBLE,
                cell: customCells?.get('power'),
                sortable,
            },
        ];
    }
    return [
        {
            field: mandatorProductColumns ? 'product_code' : 'code',
            title: 'products.code',
            filter: 'text',
            show: COLUMN_VISIBLE,
            minWidth: MIN_COLUMN_WIDTH,
        },
        {
            field: mandatorProductColumns ? 'product_name' : 'name',
            title: 'general.labels.name',
            filter: 'text',
            show: COLUMN_VISIBLE,
            minWidth: MIN_COLUMN_WIDTH,
        },
        mandatorProductColumns
            ? {
                  field: 'mandator_product_code',
                  title: 'products.mandatorCode',
                  filter: 'text',
                  show: COLUMN_VISIBLE,
                  minWidth: MIN_COLUMN_WIDTH,
              }
            : {},
        mandatorProductColumns
            ? {
                  field: 'mandator_product_name',
                  title: 'products.mandatorName',
                  filter: 'text',
                  show: COLUMN_VISIBLE,
                  minWidth: MIN_COLUMN_WIDTH,
              }
            : {},
        {
            field: 'category',
            title: 'general.labels.category',
            filter: 'text',
            show: COLUMN_VISIBLE,
            minWidth: MIN_COLUMN_WIDTH,
        },
        {
            field: 'unit_measure',
            title: 'products.unitMeasure',
            filter: 'text',
            show: COLUMN_VISIBLE,
            minWidth: MIN_COLUMN_WIDTH,
        },
        !mandatorProductColumns
            ? {
                  field: 'number_of_points',
                  title: 'products.pointCount',
                  filter: 'numeric',
                  show: COLUMN_VISIBLE,
                  minWidth: MIN_COLUMN_WIDTH,
                  sortable: false,
              }
            : {},
        !mandatorProductColumns
            ? {
                  field: 'description',
                  title: 'general.labels.description',
                  filter: 'text',
                  show: COLUMN_VISIBLE,
                  minWidth: MIN_COLUMN_WIDTH,
                  cell: customCells?.get('description'),
              }
            : {},
    ];
};
