import {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    createRef,
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';

// hooks
import { useAppDispatch, useAppSelector } from '../../../hooks';

// actions
import {
    assignSitesToTemplate,
    getReceiptTemplates,
    resetTemplates,
} from '../receiptTemplates/digitalReceiptsDuck';
import { closeModal, getTemplateData, preventSubmit, resetFormFields } from '../../../../helpers';
import { FormControlLabelComponent, SelectComponent } from '../../../common';
import { DELIVERY_NOTE_TYPE, INVOICE_TYPE } from '../../../../constants';
import { SiteAssignmentsBulkForm, SitesAssignmentsRequest } from '../../interfaces';
import { ControlRadioButton } from '../../../common/interfaces';

interface UpdateSitesAssignmentsProps {
    selectedSites: string[];
    getSitesAssignmentsData?: () => void;
    resetSelectedIdsVariable?: Dispatch<SetStateAction<string[]>>;
}

/**
 * A component for updating site assignments for multiple sites.
 * @param {string[]} selectedSites - An array of site IDs that are selected in the table.
 * @param {() => void} getSitesAssignmentsData - A function to get site assignments data.
 * @param {Dispatch<SetStateAction<string[]>>} resetSelectedIdsVariable - A function to reset selected IDs variable.
 * @returns {JSX.Element} A form for updating site assignments.
 */
const UpdateSitesAssignments = ({
    selectedSites,
    getSitesAssignmentsData,
    resetSelectedIdsVariable,
}: UpdateSitesAssignmentsProps): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const formRef = createRef<FormikProps<SiteAssignmentsBulkForm>>();

    const assignationRadioControls: ControlRadioButton[] = useMemo(
        () => [
            {
                label: t('siteAssignments.assignTemplate'),
                value: 'assign',
            },
            {
                label: t('siteAssignments.unassignTemplate'),
                value: 'unassign',
            },
        ],
        [t]
    );

    const { selectedMandator } = useAppSelector((state) => state.mandators);
    const { templates } = useAppSelector((state) => state.digitalReceipts);

    // get templates for mandators
    useEffect(() => {
        if (selectedMandator?.id) {
            dispatch(getReceiptTemplates({ mandator_id: selectedMandator.id }));
        }
        return () => {
            dispatch(resetTemplates());
        };
    }, [dispatch, selectedMandator]);

    const formInitialValues: SiteAssignmentsBulkForm = useMemo(
        () => ({
            assignation_option: assignationRadioControls[0].value as string,
            invoice_id: '',
            note_id: '',
            unassigned_invoice: false,
            unassigned_note: false,
        }),
        [assignationRadioControls]
    );

    const handleCloseModal = useCallback(() => {
        getSitesAssignmentsData?.();
        closeModal();
        resetSelectedIdsVariable?.([]);
    }, [getSitesAssignmentsData, resetSelectedIdsVariable]);

    const getRequestObject = useCallback(
        (
            data: string[] | { type: string; value: boolean }[],
            conditionValue: string | boolean,
            unassignFieldName?: string
        ) => {
            return selectedSites.reduce(
                (previousValue: SitesAssignmentsRequest[], site_id: string) => {
                    Object.values(data).forEach((item) => {
                        const itemToCheck = unassignFieldName ? item[unassignFieldName] : item;
                        if (itemToCheck !== conditionValue)
                            previousValue.push(
                                unassignFieldName
                                    ? {
                                          site_id: site_id,
                                          template_type: item.type,
                                          template_id: null,
                                      }
                                    : { site_id: site_id, template_id: item }
                            );
                    });
                    return previousValue;
                },
                []
            );
        },
        [selectedSites]
    );

    const handleSubmit = async (fields: SiteAssignmentsBulkForm) => {
        let requestObject: SitesAssignmentsRequest[] = [];
        //assign template
        if (fields.assignation_option === assignationRadioControls[0].value) {
            requestObject = getRequestObject([fields.invoice_id, fields.note_id], '');
        }
        //unassign template
        else {
            const unassignItems = [
                { type: INVOICE_TYPE, value: fields.unassigned_invoice },
                { type: DELIVERY_NOTE_TYPE, value: fields.unassigned_note },
            ];
            requestObject = getRequestObject(unassignItems, false, 'value');
        }

        if (requestObject.length > 0) {
            const assignSitesResponse = await dispatch(assignSitesToTemplate(requestObject));
            if (assignSitesToTemplate.fulfilled.match(assignSitesResponse)) {
                handleCloseModal();
            }
        } else {
            handleCloseModal();
        }
    };

    const handleChangeAssignationOption = (event: ChangeEvent<HTMLInputElement>) => {
        const newAssignationValue = event.target.value;
        formRef.current?.setFieldValue('assignation_option', newAssignationValue);
        if (newAssignationValue === assignationRadioControls[1].value) {
            resetFormFields(formRef, ['note_id', 'invoice_id'], '');
        }
    };

    const checkAssignationValue = useCallback(
        (assignationOption: string) => {
            return assignationOption === assignationRadioControls[0].value;
        },
        [assignationRadioControls]
    );

    return (
        <Formik initialValues={formInitialValues} onSubmit={handleSubmit} innerRef={formRef}>
            {({ values, handleChange, touched, errors, handleBlur, setFieldValue }) => (
                <Form
                    onKeyDown={preventSubmit}
                    noValidate
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '16px',
                        alignContent: 'center',
                        flexWrap: 'wrap',
                    }}>
                    <FormControlLabelComponent
                        control="radio"
                        label={assignationRadioControls[0].label}
                        value={assignationRadioControls[0].value}
                        labelPlacement="end"
                        labelVariant="body1"
                        size="small"
                        color="secondary"
                        name="assign-template"
                        onChange={handleChangeAssignationOption}
                        checkedRadioButton={checkAssignationValue(values.assignation_option)}
                    />
                    <SelectComponent
                        data={getTemplateData(templates.data, INVOICE_TYPE)}
                        defaultValue={values.invoice_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        valueEntry="id"
                        titleEntry="name"
                        name="invoice_id"
                        formChild
                        label={t('siteAssignments.invoice')}
                        errors={errors}
                        touched={touched}
                        className="formControl"
                        placeholder={t('siteAssignments.invoice')}
                        sx={{ width: '200px' }}
                        disabled={!checkAssignationValue(values.assignation_option)}
                    />
                    <SelectComponent
                        data={getTemplateData(templates.data, DELIVERY_NOTE_TYPE)}
                        defaultValue={values.note_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        valueEntry="id"
                        titleEntry="name"
                        name="note_id"
                        formChild
                        label={t('siteAssignments.deliveryNote')}
                        errors={errors}
                        touched={touched}
                        className="formControl"
                        placeholder={t('siteAssignments.deliveryNote')}
                        sx={{ width: '200px' }}
                        disabled={!checkAssignationValue(values.assignation_option)}
                    />
                    <FormControlLabelComponent
                        control="radio"
                        label={assignationRadioControls[1].label}
                        value={assignationRadioControls[1].value}
                        labelPlacement="end"
                        labelVariant="body1"
                        size="small"
                        color="secondary"
                        name="unassign-template"
                        onChange={handleChangeAssignationOption}
                        checkedRadioButton={!checkAssignationValue(values.assignation_option)}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: '16px' }}>
                        <FormControlLabelComponent
                            control="checkbox"
                            label={t('siteAssignments.invoice')}
                            value={values.unassigned_invoice}
                            labelPlacement="end"
                            labelVariant="body2"
                            color="secondary"
                            name="invoice-checkbox"
                            onChange={() =>
                                setFieldValue('unassigned_invoice', !values.unassigned_invoice)
                            }
                            disabled={checkAssignationValue(values.assignation_option)}
                        />
                        <FormControlLabelComponent
                            control="checkbox"
                            label={t('siteAssignments.deliveryNote')}
                            value={values.unassigned_note}
                            labelPlacement="end"
                            labelVariant="body2"
                            color="secondary"
                            name="delivery-note-checkbox"
                            onChange={() =>
                                setFieldValue('unassigned_note', !values.unassigned_note)
                            }
                            disabled={checkAssignationValue(values.assignation_option)}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: '15px' }}>
                        <Button
                            variant="outlined"
                            onClick={closeModal}
                            sx={{
                                mr: 1,
                            }}>
                            {t('general.labels.cancel')}
                        </Button>

                        <Button type="submit" variant="contained">
                            {t('general.labels.apply')}
                        </Button>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};
export default UpdateSitesAssignments;
