import { ComponentType } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';

import { IGridColumn } from '../../common/interfaces';
import { COLUMN_VISIBLE, MIN_COLUMN_WIDTH } from '../../../constants';

/**
 * Returns columns for the Site Assignments grid.
 * @param customColumns A map of custom cell components.
 * @returns An array of IGridColumn objects.
 */
export const getSiteAssignmentsColumns = (
    customColumns: Map<string, ComponentType<GridCellProps>>
): IGridColumn[] => [
    {
        field: 'name',
        title: 'sites.entityName',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'city',
        title: 'general.geolocation.city',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'invoice_name',
        title: 'siteAssignments.invoice',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
        sortable: false,
    },
    {
        field: 'invoice_status',
        title: 'siteAssignments.invoiceStatus',
        filter: 'boolean',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
        cell: customColumns.get('invoice_status'),
        sortable: false,
    },
    {
        field: 'note_name',
        title: 'siteAssignments.deliveryNote',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
        sortable: false,
    },
    {
        field: 'note_status',
        title: 'siteAssignments.deliveryNoteStatus',
        filter: 'boolean',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
        cell: customColumns.get('delivery_note_status'),
        sortable: false,
    },
];
