import { CustomDataState, IGridColumn } from '../app/common/interfaces';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';

/**
 * Builds a query object based on the provided state object.
 *
 * @param {CustomDataState} state - The state object containing pagination, sorting, and filtering information.
 * @return {object} The query object with pagination, sorting, and filtering parameters.
 */
export const buildQueryParams = (state: CustomDataState) => {
    let query = buildPaginationQuery(state);

    if (state.sort && state.sort.length > 0) {
        const sort = buildSortQuery(state.sort[0]);
        query = { ...query, ...sort };
    }
    if (state.filter || state.simpleFilter) {
        const filter = buildFilterQuery(state);
        query = { ...query, ...filter };
    }
    return query;
};

/**
 * Builds a pagination query object based on the provided state object.
 *
 * @param {CustomDataState} state - The state object containing pagination information.
 * @return {object} The pagination query object with 'page[limit]' and 'page[offset]' parameters.
 */
const buildPaginationQuery = (state: CustomDataState) => {
    return { 'page[limit]': state.take, 'page[offset]': state.skip };
};

/**
 * Builds a sort query object based on the provided SortDescriptor.
 *
 * @param {SortDescriptor} state - The SortDescriptor containing the field and direction to sort by.
 * @return {object} The sort query object with a 'sort' property that is either the field name or the field name prefixed with a '-' for descending sort.
 */
const buildSortQuery = (state: SortDescriptor) => {
    return { sort: state.dir === 'desc' ? `-${state.field}` : state.field };
};

/**
 * Builds a filter query object based on the provided state object.
 *
 * @param {CustomDataState} state - The state object containing filtering information.
 * @return {object} The filter query object with a 'filter' property that is a JSON string of the filter or simpleFilter.
 */
const buildFilterQuery = (state: CustomDataState) => {
    return { filter: JSON.stringify(state.filter ?? state.simpleFilter) };
};

/**
 * Returns an array of searchable fields from the given array of grid columns.
 *
 * @param {IGridColumn[]} columns - The array of grid columns to extract searchable fields from.
 * @return {(string | undefined)[]} An array of searchable field names.
 */
export const getSearchableFields = (columns: IGridColumn[]): (string | undefined)[] => {
    return columns
        .map((column) =>
            column.filter && column.filter === 'text' && column.show ? column.field : undefined
        )
        .flat()
        .filter((field) => field);
};

/**
 * Generates a composite filter descriptor for searching based on the given searchable fields and value.
 *
 * @param {(string | undefined)[]} searchableFields - An array of searchable field names.
 * @param {string} value - The value to search for.
 * @return {CompositeFilterDescriptor} A composite filter descriptor for searching.
 */
export const getSearchFilters = (
    searchableFields: (string | undefined)[],
    value: string
): CompositeFilterDescriptor => {
    const searchFilter = searchableFields.map((field) => ({
        field,
        operator: 'contains',
        value,
    }));
    return { logic: 'or', filters: searchFilter };
};
