import React from 'react';
import { FormControl, RadioGroup, Typography, TypographyProps } from '@mui/material';

import { ControlRadioButton } from '../interfaces';
import { FormControlLabelComponent } from '..';

interface RadioButtonProps {
    color: TypographyProps['color'];
    controls: ControlRadioButton[];
    label: string;
    labelVariant: TypographyProps['variant'];
    value: boolean | string;
    handleChange(event: React.ChangeEvent<HTMLInputElement>): void;

    isDisabled?: boolean;
}

/**
 * Renders a radio button group component.
 *
 * @param {RadioButtonProps} props - The props for the radio button group component.
 * @param {string} props.label - The label for the radio button group.
 * @param {ControlRadioButton[]} props.controls - The array of radio button controls.
 * @param {boolean | string} props.value - The value of the selected radio button.
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} props.handleChange - The function to handle radio button change.
 * @param {TypographyProps['variant']} props.labelVariant - The variant of the label.
 * @param {TypographyProps['color']} props.color - The color of the label.
 * @param {boolean} [props.isDisabled] - Whether the radio button group is disabled.
 * @return {JSX.Element} The rendered radio button group component.
 */
const RadioButtonGroup = ({
    color,
    controls,
    isDisabled,
    label,
    labelVariant,
    value,
    handleChange,
}: RadioButtonProps) => {
    return (
        <FormControl sx={{ m: 1 }}>
            <Typography variant={labelVariant} color={color}>
                {label}
            </Typography>
            <RadioGroup aria-labelledby="radio-buttons" value={value} onChange={handleChange}>
                {controls.map((control, index) => (
                    <FormControlLabelComponent
                        key={index}
                        control={'radio'}
                        label={control.label}
                        value={control.value}
                        labelPlacement={'end'}
                        labelVariant={'body2'}
                        size={'small'}
                        color={'secondary'}
                        name={control.value.toString()}
                        disabled={isDisabled}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};
export default RadioButtonGroup;
