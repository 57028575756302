import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Box, IconButton, Typography, Divider, Tooltip } from '@mui/material';
import { ArrowBack, DeleteForever, Edit } from '@mui/icons-material';
import TooltipButton from '../buttons/TooltipButton';

import { backAndCancelAction } from '../../../helpers';

interface FormComponentHeaderProps {
    title: string;

    customButton?: JSX.Element;
    editButton?: boolean;
    isBackBtnHidden?: boolean;
    backAction?: () => void;
    handleDelete?: () => void;
}

/**
 * Renders a form component header with action buttons.
 *
 * @param {Object} props - The properties for the component.
 * @param {Function} [props.backAction] - The function to handle back action.
 * @param {string} props.title - The title to be displayed.
 * @param {Function} [props.handleDelete] - The function to handle delete action.
 * @param {JSX.Element} [props.customButton] - The custom button element.
 * @param {boolean} [props.editButton] - Indicates if the edit button is visible.
 * @param {boolean} [props.isBackBtnHidden] - Indicates if the back button should be hidden
 * @return {JSX.Element} The rendered form component header.
 */
const FormComponentHeader = ({
    customButton,
    editButton,
    isBackBtnHidden,
    title,
    backAction,
    handleDelete,
}: FormComponentHeaderProps): JSX.Element => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleBackAction = () => {
        backAction ? backAction() : backAndCancelAction(navigate);
    };

    const handleEditButton = () => {
        const editEntityPath = window.location.pathname.replace('view', 'edit');
        navigate(editEntityPath);
    };

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                {!isBackBtnHidden && (
                    <Tooltip title={t('general.labels.back')}>
                        <IconButton
                            aria-label="back"
                            sx={{ p: 0, mr: 2 }}
                            onClick={handleBackAction}>
                            <ArrowBack color="primary" className="icon-24" />
                        </IconButton>
                    </Tooltip>
                )}

                <Typography variant="h1" sx={{ flex: 1 }}>
                    {title}
                </Typography>

                {editButton && (
                    <TooltipButton
                        buttonVariant="contained"
                        title="general.labels.edit"
                        icon={<Edit />}
                        onClick={handleEditButton}
                        role="edit"
                    />
                )}

                {customButton}

                {handleDelete && (
                    <TooltipButton
                        buttonVariant="contained"
                        title="general.labels.delete"
                        icon={<DeleteForever />}
                        onClick={handleDelete}
                        role="delete"
                        sx={customButton || editButton ? { marginLeft: '5px' } : undefined}
                    />
                )}
            </Box>
            <Divider />
        </>
    );
};

export default FormComponentHeader;
