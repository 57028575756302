import { nanoid } from '@reduxjs/toolkit';
import { UploadFileStatus } from '@progress/kendo-react-upload';
import { convertDateToFormat } from './dateHandlers';

/**
 * Returns a formatted file name based on the provided format, extension, and optional mandator name.
 *
 * @param {string} format - The format to use for converting the date.
 * @param {string} [ext='pdf'] - The file extension to use. Defaults to 'pdf'.
 * @param {string} [mandatorName] - The optional mandator name to include in the file name.
 * @return {string} The formatted file name.
 */
export const getFileName = (format: string, ext = 'pdf', mandatorName?: string): string => {
    const fileName = mandatorName ? `report, ${mandatorName}.${ext}` : `report.${ext}`;
    const formattedDate = convertDateToFormat(new Date(), format).replace(':', '.');

    return `${formattedDate}, ${fileName}`;
};

/**
 * Generates an array of default file information based on the provided value.
 *
 * @param {string} value - The value to generate the file information from.
 * @param {number} [status] - The status of the file. Defaults to UploadFileStatus.Initial.
 * @return {Array<{name: string, extension: string, uid: string, status: number, progress: number}>}
 * An array of objects containing the default file information.
 */
export const getDefaultFilesInfo = (
    value: string,
    status?: number
): Array<{ name: string; extension: string; uid: string; status: number; progress: number }> => {
    return [
        {
            name: value.split('/').pop() ?? '',
            extension: '.' + value.split('.').pop(),
            uid: nanoid(),
            status: status || UploadFileStatus.Initial,
            progress: 0,
        },
    ];
};
