import { lookup } from 'country-data';
import moment from 'moment-timezone';

import {
    LANGUAGES,
    NumberSeparators,
    DAY_FORMAT,
    MONTH_FORMAT,
    YEAR_FORMAT,
    HOUR_FORMAT,
    MINUTES_FORMAT,
    DEFAULT_CURRENCY,
    DEFAULT_DATE,
} from '../constants';
import { NumberSeparatorsTypes } from '../app/common/interfaces';

/**
 * Converts the given string representation of an enabled state to a boolean value.
 *
 * @param {string} enabled - The string representation of the enabled state. Must be either 'active' or 'inactive'.
 * @return {boolean|undefined} - Returns true if the enabled state is 'active', false if the enabled state is 'inactive', and undefined otherwise.
 */
export const convertEnabledToBoolean = (enabled: string): boolean | undefined => {
    switch (enabled) {
        case 'active':
            return true;
        case 'inactive':
            return false;
        default:
            return undefined;
    }
};

/**
 * Converts a country name to its corresponding ISO 3166-1 alpha-2 code.
 *
 * @param {string} countryName - The name of the country to be converted.
 * @return {string} The ISO 3166-1 alpha-2 code of the country, or 'GB' if the country is not found.
 */
export const convertCountryNameToCode = (countryName: string): string => {
    const country = lookup.countries({ name: capitalizeString(countryName) })[0];
    return country ? country.alpha2 : 'GB';
};

/**
 * Capitalizes the first letter of a given string.
 *
 * @param {string} str - The input string to be capitalized.
 * @return {string} The capitalized string, or an empty string if the input is falsy.
 */
export const capitalizeString = (str: string): string => {
    return str ? `${str.substring(0, 1).toUpperCase()}${str.slice(1)}` : '';
};

/**
 * Adds a country code to a language code.
 *
 * @param {string} lng - The language code to add a country code to.
 * @return {string} The language code with a country code appended, or 'en-GB' if the language code is not found.
 */
export const addCountryCodeToLanguage = (lng: string): string => {
    const countryCode = LANGUAGES.find((language) => language.code === lng)?.countryCode;
    return `${lng}-${countryCode}` ?? 'en-GB';
};

/**
 * Extracts the date format from a given date time format string.
 *
 * @param {string} dateTimeFormat - The date time format string.
 * @return {string} The extracted date format.
 */
const getDateFormatFromDateTimeFormat = (dateTimeFormat: string): string => {
    let dateFormat = '';
    const splittedDateTimeFormat = dateTimeFormat.split(' ');
    if (splittedDateTimeFormat.length > 3) {
        dateFormat =
            `${splittedDateTimeFormat[0]} ${splittedDateTimeFormat[1]} ${splittedDateTimeFormat[2]}`.replace(
                ',',
                ''
            );
    } else {
        dateFormat = splittedDateTimeFormat[0].replace(',', '');
    }
    return dateFormat;
};

/**
 * Converts a UTC hour to the corresponding local hour in a specified language and timezone.
 *
 * @param {string} lang - The language code for the target timezone.
 * @param {string} utcHour - The UTC hour to convert.
 * @return {number} The local hour corresponding to the UTC hour.
 */
export const convertHourToLocale = (lang: string, utcHour: string): number => {
    const countryCode = LANGUAGES.find((language) => language.code === lang)?.countryCode ?? 'GB';
    const timezone = moment.tz.zonesForCountry(countryCode)[0];
    const momentDateAndTime = moment.utc(`${DEFAULT_DATE} ${utcHour}`).tz(timezone);
    return Number(momentDateAndTime?.format().slice(11, 13));
};

/**
 * Returns an object containing various date and number formatting options for a given language.
 *
 * @param {string} lng - The language code to determine the formatting options.
 * @return {Object} An object with the following properties:
 *   - dateFormat: The extracted date format.
 *   - dateTimeFormat: The date and time format.
 *   - decimalSeparator: The decimal separator character.
 *   - thousandsSeparator: The thousands separator character.
 *   - momentDateTimeFormat: The date and time format with day and year capitalized.
 *   - momentDateFormat: The date format capitalized.
 */
export const getFormats = (lng: string) => {
    const todayDate = Intl.DateTimeFormat(lng, {
        dateStyle: 'short',
        timeStyle: 'short',
    }).formatToParts(new Date());

    const dateFormat = todayDate.map((el) => {
        switch (el.type) {
            case 'year':
                return YEAR_FORMAT;
            case 'day':
                return DAY_FORMAT;
            case 'month':
                return MONTH_FORMAT;
            case 'hour':
                return HOUR_FORMAT;
            case 'minute':
                return MINUTES_FORMAT;
            case 'dayPeriod':
                return '';
            default:
                return el.value;
        }
    });

    const numberFormat = Intl.NumberFormat(lng).formatToParts(18988999999.2);
    const decimalSeparator =
        (numberFormat.find((item) => item.type === 'decimal')?.value as NumberSeparatorsTypes) ??
        '.';
    const thousandsSeparator =
        (numberFormat.find((item) => item.type === 'group')?.value as NumberSeparatorsTypes) ?? ',';

    const date = getDateFormatFromDateTimeFormat(dateFormat.join(''));

    return {
        dateFormat: date,
        dateTimeFormat: dateFormat.join(''),
        decimalSeparator: decimalSeparator,
        thousandsSeparator: thousandsSeparator,
        momentDateTimeFormat: dateFormat
            .join('')
            .replace(DAY_FORMAT, convertStringToUpperCase(DAY_FORMAT))
            .replace(YEAR_FORMAT, convertStringToUpperCase(YEAR_FORMAT)),
        momentDateFormat: convertStringToUpperCase(date),
    };
};

/**
 * Converts a number or string to a localized string representation.
 *
 * @param {number | string | null | undefined} data - The number or string to convert.
 * @param {string} language - The language code to use for localization.
 * @param {Intl.NumberFormatOptions} [options] - Optional number formatting options.
 * @return {string} The localized string representation of the input data.
 */
export const convertNumberToString = (
    data: number | string | null | undefined,
    language: string,
    options?: Intl.NumberFormatOptions
): string => {
    if (typeof data === 'string') {
        return parseFloat(data).toLocaleString(language, options);
    } else {
        return data !== null && data !== undefined ? data?.toLocaleString(language, options) : '';
    }
};

/**
 * Converts a string representation of a number to a number.
 *
 * @param {string} number - The string representation of the number.
 * @param {string} decimalSeparator - The decimal separator used in the string representation.
 * @param {string} thousandsSeparator - The thousands separator used in the string representation.
 * @return {number} The converted number.
 */
export const convertStringToNumber = (
    number: string,
    decimalSeparator: string,
    thousandsSeparator: string
): number => {
    let value = number;
    if (decimalSeparator === NumberSeparators.Comma) {
        if (thousandsSeparator === NumberSeparators.Point) {
            value = value.replaceAll(NumberSeparators.Point, '');
        }
        value = value.replace(NumberSeparators.Comma, NumberSeparators.Point);
    } else if (
        decimalSeparator === NumberSeparators.Point &&
        thousandsSeparator === NumberSeparators.Comma
    ) {
        value = value.replaceAll(NumberSeparators.Comma, '');
    }
    return parseFloat(value.replace(/\s/g, ''));
};

/**
 * Converts a given string to uppercase.
 *
 * @param {string} str - The string to be converted to uppercase.
 * @return {string} The uppercase version of the input string.
 */
export const convertStringToUpperCase = (str: string): string => {
    return str.toUpperCase();
};

/**
 * Converts a number to a currency string representation.
 *
 * @param {number} data - The number to be converted.
 * @param {string} language - The language code for number formatting.
 * @param {string} [currency=DEFAULT_CURRENCY] - The currency code. Defaults to DEFAULT_CURRENCY.
 * @param {string} [unitMeasure] - The unit measure for the currency.
 * @return {string} The currency string representation.
 */
export const convertNumberToCurrencyString = (
    data: number,
    language: string,
    currency = DEFAULT_CURRENCY,
    unitMeasure?: string
): string => {
    if (!unitMeasure) {
        return `${convertNumberToString(data, language)} ${currency}`;
    }
    return `${convertNumberToString(data, language)} ${currency}/${unitMeasure}`;
};

/**
 * Converts latitude and longitude coordinates to a formatted string.
 *
 * @param {number} lat - The latitude coordinate.
 * @param {number} lng - The longitude coordinate.
 * @param {string} language - The language code for number formatting.
 * @return {string} The formatted string containing the latitude and longitude coordinates.
 */
export const convertCoordinates = (lat: number, lng: number, language: string): string => {
    return `${convertNumberToString(lat, language)}, ${convertNumberToString(lng, language)}`;
};

/**
 * Encodes special characters in an HTML string.
 *
 * @param {string} htmlString - The HTML string to be encoded.
 * @return {string} The encoded HTML string.
 */
export const htmlEncode = (htmlString: string): string => {
    return htmlString
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
};

/**
 * Decodes an HTML string by removing script tags and HTML tags.
 *
 * @param {string} str - The HTML string to decode.
 * @return {string} The decoded string.
 */
export const htmlDecode = (str: string): string => {
    if (str && typeof str === 'string') {
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
    }

    return str;
};
