import { ComponentType } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { IGridColumn } from '../../common/grid/interfaces';
import { COLUMN_VISIBLE, MIN_COLUMN_WIDTH } from '../../../constants';

/**
 * Returns columns for the Device Logs grid.
 * @param customCells A map of custom cell components.
 * @returns An array of IGridColumn objects.
 */
export const getDeviceLogsColumns = (
    customCells: Map<string, ComponentType<GridCellProps>>
): IGridColumn[] => [
    {
        field: 'time',
        title: 'devices.logs.eventTime',
        filter: 'date',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'message_type',
        title: 'devices.logs.message',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'point_number',
        title: 'devices.remoteControl.point_number',
        filter: 'text',
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
    },
    {
        field: 'level',
        title: 'userPermissions.level',
        sortable: false,
        minWidth: MIN_COLUMN_WIDTH,
        show: COLUMN_VISIBLE,
        cell: customCells.get('level'),
    },
];
