import { useEffect, useRef } from 'react';
import {
    Editor,
    EditorChangeEvent,
    EditorMountEvent,
    ProseMirror,
} from '@progress/kendo-react-editor';
import { FormikErrors, FormikValues, getIn } from 'formik';
import { Typography } from '@mui/material';
import DOMPurify from 'dompurify';

interface CustomEditorProps {
    className?: string;
    content?: string;
    disabled?: boolean;
    errors?: FormikErrors<FormikValues>;
    fieldName?: string;
    height?: string;
    minHeight?: string;
    tools?: any[];
    width?: string;
    onChange?: (data: EditorChangeEvent) => void;
}
const { EditorState, EditorView, Plugin, PluginKey } = ProseMirror;

// Add a hook to remove the target and title attributes from <a> elements and alt attribute from img element
DOMPurify.addHook('beforeSanitizeAttributes', (node) => {
    // Check if the node is an anchor (<a>) tag
    if (node.tagName === 'A') {
        // Remove the 'target' attribute
        node.removeAttribute('target');
        // Remove the 'target' attribute
        node.removeAttribute('title');
    }
    // Check if the node is an image
    if (node.tagName === 'IMG') {
        // Remove the 'alt' attribute
        node.removeAttribute('alt');
    }
});

/**
 * Renders a custom Kendo editor component.
 * It supports a custom toolbar and can display validation errors that might appear
 *
 * @param {Object} props - The props for the custom editor.
 * @param {Array} [props.tools] - The custom toolbar for the editor.
 * @param {string} [props.height] - The height of the editor.
 * @param {string} [props.width] - The width of the editor.
 * @param {string} [props.content] - The initial content of the editor.
 * @param {string} [props.className] - The CSS class name for the editor.
 * @param {boolean} [props.disabled] - Indicates if the editor is disabled.
 * @param {string} [props.fieldName] - The name of the field associated with the editor.
 * @param {Object} [props.errors] - The validation errors for the editor.
 * @param {Function} [props.onChange] - The callback function for handling changes in the editor.
 * @param {string} [props.minHeight] - The min height of the editor
 * @return {JSX.Element} The custom editor component.
 */
const CustomEditor = ({
    className,
    content,
    disabled,
    errors,
    fieldName,
    height,
    minHeight,
    tools,
    width,
    onChange,
}: CustomEditorProps): JSX.Element => {
    const inputFieldName = fieldName || '';
    const errorText = getIn(errors, inputFieldName) ?? '';
    const editableRef = useRef<boolean>(!disabled);

    const nastyStrings = ['\\&quot', '&lt', '&gt', '&ndash', '&NewLine', '&nbsp', '&not', '&#92;'];
    if (content && content !== undefined) {
        nastyStrings.forEach((nastyString) => {
            content = content?.split(nastyString).join('');
        });
    }

    useEffect(() => {
        if (editableRef.current && disabled) {
            editableRef.current = !disabled;
        }
    }, [disabled]);

    const onMount = (event: EditorMountEvent) => {
        const state = event.viewProps.state;
        const plugins = [
            ...state.plugins,
            new Plugin({
                key: new PluginKey('readonly'),
                props: { editable: () => editableRef.current },
                filterTransaction: (tr, _st) => editableRef.current || !tr.docChanged,
            }),
        ];

        return new EditorView(
            { mount: event.dom },
            {
                ...event.viewProps,
                state: EditorState.create({ doc: state.doc, plugins }),
            }
        );
    };

    return (
        <>
            <Editor
                defaultEditMode="div"
                className={className ?? ''}
                tools={disabled ? undefined : tools}
                style={{ height: height ?? 'auto', width: width ?? 'auto', minHeight: minHeight }}
                value={DOMPurify.sanitize(content ?? '')}
                onChange={onChange}
                onMount={onMount}
            />
            {errors && (
                <Typography color="#d63232" variant="caption" className="errorHeight">
                    {errorText}
                </Typography>
            )}
        </>
    );
};

export default CustomEditor;
