import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ChartTypes, INITIAL_DATA_STATE, URLS } from '../../../constants';
import { buildQueryParams } from '../../../helpers';
import { Interceptor } from '../../common';
import {
    EdentifyInitialStateProps,
    EdentifyUnitsRequestBody,
    ExtendedGetReqProps,
} from '../interfaces';

const initialState: EdentifyInitialStateProps = {
    graphs: {
        [ChartTypes.EdentifyUnitsCount]: {},
        [ChartTypes.VehicleTagsCount]: {},
        [ChartTypes.EdentifyUnitsGrid]: {},
        [ChartTypes.VehicleTagsGrid]: {},
    },
    breakpoint: 'lg',
    vehicleTags: INITIAL_DATA_STATE,
    edentifyUnits: INITIAL_DATA_STATE,
    sites: INITIAL_DATA_STATE,
};

interface EdentifyUnitsDataReqProps extends ExtendedGetReqProps {
    site_id: string;
}

export const createOrUpdateEdentifyUnits = createAsyncThunk(
    'edentify/edentifyUnits/add',
    async ({ mandator_id, units }: EdentifyUnitsRequestBody, { rejectWithValue }) => {
        try {
            if (units.length <= 100) {
                const response = await Interceptor().post(`${URLS.EdentifyUnits}`, {
                    mandator_id,
                    units,
                });
                return response.data;
            }
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const getVehicleTagsData = createAsyncThunk(
    'edentify/vehicleTags/list',
    async ({ data_state, mandator_id }: ExtendedGetReqProps, { rejectWithValue }) => {
        try {
            // build pagination, sorting and filtering params
            //const queryParams = data_state && buildQueryParams(data_state);
            //const response = await Interceptor().get(`${URLS.VehicleTags}`, {
            //    params: {
            //        mandator_id,
            //        ...queryParams,
            //    },
            //});
            //return response.data;
            return {
                data: [
                    {
                        mandator_id,
                        tag_id: 'RFID Tag ID',
                        creation_date: '20/09/2023',
                        scheme: 'Retail',
                        plate_number: 'SGR0123R',
                    },
                    {
                        mandator_id,
                        tag_id: '1234 5678 9101 1121',
                        creation_date: '09/08/2024',
                        scheme: 'Fleet+',
                        plate_number: 'CJ98PAO',
                        vehicle_owner: 'Andrew',
                    },
                ],
                meta: {
                    limit: 10,
                    size: 500,
                    start: 0,
                    total: 2,
                },
            };
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const getEdentifyUnitsCount = createAsyncThunk(
    'edentify/edentifyUnits/count',
    async (mandator_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.EdentifyUnits}`, {
                params: {
                    mandator_id,
                    count: true,
                },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const getVehicleTagsCount = createAsyncThunk(
    'edentify/vehicleTags/count',
    async (mandator_id: string, { rejectWithValue }) => {
        try {
            // build pagination, sorting and filtering params
            //const response = await Interceptor().get(`${URLS.EdentifyUnits}`, {
            //    params: {
            //        mandator_id,
            //        count: true,
            //    },
            //});
            //return response.data;
            return 5336;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

export const getUnitsBySiteId = createAsyncThunk(
    'edentify/siteUnits',
    async (
        { data_state, site_id }: Omit<EdentifyUnitsDataReqProps, 'mandator_id'>,
        { rejectWithValue }
    ) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.EdentifyUnits}`, {
                params: {
                    site_id,
                    ...queryParams,
                },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

const edentifySlice = createSlice({
    name: 'edentify',
    initialState,
    reducers: {
        handleBreakPointChange: (state, action) => {
            state.breakpoint = action.payload;
        },
        updateGraphs: (state, action) => {
            if (state.graphs) {
                state.graphs = {
                    ...action.payload,
                    edentifyUnitsGrid: state.graphs.edentifyUnitsGrid,
                    vehicleTagsGrid: state.graphs.vehicleTagsGrid,
                };
            }
        },
        resetGraphs: (state) => {
            state.graphs = initialState.graphs;
        },
        updateEdentifyUnitsGridGraph: (state, action) => {
            state.graphs = {
                ...state.graphs,
                edentifyUnitsGrid: action.payload,
            };
        },
        updateEdentifyUnits: (state, action) => {
            state.edentifyUnits.data = action.payload;
        },
        updateVehicleTagsGridGraph: (state, action) => {
            state.graphs = {
                ...state.graphs,
                vehicleTagsGrid: action.payload,
            };
        },
        resetEdentifyUnitsGrid: (state) => {
            state.edentifyUnits = initialState.edentifyUnits;
        },
        resetVehicleTagsGrid: (state) => {
            state.vehicleTags = initialState.vehicleTags;
        },
        updateUnitsCountGraph: (state, action) => {
            state.graphs = {
                ...state.graphs,
                edentifyUnitsCount: action.payload,
            };
        },
        updateTagsCountGraph: (state, action) => {
            state.graphs = {
                ...state.graphs,
                vehicleTagsCount: action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getVehicleTagsData.fulfilled, (state, { payload }) => {
            state.vehicleTags.data = [...payload.data];
            state.vehicleTags.total = payload.meta.total;
        });
    },
});

export const {
    updateGraphs,
    updateUnitsCountGraph,
    updateTagsCountGraph,
    resetGraphs,
    handleBreakPointChange,
    updateVehicleTagsGridGraph,
    updateEdentifyUnitsGridGraph,
    resetVehicleTagsGrid,
    resetEdentifyUnitsGrid,
    updateEdentifyUnits,
} = edentifySlice.actions;
export default edentifySlice.reducer;
