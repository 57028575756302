import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// common components, interfaces, constants and helpers
import { ChartTypes, INITIAL_DATA_STATE, URLS } from '../../../constants';
import { Interceptor } from '../../common';
import {
    DashboardInitialStateProps,
    GetTransactionsProps,
    PostUserLayouts,
    UpdateGraphCustomizationModel,
} from '../interfaces';
import { buildQueryParams, changeGraphCustomizationData } from '../../../helpers';

const initialState: DashboardInitialStateProps = {
    graphs: {
        [ChartTypes.TotalAmount]: {},
        [ChartTypes.Transactions]: {},
        [ChartTypes.TotalQuantity]: {},
        [ChartTypes.PaymentMethods]: {},
        [ChartTypes.SitesRanking]: {},
        [ChartTypes.SitesLocations]: {},
        [ChartTypes.TransactionsGrid]: {},
        [ChartTypes.PointsStatus]: {},
    },
    userLayouts: undefined,
    breakpoint: 'lg',
    transactions: INITIAL_DATA_STATE,
};

// create thunk for getting sites location
export const getSitesChartData = createAsyncThunk(
    'dashboard/sites',
    async (_, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Sites}/location`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk for getting the user layouts
export const getUserLayouts = createAsyncThunk(
    'dashboard/layouts',
    async (userId: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Users}/${userId}/layouts`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);
//create thunk for getting user charts customization
export const getGraphsCustomization = createAsyncThunk(
    'dashboard/graphsCustomization',
    async (userId: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Users}/${userId}/charts`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk for creating or updating the user layouts
export const createUserLayouts = createAsyncThunk(
    'dashboard/layouts',
    async ({ user_id, id, layouts }: PostUserLayouts, { rejectWithValue }) => {
        try {
            return await Interceptor().post(`${URLS.Users}/${user_id}/layouts`, {
                id,
                layouts,
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);
//create thunk for adding or updating user charts customization
export const updateGraphsCustomization = createAsyncThunk(
    'dashboard/graphsCustomization/add',
    async (
        { graphCustomization, userId, id }: UpdateGraphCustomizationModel,
        { rejectWithValue }
    ) => {
        try {
            return await Interceptor().post(`${URLS.Users}/${userId}/charts`, {
                configuration: graphCustomization,
                id,
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for getting transactions for dashboard page
export const getTransactionsForDashboard = createAsyncThunk(
    'dashboard/transactions',
    async (
        { data_state, mandator_id, start_date, end_date }: GetTransactionsProps,
        { rejectWithValue }
    ) => {
        try {
            // build pagination, sorting and filtering params
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.Transactions}`, {
                params: {
                    mandator_id,
                    start_date,
                    end_date,
                    ...queryParams,
                },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//reducers
const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        handleBreakPointChange: (state, action) => {
            state.breakpoint = action.payload;
        },
        updateGraphs: (state, action) => {
            if (state.graphs) {
                state.graphs = {
                    ...action.payload,
                    transactionsGrid: state.graphs.transactionsGrid,
                    sitesRanking: state.graphs.sitesRanking,
                    totalQuantity: state.graphs.totalQuantity,
                    paymentMethods: state.graphs.paymentMethods,
                };
            }
        },
        updateSitesRankingGraph: (state, action) => {
            state.graphs = {
                ...state.graphs,
                sitesRanking: action.payload,
            };
        },
        updateTotalQuantityGraph: (state, action) => {
            state.graphs = {
                ...state.graphs,
                totalQuantity: action.payload,
            };
        },
        updatePaymentMethodsGraph: (state, action) => {
            state.graphs = {
                ...state.graphs,
                paymentMethods: action.payload,
            };
        },
        resetGraphs: (state) => {
            state.graphs = initialState.graphs;
        },
        updateTransactionsGridGraph: (state, action) => {
            state.graphs = {
                ...state.graphs,
                transactionsGrid: action.payload,
            };
        },
        resetTransactionsGridData: (state) => {
            state.transactions = initialState.transactions;
        },
        resetGraphsCustomization: (state) => {
            state.graphsCustomization = initialState.graphsCustomization;
        },
        updateUserLayout: (state, action) => {
            state.userLayouts = action.payload;
        },
        resetUserLayouts: (state) => {
            state.userLayouts = initialState.userLayouts;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSitesChartData.fulfilled, (state, { payload }) => {
            state.graphs = {
                ...state.graphs,
                sitesLocations: {
                    ...state.graphs?.sitesLocations,
                    ...payload.data,
                },
            };
        });
        builder.addCase(getUserLayouts.fulfilled, (state, { payload }) => {
            const data = payload.data;
            state.userLayouts =
                data.length > 0
                    ? { id: data[0].id, layouts: JSON.parse(data[0].layouts) }
                    : undefined;
        });
        builder.addCase(getGraphsCustomization.fulfilled, (state, { payload }) => {
            const response = changeGraphCustomizationData(payload.data);
            state.graphsCustomization = response;
        });
        builder.addCase(getTransactionsForDashboard.fulfilled, (state, { payload }) => {
            state.transactions.data = payload.data ?? [];
            state.transactions.total = payload.meta.total;
        });
    },
});

export const {
    handleBreakPointChange,
    resetGraphs,
    resetGraphsCustomization,
    resetTransactionsGridData,
    resetUserLayouts,
    updateGraphs,
    updatePaymentMethodsGraph,
    updateSitesRankingGraph,
    updateTotalQuantityGraph,
    updateTransactionsGridGraph,
    updateUserLayout,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
