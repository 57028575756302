import { useMemo } from 'react';
import { FormControl, Typography } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';

// common components, interfaces, constants and helpers
import iconImage from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { BONNDORF_COORDINATES, NOMINATIM_REVERSE_URL } from '../../../constants';
import { normalizeStringField, geocode, convertCoordinates } from '../../../helpers';
import { useAppSelector } from '../../hooks';
import TooltipButton from '../buttons/TooltipButton';

// styles
import './location.scss';

interface LocationProps {
    label: string;
    setFieldValue: (field: string, value: string | number, shouldValidate?: boolean) => void;

    code?: string;
    draggable?: boolean;
    hasAddressFields?: boolean;
    infoLabel?: string;
    latitude?: number | null;
    longitude?: number | null;
}

interface MapProps {
    latitude: number | null | undefined;
    longitude: number | null | undefined;
}

const icon = L.icon({
    iconUrl: iconImage,
    iconSize: [25, 35],
    iconAnchor: [12.5, 35],
    shadowUrl: iconShadow,
    shadowSize: [50, 32],
    shadowAnchor: [15, 32],
    popupAnchor: [0, -32],
});

const RecenterAutomatically = ({ latitude, longitude }: MapProps) => {
    const map = useMap();
    useMemo(() => {
        if (latitude && longitude) map.setView([latitude, longitude]);
    }, [map, latitude, longitude]);
    return null;
};

/**
 * Renders a location component with a map, a marker and optionally an info label.
 *
 * @param {LocationProps} props - The component props.
 * @param {string} props.label - The label for the component.
 * @param {number | null | undefined} props.latitude - The latitude of the location.
 * @param {number | null | undefined} props.longitude - The longitude of the location.
 * @param {string} props.code - The code for the component.
 * @param {boolean} props.draggable - Whether the marker is draggable.
 * @param {string | undefined} props.infoLabel - The info label value for the component.
 * @param {boolean} props.hasAddressFields - Whether the component has address fields.
 * @param {Function} props.setFieldValue - The function to set the field value.
 * @return {JSX.Element} The rendered location component.
 */
const Location = ({
    code,
    draggable,
    hasAddressFields,
    infoLabel,
    label,
    latitude,
    longitude,
    setFieldValue,
}: LocationProps): JSX.Element => {
    const { ISOLanguageCode } = useAppSelector((state) => state.commonData);
    const eventHandlers = useMemo(
        () => ({
            async dragend(e: L.LeafletEvent) {
                const pos = e.target.getLatLng();
                const location = await geocode(NOMINATIM_REVERSE_URL(pos.lat, pos.lng));
                if (location.address && hasAddressFields) {
                    const { country, road, house_number, town, city, village } = location.address;
                    setFieldValue('country', normalizeStringField(country ?? ''));
                    setFieldValue('city', normalizeStringField(city ?? town ?? village ?? ''));
                    setFieldValue(
                        'street',
                        normalizeStringField(
                            `${road ?? ''} ${road && house_number ? house_number : ''}`
                        )
                    );
                }
                setFieldValue('gps.latitude', Number.parseFloat(pos.lat.toFixed(6)));
                setFieldValue('gps.longitude', Number.parseFloat(pos.lng.toFixed(6)));
            },
        }),
        []
    );

    const mapCoordinates = useMemo(() => {
        return {
            lat: latitude ?? BONNDORF_COORDINATES.lat,
            lng: longitude ?? BONNDORF_COORDINATES.lng,
        };
    }, [latitude, longitude]);

    return (
        <FormControl className="locationForm" variant="outlined" role="map">
            <Typography variant="h3">
                {label}
                {infoLabel && (
                    <TooltipButton
                        className="info-buttons"
                        role="info-button"
                        title={infoLabel}
                        icon={<InfoRounded />}
                    />
                )}
            </Typography>
            <MapContainer
                className="location rounded"
                center={[mapCoordinates.lat, mapCoordinates.lng]}
                zoom={13}
                scrollWheelZoom={true}
                minZoom={2}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {latitude && longitude && (
                    <Marker
                        position={[mapCoordinates.lat, mapCoordinates.lng]}
                        key={`marker-${code}`}
                        eventHandlers={eventHandlers}
                        icon={icon}
                        draggable={draggable}>
                        <Popup key={`popup-${code}`}>
                            <Typography variant="overline">
                                {convertCoordinates(
                                    mapCoordinates.lat,
                                    mapCoordinates.lng,
                                    ISOLanguageCode
                                )}
                            </Typography>
                        </Popup>
                    </Marker>
                )}
                <RecenterAutomatically
                    latitude={mapCoordinates.lat}
                    longitude={mapCoordinates.lng}
                />
            </MapContainer>
        </FormControl>
    );
};
export default Location;
