import { FocusEvent } from 'react';
import { FormControl, Typography, SxProps } from '@mui/material';
import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';

interface DatePickerInterface {
    format: string;
    fieldName: string;
    label: string;
    value: Date | null | undefined;

    className?: string;
    disabled?: boolean;
    errors?: FormikErrors<FormikValues>;
    max?: Date;
    min?: Date;
    sx?: SxProps;
    touched?: FormikTouched<FormikValues>;
    handleBlur?: (event: FocusEvent<HTMLDivElement | HTMLSpanElement>) => void;
    handleChange?: (event: DatePickerChangeEvent) => void;
}

/**
 * Renders a DatePicker component with customizable options.
 *
 * @param {Object} props - The props object.
 * @param {string} props.format - The format of the date.
 * @param {string} props.fieldName - The name of the field.
 * @param {string} props.label - The label of the component.
 * @param {Date} [props.min] - The minimum date.
 * @param {Date} [props.max] - The maximum date.
 * @param {Date} props.value - The value of the date.
 * @param {boolean} [props.disabled] - Whether the component is disabled.
 * @param {FormikErrors<FormikValues>} [props.errors] - The form errors.
 * @param {FormikTouched<FormikValues>} [props.touched] - The touched fields.
 * @param {SxProps} [props.sx] - The custom CSS.
 * @param {string} [props.className] - The custom class name.
 * @param {(event: DatePickerChangeEvent) => void} [props.handleChange] - The change event handler.
 * @param {(event: FocusEvent<HTMLDivElement | HTMLSpanElement>) => void} [props.handleBlur] - The blur event handler.
 * @return {JSX.Element} The DatePicker component.
 */
const DatePickerComponent = ({
    className,
    disabled,
    errors,
    fieldName,
    format,
    label,
    max,
    min,
    sx,
    touched,
    value,
    handleBlur,
    handleChange,
}: DatePickerInterface): JSX.Element => {
    const inputFieldName = fieldName || '';

    const isErrorAvailable = !!errors?.[inputFieldName];
    const applyError = !touched ? isErrorAvailable : isErrorAvailable && touched[inputFieldName];

    const errorText = applyError ? errors?.[inputFieldName] : '';
    const customClassName = className ?? 'formControl';
    return (
        <FormControl className={customClassName} variant="outlined" size="small" sx={sx}>
            {label && <Typography variant="h3">{label}</Typography>}
            <DatePicker
                format={format}
                name={inputFieldName}
                min={min}
                max={max}
                value={value}
                disabled={disabled}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            {errors && (
                <Typography
                    color="#d63232"
                    variant="caption"
                    className="errorHeight"
                    role={`error-${fieldName}`}>
                    <>{errorText}</>
                </Typography>
            )}
        </FormControl>
    );
};
export default DatePickerComponent;
