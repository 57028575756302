/**
 * Clears the specified items from the local storage when the component is left.
 *
 * @return {void} This function does not return anything.
 */
export const clearLSOnComponentLeave = (): void => {
    localStorage.removeItem('idParent');
    localStorage.removeItem('deviceId');
    localStorage.removeItem('addOrEdit');
};

/**
 * Clears all items from the local storage when the user logs out.
 *
 * @return {void} This function does not return anything.
 */
export const clearLSOnLogout = (): void => {
    localStorage.clear();
};
