import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { URLS, INITIAL_DATA_STATE } from '../../../constants';
import { Interceptor } from '../../common';
import {
    ExtendedGetReqProps,
    Point,
    PointProductsReqProps,
    PointsStateTypes,
    SitePointsReqProps,
} from '../interfaces';
import { buildQueryParams } from '../../../helpers';

const initialState: PointsStateTypes = {
    devicePoints: INITIAL_DATA_STATE,
    point: null,
    pointProducts: [],
    points: INITIAL_DATA_STATE,
};

//get all points for a mandator
export const getPoints = createAsyncThunk(
    'points/list',
    async ({ data_state, mandator_id }: ExtendedGetReqProps, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.Points}`, {
                params: { mandator_id, ...queryParams },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create a thunk for get points
export const getPointsBySiteId = createAsyncThunk(
    'points/site/points',
    async ({ data_state, site_id }: SitePointsReqProps, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.Sites}/${site_id}/points`, {
                params: { ...queryParams },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);
// create a thunk for getting the points of a device by its id
export const getPointsByDeviceId = createAsyncThunk(
    'points/device/points',
    async ({ data_state, mandator_id }: ExtendedGetReqProps, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(`${URLS.Points}`, {
                params: { ...queryParams, mandator_id },
            });
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);
// create a thunk for add point
export const addPoint = createAsyncThunk(
    'points/point/add',
    async (point: Point, { rejectWithValue }) => {
        try {
            return await Interceptor().post(`${URLS.Points}`, { ...point });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk for getting point details
export const getPointById = createAsyncThunk(
    'points/point/get',
    async (point_id: string, { rejectWithValue }) => {
        try {
            const response = await Interceptor().get(`${URLS.Points}/${point_id}`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// create thunk for updating a point
export const updatePoint = createAsyncThunk(
    'points/point/update',
    async (point: Point, { rejectWithValue }) => {
        try {
            const { id, ...updatedPoint } = point;
            return await Interceptor().put(`${URLS.Points}/${id}`, { ...updatedPoint });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//get all products for a point
export const getProductsForPoint = createAsyncThunk(
    'points/point/products',
    async ({ site_id, point_id, data_state }: PointProductsReqProps, { rejectWithValue }) => {
        try {
            const queryParams = data_state && buildQueryParams(data_state);
            const response = await Interceptor().get(
                `${URLS.Sites}/${site_id}/points/${point_id}/products`,
                {
                    params: queryParams,
                }
            );
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

//delete point
export const deletePoint = createAsyncThunk(
    'points/point/delete',
    async (pointId: string, { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.Points}/${pointId}`);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// thunk for deleting multiple points
export const deletePoints = createAsyncThunk(
    'points/delete/bulk',
    async (points_ids: string[], { rejectWithValue }) => {
        try {
            return await Interceptor().delete(`${URLS.Points}`, {
                data: [...points_ids],
            });
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response);
        }
    }
);

// reducers
const pointsSlice = createSlice({
    name: 'points',
    initialState,
    reducers: {
        resetPoint: (state) => {
            state.point = initialState.point;
        },
        resetPointProducts: (state) => {
            state.pointProducts = initialState.pointProducts;
        },
        resetPoints: (state) => {
            state.points = initialState.points;
        },
        updatePoints: (state, action) => {
            state.points.data = [...action.payload];
        },
        resetDevicePoints: (state) => {
            state.points = initialState.points;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPoints.fulfilled, (state, { payload }) => {
            state.points.data = [...payload.data];
            state.points.total = payload.meta.total;
        });
        builder.addCase(getPointsBySiteId.fulfilled, (state, { payload }) => {
            state.points.data = [...payload.data];
            state.points.total = payload.meta.total;
        });
        builder.addCase(getPointById.fulfilled, (state, { payload }) => {
            state.point = payload.data[0];
        });
        builder.addCase(getProductsForPoint.fulfilled, (state, { payload }) => {
            state.pointProducts = payload.data;
        });
        builder.addCase(getPointsByDeviceId.fulfilled, (state, { payload }) => {
            state.devicePoints.data = [...payload.data];
            state.devicePoints.total = payload.meta.total;
        });
    },
});

export const { resetPoint, resetPointProducts, resetPoints, resetDevicePoints, updatePoints } =
    pointsSlice.actions;
export default pointsSlice.reducer;
