import { ImageMetaData } from '../app/common/interfaces';

/**
 * Creates a URL for uploading an image with the given metadata.
 *
 * @param {string} baseUrl - The base URL for the upload endpoint.
 * @param {ImageMetaData} imageMetaData - The metadata of the image to be uploaded.
 * @param {string} component - The type of component that is uploading the image.
 * @param {string | null} mandator - The ID of the mandator associated with the image.
 * @return {string} The URL for uploading the image.
 */
export const createUploadUrl = (
    baseUrl: string,
    imageMetaData: ImageMetaData,
    component: string,
    mandator: string | null
): string => {
    return `${baseUrl}?mandator_id=${mandator}&component_type=${component}&label=${imageMetaData.label}&width=${imageMetaData.width}&height=${imageMetaData.height}`;
};

/**
 * Creates a URL for deleting a file with the given access file path.
 *
 * @param {string} baseUrl - The base URL for the delete endpoint.
 * @param {string} accessFilePath - The path of the file to be deleted.
 * @return {string} The URL for deleting the file.
 */
export const createDeleteUrl = (baseUrl: string, accessFilePath: string): string => {
    return `${baseUrl}?access_file_path=${accessFilePath}`;
};
