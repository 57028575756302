import { Dispatch, SetStateAction } from 'react';
import { getModuleById } from '../app/pages/marketplace/marketplaceDuck';
import { Module } from '../app/pages/interfaces';

/**
 * Retrieves the status of a service based on the provided module ID.
 *
 * @param {string} moduleId - The ID of the module to retrieve the status for.
 * @param {(action: any) => any} dispatch - The function used to dispatch the getModuleById action.
 * @return {Promise<boolean>} A promise that resolves to the active status of the module.
 */
export const getServiceStatus = async (
    moduleId: string,
    dispatch: (action: any) => any
): Promise<boolean> => {
    const response = await dispatch(getModuleById(moduleId));
    return response.payload.active;
};

/**
 * Sets extended module information by retrieving module data from the server and updating the state.
 *
 * @param {string} item_id - The ID of the module to retrieve information for.
 * @param {(action: any) => any} dispatch - The function used to dispatch the getModuleById action.
 * @param {Dispatch<SetStateAction<Module | undefined>>} [setModule] - Optional function to update the module state.
 * @param {Dispatch<SetStateAction<boolean>>} [setModuleStatus] - Optional function to update the module status state.
 * @return {Promise<void>} A promise that resolves when the module information has been set.
 */
export const setExtendedModuleInfo = async (
    item_id: string,
    dispatch: (action: any) => any,
    setModule?: Dispatch<SetStateAction<Module | undefined>>,
    setModuleStatus?: Dispatch<SetStateAction<boolean>>
): Promise<void> => {
    // get module info
    const response = await dispatch(getModuleById(item_id));
    const moduleData = (await response).payload;
    setModule && setModule(moduleData);
    setModuleStatus && setModuleStatus(moduleData.active);
};

export const getActiveModulesForMandator = (modules: Module[]) => {
    return modules
        .filter((module) => module.active)
        .map((item) => {
            return item.service_id;
        });
};
