import { CognitoPassword } from 'aws-cognito-temporary-password-generator';

/**
 * Generates a Cognito password using the `CognitoPassword` class.
 *
 * @return {string} The generated password.
 */
export const generateCognitoPassword = (): string => {
    const generator = new CognitoPassword();
    return generator.generate();
};
